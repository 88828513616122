import { osName, osVersion,  browserName, browserVersion, mobileVendor,  mobileModel } from 'react-device-detect';


export function deviceInfo(){

 const deviceInfo = {

   osName:         osName, 
   osVersion:      osVersion, 
   browserName:    browserName, 
   browserVersion: browserVersion, 
   mobileVendor:   mobileVendor, 
   mobileModel:    mobileModel, 
 }

 return deviceInfo;

}
