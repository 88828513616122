import {TextField} from '@mui/material'

export function FplTextField(props){
    const{defaultValue, label, name, setFunc,inputRef} = props
    return(
        <TextField
            required
            defaultValue={defaultValue}
            margin="dense"
            placeholder= {label}
            inputRef={inputRef}
            label= {label}
            name={name}
            fullWidth
            variant="standard"
            onChange={(event) => {setFunc(event.target)}} 
        />
        )
}

export default FplTextField;

