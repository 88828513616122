import * as React from "react"

export function WindyIcon(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 1124 1124"
    {...props}
  >
    <path
      d="M540.5.6c-55 2.7-108.6 12.6-157.2 29-190.7 64.1-332.4 225-371.8 421.9C3.5 491.5.7 520.8.7 562s2.8 70.5 10.8 110.5c44.4 222 218 395.6 440 440 40 8 69.3 10.8 110.5 10.8s70.5-2.8 110.5-10.8c222-44.4 395.6-218 440-440 8-40 10.8-69.3 10.8-110.5s-2.8-70.5-10.8-110.5C1073.1 254.6 931.4 93.7 740.7 29.6c-40.7-13.7-85-23-129.6-27.1-16-1.4-57.4-2.6-70.6-1.9zm-34.4 267c7.8 2.3 18.3 8 23.9 13.1 6.6 6 13.5 16.4 16.7 25.3 3.7 10.5 4 23 1.4 49.5-8.8 89.2-10.9 187.8-5.6 257.5 6.8 88.6 17.6 143.4 35.6 180.8 4.2 8.8 5.2 10.1 6.7 9.3 8.3-4.5 50.2-44.3 68.2-64.8 30.5-34.7 75.6-98.4 107.2-151.3 38.2-63.9 82-151.7 99.4-199 2.9-8 5.3-14 5.4-13.4.1 3.8-9.7 37.7-18.4 63.9-22 65.8-45.8 123-79.1 190-19.7 39.7-36.5 70-56.9 102.2-21.8 34.7-39.9 58.7-66.3 88.3l-13 14.5 3.9.3c13.2 1.1 36-10.2 57-28.2 8.7-7.4 29.4-30.5 42.3-47.1 53.6-69 117.2-200.1 173.1-357 5.7-16 10.3-28.6 10.4-28 0 .5-2.7 12.3-5.9 26.2C874.5 559 824 701.1 777.2 779c-18 30-37.2 56.1-52.1 71.1-25.2 25.1-47.5 38.9-73.6 45.4-10.3 2.6-11.7 2.7-28.9 2.2-21.1-.5-30.4-2.5-47.4-10l-10.3-4.5-7.4 3.4c-9.3 4.3-22.4 8.6-33.5 11-12.5 2.7-36.8 2.5-51-.5-19.6-4.1-39-13.4-59-28.1-10.3-7.6-32.7-29.9-43.2-43-31.9-40.1-62.7-100.7-80.5-158.5-15.5-50.3-36.6-158.8-55.8-287-7.9-52.6-8.1-54.8-6.1-64.8 6.9-35.1 41.5-56.6 75.5-47.1 21.1 6 37.9 23.5 42.5 44.4.8 3.6 2.4 22.5 3.6 42 6.4 107.1 18.4 226.3 27.6 275 9.5 50 25.3 96.6 44.4 130.7 16.9 30.3 29 45.9 45.5 58.8 13.5 10.5 23 14.3 37.8 15.2 5.3.3 9.7.3 9.7-.1s-2-4-4.4-8c-5.8-9.5-16.5-30.9-21.5-43-17.8-42.9-37.7-127.3-47-200.1-8.7-67.8-12.4-130.8-12.5-214l-.1-55 2.8-7.8c7.7-21.9 25.8-37.2 48.1-40.8 6.8-1 19.1-.2 25.7 1.7z"
      fill="#757575"
    />
  </svg>
  )
}

export default WindyIcon
