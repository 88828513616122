import { useState, useCallback, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import { RenderRoute, OpeningDialog, PicInfoDialog, ActionMenu, ChangeDepartureTime, FplDialog, UndoButton, MsgDialog, HelpDialog} from '.';
import Bubbles from './Bubbles';
import ClosedAirways from './ClosedAirways';
import Airstrips from './Airstrips';
import ReportPoints from './ReportPoints';
import RouteList from './RouteList';
import { useLocalStorage,  GetTimeCategory, getTimeCategoryExceptions,getFirStatus, getOpenAirways, getTrafficRules, getManualRules,  filterTrafficRules, InitDepartureTime, isPatreonPilot,IsPicInfoFull,ValidatePicInfo, isProduction} from '../utils';
import { CalcBestRoute, CalcRouteLength , CreateRouteBack, createNewPoint } from '../airways';
import { useSearchParams } from 'react-router-dom';
import Control from 'react-leaflet-custom-control';
import { Button ,Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SadFaceIcon } from '../assets';


export function Main() {

  /***********************************************/

  // The entered route
  const [planedRoute, setPlanedRoute] = useState([]);

  // planed route with unique point names
  const [waypointRoute, setWaypointRoute] = useState([]);

  const [routeLength, setRouteLength] = useState(0);
  const [departureTime, setDepartureTime] = useState(InitDepartureTime());
  const [timeCategory, setTimeCategory] = useState(GetTimeCategory(departureTime));

  const [openingDialogOpen, setOpeningDialogOpen] = useState(true);
  const [timeDialogOpen, setTimeDialogOpen] = useState(false);
  const [fplDialogOpen, setFplDialogOpen] = useState(false);
  const [picInfoDialogOpen, setPicInfoDialogOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const [patreonPilot, setPatreonPilot] = useState(false);
  const [trafficRules, setTrafficRules] = useState([]);
  const [filteredRules, setFilteredRules] = useState([]);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [allClosedDialogOpen, setAllClosedDialogOpen] = useState(false);
  const [allClosedMessage, setAllClosedMessage] = useState("");
  const [openAirways, setOpenAirways] = useState(null);


 /***********************************************/

  const [mapUsed, setMapUsed] = useLocalStorage('mapUsed', 'lsa');
  const [activateRules, setActivateRules] = useLocalStorage('activateRules', true);
  const [initialCenter, setInitialCenter] = useLocalStorage('initialCenter',[32.2223, 34.8857]);
  const [timeCategoryExceptions, setTimeCategoryExceptions] = useLocalStorage('timeCategoryExceptionList',[]);
  const [picInfo, setPicInfo] = useLocalStorage('picInfo', {picName: "",licenseNum: "",phoneNumber: "",emailAddress: "",});

  /***********************************************/
  
  const routeListRef = useRef(null);

  const planedRouteRef = useRef(planedRoute);
  useEffect(() => { planedRouteRef.current = planedRoute; }, [planedRoute]);

  const mapUsedRef = useRef(mapUsed);
  useEffect(() => { mapUsedRef.current = mapUsed; }, [mapUsed]);

  const timeCategoryRef = useRef(timeCategory);
  useEffect(() => {timeCategoryRef.current = timeCategory;}, [timeCategory]);

  const filteredRulesRef = useRef(filteredRules);
  useEffect(() => {filteredRulesRef.current = filteredRules;}, [filteredRules]);

  const openAirwaysRef = useRef(openAirways);
  useEffect(() => {openAirwaysRef.current = openAirways;}, [openAirways]);

  const fplDialogOpenRef = useRef(fplDialogOpen);
  useEffect(() => {fplDialogOpenRef.current = fplDialogOpen;}, [fplDialogOpen]);

  const helpDialogOpenRef = useRef(helpDialogOpen);
  useEffect(() => {helpDialogOpenRef.current = helpDialogOpen;}, [helpDialogOpen]);

  const picInfoDialogOpenRef = useRef(picInfoDialogOpen);
  useEffect(() => {picInfoDialogOpenRef.current = picInfoDialogOpen;}, [picInfoDialogOpen]);

  const timeDialogOpenRef = useRef(timeDialogOpen);
  useEffect(() => {timeDialogOpenRef.current = timeDialogOpen;}, [timeDialogOpen]);

  /***********************************************/

  // handle what happens on key press
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Backspace' && !fplDialogOpenRef.current && !helpDialogOpenRef.current && !picInfoDialogOpenRef.current && !timeDialogOpenRef.current) {
      removeLastPointInRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  
  /***********************************************/
  
  // append a routing point to the route
  const appendToRoute = (latlng, name, icao) => {
    const newPoint = createNewPoint(latlng, name, icao);

    const newRoute = [...planedRoute, newPoint];

    CalcBestRoute(mapUsed, timeCategory, newRoute, setPlanedRoute,filteredRules,openAirways, setAlertDialogOpen, setAlertDialogMessage,true);

    setInitialCenter([latlng.lat, latlng.lng]);
  };

  /***********************************************/
  // Add a routing point callback
  const addToRoute = useCallback(
    (latlng, name, icao) => {
      const newPoint = createNewPoint(latlng, name, icao);

      const newRoute = [...planedRouteRef.current, newPoint];

      CalcBestRoute(mapUsedRef.current, timeCategoryRef.current, newRoute, setPlanedRoute ,filteredRulesRef.current,openAirwaysRef.current,  setAlertDialogOpen, setAlertDialogMessage,true);

      setInitialCenter([latlng.lat, latlng.lng]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[planedRouteRef]);

  /***********************************************/

  // Change a point parameters
  const changePoint = (point) => {
    const index = planedRoute.findIndex((o) => o.key === point.key);
    if (index > -1) {
      point.key = Math.random() * 1000;
      let pointsArray = [...planedRoute];
      pointsArray[index] = point;
      setPlanedRoute(pointsArray);
    }
  };

  /***********************************************/

  // Insert a point at a specific index
  const insertPoint = (point, index) => {
    if (index > -1) {
      let pointsArray = [...planedRoute];
      pointsArray.splice(index, 0, point);
      setPlanedRoute(pointsArray);
    }
  };

  /***********************************************/
  // remove a point from the route
  const removePoint = (key) => {
    const ind = planedRoute.findIndex((o) => o.key === key);
    if (ind > -1) {
      setPlanedRoute((prevItems) => {
        return prevItems.filter((item, index) => {
          return index !== ind;
        });
      });
    }
  };

  /***********************************************/

  // Remove the last point from the route
  const removeLastPointInRoute = () => {

    var route = [...planedRouteRef.current];

    var length = route.length;

    if (length) {
      const icao = route[length - 1].icao;
      var numToClear = 0;

      // count the last points with the same type
      while (length > 0 && route[length - 1].icao === icao) {
        numToClear++;
        length--;
      }
      setPlanedRoute(route.slice(0, -numToClear));
    }
  };

  /***********************************************/

  const removeLastPointInRouteEvent = (e) => {
    e.stopPropagation()
    e.preventDefault()
    removeLastPointInRoute();
  }

  /***********************************************/

  const createRouteBack = () =>{
    return CreateRouteBack(mapUsed, planedRoute, setPlanedRoute, setAlertDialogOpen, setAlertDialogMessage, updateDepartureTime, departureTime, filteredRules, openAirways)
  }

  /***********************************************/

  const openFplDialog = () => {
    
    if(!IsPicInfoFull(picInfo) || ValidatePicInfo(picInfo)){
        setPicInfoDialogOpen(true)
    } else {
      setFplDialogOpen(true);
    }
  };

  /***********************************************/

  const closeFplDialog = () => {
    setFplDialogOpen(false);
  };

  /***********************************************/

  useEffect(() => {
    // Create a route with unique point names
    var route = [];
    for (let i = 0; i < planedRoute.length; i++) {
      const point = planedRoute[i];

      // prevent adding same name twice
      if (i === 0 || point.name !== planedRoute[i - 1].name) route.push(point);
    }
    setWaypointRoute(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setRouteLength(CalcRouteLength(planedRoute));

  }, [planedRoute]);

  /***********************************************/
  
  function notPatreon(){
    // activateRules && setActivateRules(false)
    patreonPilot && setPatreonPilot(false);
    trafficRules.length && setTrafficRules([]);
    filteredRules.length && setFilteredRules([]);
  }

  /***********************************************/

  useEffect(() => {

    if(picInfo.licenseNum){

      if(isProduction() ){
      // if(process.env.REACT_APP_VERCEL_ENV === "production" ){
      // if(process.env.REACT_APP_VERCEL_ENV === "production" || process.env.REACT_APP_RUN_ENV === "LOCAL"){

        // Temporary  get manual rules
        getManualRules(picInfo.licenseNum,function(status,data){
          if(status === "success"){

            console.log('traffic Rules', data);
            setTrafficRules(data)
          } 
        })

        isPatreonPilot(parseInt(picInfo.licenseNum),function (isPatreon) {
          console.log('isPatreon', isPatreon);
          setPatreonPilot(isPatreon)
        });

        setActivateRules(true);

      } else {

        getTrafficRules(picInfo.licenseNum,function(status,data){
          if(status === "success"){
            setPatreonPilot(true);
            setTrafficRules(data)
          } else{
            notPatreon();
          }
        })
      }
   }


      // getTrafficRules(picInfo.licenseNum,function(status,data){
      //   if(status === "success"){

      //     setPatreonPilot(true);

/***********************************/
//         if(process.env.REACT_APP_VERCEL_ENV === "production"){
//           setTrafficRules([])
//           setFilteredRules([])
//         }else {
//           setTrafficRules(data)
//         }
// /***********************************/

          // console.log('trafficRules', data);
    //     } else{
    //       notPatreon();
    //     }
    //   });
    // } 
    // else {
    //   notPatreon();
    // }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picInfo.licenseNum]);

  /***********************************************/

  const UpdateTimeCategory = (depTime) => {

    var tCategory = GetTimeCategory(depTime, timeCategoryExceptions);

    if(tCategory !== timeCategory){
      setTimeCategory(tCategory)
      resetRoute();
    }

    return tCategory
  };

  /***********************************************/

  // update the departure time
  function updateDepartureTime(newTime) {

    newTime.setSeconds(0,0);
    setDepartureTime(newTime);
    return UpdateTimeCategory (newTime);
  }

  /***********************************************/


  const closeOpeningDialog = () =>{

    setOpeningDialogOpen(false)

    if(!IsPicInfoFull(picInfo) || ValidatePicInfo(picInfo))
    {
        setPicInfoDialogOpen(true)
    }
  }

  /***********************************************/

  const closePicInfoDialog = () => {
    setPicInfoDialogOpen(false)
  }

  /***********************************************/

  // get the map to use and the departure time from the command line
  /* eslint-disable no-unused-vars */
  const [searchParams, setSearchParams] = useSearchParams();
  /* eslint-enable no-unused-vars */


  useEffect(() => {

    var fRules = []

    // if(activateRules && isPatreonPilot){
      fRules = filterTrafficRules(trafficRules, departureTime,mapUsed)
    // }

    if (JSON.stringify(fRules) !== JSON.stringify(filteredRules)){
      setFilteredRules(fRules)

      var routeStr = searchParams.get("route");
      if(!routeStr){
        resetRoute()
      }
    }

    // console.log('filteredRules', fRules);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureTime, mapUsed, trafficRules, activateRules, isPatreonPilot]);

  /***********************************************/

  useEffect(() => {

    // Try to get the map to use from the command line
    const mapToUse = searchParams.get('map');
    if (mapToUse && (mapToUse === 'cvfr' || mapToUse === 'lsa')){ 
      setMapUsed(mapToUse);
    }else{
      // in case the local storage is corrupted
      if ((mapUsed !== 'cvfr' && mapUsed !== 'lsa')){ 
        setMapUsed('lsa');
      }
    }

    // If the url params contain departure time, do not open the opening dialog
    if (searchParams.get('dep')) {
      setOpeningDialogOpen(false);
      if(!IsPicInfoFull(picInfo) || ValidatePicInfo(picInfo))
      {
          setPicInfoDialogOpen(true)
      }
    } 

    getTimeCategoryExceptions(function (exceptions){
      if(exceptions){
        var tCategory = GetTimeCategory(departureTime, exceptions);
        setTimeCategoryExceptions(exceptions)
        setTimeCategory(tCategory)
      }
    });


    // if(process.env.REACT_APP_RUN_ENV === "SERVER"){
    if(process.env.REACT_APP_VERCEL_ENV === "production"){

      getFirStatus(function (status){
        if(status && status.varValue) { 

          if( status.varValue === "closed"){
            const message = status.message ? status.message : ""
            setAllClosedMessage(message)
            setAllClosedDialogOpen(true)
          } else if( status.varValue === "limited"){
            getOpenAirways(function(airways){
              setOpenAirways(airways)
            })
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***********************************************/

  const resetRoute = () => {
    setFplDialogOpen(false)
    setPlanedRoute([]);
  }

  /***********************************************/

  // Change map callback
  const changeMap = (map) => {
    if (mapUsed !== map) {
      setMapUsed(map);
      resetRoute();
    }
  };

  /***********************************************/

  const { BaseLayer } = LayersControl;
  const lsaUrl = 'https://flight-maps.com/tiles/la/{z}/{x}/{y}.png';
  const cvfrUrl = 'https://flight-maps.com/tiles/cvfr/{z}/{x}/{y}.png';
  // const attribution =
  //   '&copy; <a href="https://fpl.co.il">Davidi Hollander</a> | <a href="https://www.gov.il/he/departments/civil_aviation_authority_of_israel">CAAI</a>';

  /***********************************************/

  return (
    <>
      <MapContainer
        center={initialCenter}
        zoom={11}
        scrollWheelZoom={true}
        maxZoom={13}
        tapHold={false}
        doubleClickZoom={false}
        attributionControl={false}
        maxBounds={[
          [33.8, 33.55],
          [29, 36.5],
        ]}
      >
        <LayersControl position='topleft'>
          <BaseLayer checked={mapUsed === 'lsa'} name='LSA'>
            <TileLayer
              url={lsaUrl}
              // attribution={attribution}
              eventHandlers={{
                add: (e) => {
                  changeMap('lsa');
                },
              }}
            />
          </BaseLayer>
          <BaseLayer checked={mapUsed === 'cvfr'} name='CVFR'>
            <TileLayer
              url={cvfrUrl}
              // attribution={attribution}
              eventHandlers={{
                add: (e) => {
                  changeMap('cvfr');
                },
              }}
            />
          </BaseLayer>
        </LayersControl>

        <OpeningDialog
          departureTime={departureTime}
          updateDepartureTime={updateDepartureTime}
          openingDialogOpen={openingDialogOpen}
          closeOpeningDialog={closeOpeningDialog}
        />

        <PicInfoDialog
          picInfo={picInfo}
          setPicInfo={setPicInfo}
          setActivateRules={setActivateRules}
          picInfoDialogOpen={picInfoDialogOpen}
          closePicInfoDialog={closePicInfoDialog}
        />

        <MsgDialog
            msgDialogOpen = {alertDialogOpen}
            msgTitle = {"מצטערים"}
            icon = {<SadFaceIcon/>}
            msgContent = {alertDialogMessage}
            trueButtonText = "חזור"
            closeMsgDialog = {()=>{setAlertDialogOpen(false)}}
        />

        <MsgDialog
            msgDialogOpen = {allClosedDialogOpen}
            msgTitle = {"לצערנו לא ניתן להגיש כעת תוכנית טיסה"}
            icon = {<SadFaceIcon/>}
            msgContent = {allClosedMessage}
            closeMsgDialog = {()=>{}}
        />

        <ChangeDepartureTime
          departureTime={departureTime}
          updateDepartureTime={updateDepartureTime}
          timeDialogOpen={timeDialogOpen}
          setTimeDialogOpen={setTimeDialogOpen}
        />

        <RenderRoute
          route={planedRoute}
          appendToRoute={appendToRoute}
          setPlanedRoute={setPlanedRoute}
          changePoint={changePoint}
          insertPoint={insertPoint}
          removePoint={removePoint}
          mapUsed={mapUsed}
          timeCategory={timeCategory}
          rules={filteredRules}
          openAirways = {openAirways}
          resetRoute = {resetRoute}
        />

        <Bubbles
          addToRoute={addToRoute}
          mapUsed={mapUsed}
          timeCategory={timeCategory}
          rules={filteredRules}
          openAirways = {openAirways}
        />

        <ReportPoints
          addToRoute={addToRoute}
          mapUsed={mapUsed}
          timeCategory={timeCategory}
          openAirways = {openAirways}
          // rules={filteredRules}
        />

        <Airstrips 
          addToRoute={addToRoute} 
          mapUsed={mapUsed} 
          rules={filteredRules}
          openAirways = {openAirways}
        />

        <ClosedAirways 
          mapUsed={mapUsed} 
          timeCategory={timeCategory}
          rules={filteredRules}
        />

        <Control position='bottomleft'>
          <UndoButton 
            removeLastPointInRouteEvent = {removeLastPointInRouteEvent}   
            undoDisabled = {planedRoute.length === 0 || fplDialogOpen || timeDialogOpen}
          />
        </Control>

        <Control position='topright'>
          <ActionMenu
            planedRoute={planedRoute}
            setPlanedRoute={setPlanedRoute}
            mapUsed={mapUsed}
            departureTime={departureTime}
            createRouteBack = {createRouteBack}
            setTimeDialogOpen={setTimeDialogOpen}
            setPicInfoDialogOpen={setPicInfoDialogOpen}
            setHelpDialogOpen={setHelpDialogOpen}
            activateRules = {activateRules}
            setActivateRules = {setActivateRules}
            patreonPilot={patreonPilot}
            picInfo = {picInfo}
          />
        </Control>

        <RouteList 
          waypointRoute={waypointRoute} 
          ref={routeListRef}  
        />

        <Control position='topright' style={{ zIndex:10000}}>
          <Button
            sx={{ mt: 1, mb: 1, boxShadow: 5 }}
            size='small'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            disabled={waypointRoute.length < 2}
            style={{ borderRadius: 25 , zIndex:10000}}
            onClick={openFplDialog}
          >
            המשך
          </Button>
        </Control>

        <Control position='bottomright'>
          <Box 
            dir = "rtl"
            style={{marginBottom: "-10px", color: 'blue',  zIndex:1}}
            backgroundColor = '#eee'
            sx={{ width: waypointRoute.length > 1 ? routeListRef.current.clientWidth : 0, height: 18, }}
          >
          <span> &nbsp; {Math.round(routeLength)} מייל ימי </span>
          </Box>
        </Control>
      </MapContainer>

      <FplDialog
        route={waypointRoute}
        fplDialogOpen={fplDialogOpen}
        closeFplDialog={closeFplDialog}
        routeLength={routeLength}
        mapUsed={mapUsed}
        departureTime={departureTime}
        timeCategory={timeCategory}
        picInfo={picInfo}
        setPicInfoDialogOpen={setPicInfoDialogOpen}
        setTimeDialogOpen={setTimeDialogOpen}
        patreonPilot={patreonPilot}
      />

      <HelpDialog
        helpDialogOpen = {helpDialogOpen}
        setHelpDialogOpen = {setHelpDialogOpen}
        picInfo = {picInfo}
        mapUsed = {mapUsed}
        patreonPilot = {patreonPilot}
      />
    </>
  );
}

export default Main;
