import { useSearchParams } from 'react-router-dom';

export function GetTimeCategory(departureTime, timeCategoryExceptions){


    var timeCategory = "WeekDay"
    var dayOfWeek = departureTime.getDay()
    var departureHour = departureTime.getHours();

    if(dayOfWeek === 6){
        timeCategory = "AllOpen"
    } else {

        if (dayOfWeek === 5){
        
            if(departureHour < 12){
                timeCategory = "OpenOnly6"
            } else if(departureHour < 14){
                timeCategory = "OpenOnly12"
            } else {
                timeCategory = "AllOpen"
            }
        }
    }

    if(timeCategoryExceptions && Array.isArray(timeCategoryExceptions) ){

        var isoDate = departureTime.toJSON().slice(0,10)
        var dayFound = timeCategoryExceptions.find(o => o.date.slice(0,10) === isoDate)

        if(dayFound){
            if(dayFound.status === "AllOpen"){
                timeCategory = "AllOpen"
            } else if(dayFound.status === "OpenAllAt14"){
                if(departureHour >= 14){
                    timeCategory = "AllOpen"
                }
            } else if(dayFound.status === "OpenAllAt12"){
                if(departureHour >= 12){
                    timeCategory = "AllOpen"
                }
            } 
        }
    }

    return timeCategory;
}

export function InitDepartureTime(){

    var initialDepartureTime = new Date();

  /* eslint-disable no-unused-vars */
  const [searchParams, setSearchParams] = useSearchParams();
  /* eslint-enable no-unused-vars */

    const depTime = searchParams.get('dep');

    if (depTime) {

        initialDepartureTime = new Date(parseInt(depTime))

    } else {
        if(initialDepartureTime.getHours() < 18 ){
            initialDepartureTime.setMinutes(Math.ceil(initialDepartureTime.getMinutes() / 5) * 5 + 75);
        } else {
            initialDepartureTime.setDate(initialDepartureTime.getDate() + 1);
            initialDepartureTime.setHours(7 ,0 ,0);
        }
    }

    return(initialDepartureTime)
}

export function getTimeCategoryHour(timeCategory){

    const timeCatObj = {
        WeekDay: 0,
        OpenOnly6: 6,
        OpenOnly12: 12,
        OpenOnly14: 14,
        AllOpen: 20,
    }

    return  timeCatObj[timeCategory] ?? 0;
}

export function isInTimeCategory(timeCategory,hour){

    return getTimeCategoryHour(timeCategory) >= hour;
}

