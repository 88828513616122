import {memo} from "react";
import {Polygon, Pane } from 'react-leaflet';
import {LoadBubbles } from "../airways";


export function Bubbles(props) {

  const {addToRoute, mapUsed, timeCategory, rules, openAirways} = props

  // if(props.mapUsed !== "lsa"){
  //   return null
  // }

  console.log('Loading bubbles timeCategory=',timeCategory);

  const polygonOptions = mapUsed === "lsa" ? { opacity: 0 , fillOpacity: 0} : { color: 'green', opacity: 0.3, fillOpacity: 0.1 }

  const bubblesData = LoadBubbles(mapUsed, timeCategory, rules, openAirways)

  const bubbles = bubblesData.filter((bubble) => {
    return (bubble.closed === false)
  })

  return (
    <>
      <Pane name="bubbles-pane" style={{ zIndex: 400 }}>
        {bubbles.map((bubble) => {
          return(
            <Polygon 
              pathOptions={polygonOptions} 
              positions={bubble.coords}
              key={ bubble.icao}
              title={bubble.name}
              icao={bubble.icao}
              zIndexOffset = {-100000}
              eventHandlers={{
                click: (e) => {
                  addToRoute(e.latlng,e.target.options.title,e.target.options.icao)
                },
              }}>
          </Polygon>
          )}
        )}
      </Pane>
    </>
  )
}

export default memo(Bubbles);
