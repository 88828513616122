import {useState} from "react";
import {DonateDialog, MsgDialog} from ".";
import {WindyIcon} from "../assets";
import {isProduction} from "../utils";
import {FormControlLabel,Checkbox, IconButton, ListItemIcon, ListItemText, MenuItem, Menu, Divider, Typography} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';        
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ManIcon from '@mui/icons-material/Man';
import DeleteIcon from '@mui/icons-material/Delete';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuIcon from '@mui/icons-material/Menu';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { track } from '@vercel/analytics';


import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
// import { BrowserView} from 'react-device-detect';
import {GetRouteUrl, ExportGPX, GetWindyUrl, parseKml} from "../utils";
// import { changeKmlFile} from '../../lib/changeKmlFile';

  // Display the list of route point on the right side of the screen
  export function ActionMenu (props) {

    const {planedRoute, 
          setPlanedRoute, 
           mapUsed, 
           departureTime, 
           createRouteBack,
           setTimeDialogOpen,
           setPicInfoDialogOpen,
           setHelpDialogOpen,
           activateRules,
           setActivateRules,
           patreonPilot,
           picInfo,
          } = props

    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [donateDialogOpen, setDonateDialogOpen] = useState(false);
    const [notamDialogOpen, setNotamDialogOpen] = useState(false);

    const handleOpenMenu = (e) => {
      setAnchorElMenu(e.currentTarget);
    };

/***********************************************/
     const handleCloseMenu = () => {
      setAnchorElMenu(null);
    };
    
/***********************************************/
    const makeAction = (action) => (e) => {
      handleCloseMenu()
      action(e)
    }

/***********************************************/
    const closeNotamDialog = (donate) => {
      if(donate){
        window.open('https://patreon.com/user?u=84256488', '_blank');
      }
      setNotamDialogOpen(false)
    }

/***********************************************/
    const handleActivateNotams = () => {
      if(activateRules && patreonPilot){
        setActivateRules(false)
      }else{
        patreonPilot ? setActivateRules(true) : setNotamDialogOpen(true)
      }
      handleCloseMenu()
    };

/***********************************************/
// Reverse the route
    const routeBack = () => {
      track('createRouteBack');
      return (createRouteBack())
    }

/***********************************************/

    const  sendWhatsapp = (whatsAppText) => {
      var url = "https://wa.me/?text=" + whatsAppText;
      window.open(url, '_blank');
    }

/***********************************************/
    const ShareFlightPlan = () => {
      track('ShareFlightPlan');
      const routeUrl = GetRouteUrl(mapUsed,planedRoute,departureTime)
      const whatsAppText = planedRoute[0].name + "-" + planedRoute[planedRoute.length - 1].name + '%0D%0A' + routeUrl;
      sendWhatsapp(whatsAppText)
    }

/***********************************************/

    const callWindy = () => {
      const windyUrl = GetWindyUrl(planedRoute, departureTime)
      window.open(windyUrl, '_blank');
    }


/***********************************************/

  function CheckActivateRules (){

    // const isProduction = process.env.REACT_APP_VERCEL_ENV === "production";
    
    return (!isProduction() ?

    <FormControlLabel 
      control={ 
        <Checkbox 
          sx={{ mr:-1.3}} 
          id="notams" 
          checked={activateRules && patreonPilot} 
          onChange={handleActivateNotams} 
        />}
          label={
            <Typography sx={{mr:0.2, fontSize: 14}}>
              הפעל ניתוח נוטאמים
            </Typography> 
          }
      />: null
    )
  }

/***********************************************/

  function MItem (props){
    const {text, icon, action} = props
    return(
      <MenuItem onClick={makeAction(action)} disabled = {props.disabled}>
          <ListItemIcon size="small"> 
            {icon} 
          </ListItemIcon>
          <ListItemText primary={text}  primaryTypographyProps={{fontSize: '14px', textAlign: "right"}}/>
      </MenuItem>
    )
  }

/***********************************************/
    
    return (
      <>
        <span>
          <IconButton 
            size="small"
            sx={{ mr:1, bgcolor: '#1976d2', color:'#fff', '&:hover': {backgroundColor: '#1565c0',color: '#fff'}}}
            variant="contained"
            aria-label="menu" 
            onClick={handleOpenMenu} 
            >
            <MenuIcon />
          </IconButton>
        </span>

      <Menu 
        dir="RTL" 
        sx={{textAlign: "right",  mt: '45px'}}
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { width: 280,},}} 
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        >

        <MItem text = "טייס מפקד" icon = {<ManIcon />} action = {() => setPicInfoDialogOpen(true)}/>
        <MItem text = "מחק נתיב" icon = {<DeleteIcon/>} action = {()=>  setPlanedRoute([])}  disabled={planedRoute.length < 1} />
        <MItem text = "תוכנית טיסה חזרה" icon = {<UTurnLeftIcon/>} action = {routeBack}  disabled={planedRoute.length < 2} />
        <MItem text = "עדכן זמן המראה" icon = {<AccessTimeIcon/>} action = {() => setTimeDialogOpen(true)} />
        <CheckActivateRules/>
        <Divider />
        <MItem text = "שמירה לקובץ ניווט" icon = {<FileDownloadIcon/>} action = {() => ExportGPX(planedRoute)}  disabled={planedRoute.length < 2} />
        <MItem text = "שתף" icon = {<WhatsAppIcon/>} action = {ShareFlightPlan}  disabled={planedRoute.length < 2} />
        {/* <BrowserView> */}
        <MItem text = "תחזית מזג האויר בנתיב" icon = {<WindyIcon/>} action = {callWindy}  disabled={planedRoute.length < 2} />
        {/* </BrowserView> */}
        <MItem text = "עזרה" icon = {<HelpOutlineIcon />} action = {() => {window.open('data/manual.pdf','_blank' );}}/>
        <MItem text = "צור קשר" icon = {<MailOutlineIcon />} action = {() => setHelpDialogOpen(true)}/>
        <MItem text = "אהבתי - רוצה לתמוך" icon = {<VolunteerActivismIcon color="error"/>} action = {() => setDonateDialogOpen(true)}/>
        {process.env.REACT_APP_RUN_ENV === "LOCAL" && <MItem text = "parseKml" icon = {<FileDownloadIcon />} action = {parseKml}/>}
      </Menu>

      <DonateDialog
        donateDialogOpen = {donateDialogOpen}
        setDonateDialogOpen = {setDonateDialogOpen}
        picInfo = {picInfo}
      />

      <MsgDialog
            msgDialogOpen = {notamDialogOpen}
            msgTitle = {"ניתוח נוטאמים"}
            msgContent = {"בגלל עלויות נוספות, בשלב הזה, ניתוח נוטאמים מאופשר רק לטייסים שעוזרים בהוצאות הפעלת האתר."}
            trueButtonText = "רוצה לעזור"
            falseButtonText = "פעם אחרת"
            closeMsgDialog = {(donate)=>{closeNotamDialog(donate)}}
        />
      </>
    );
  }

  export default ActionMenu;
