import {Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle} from '@mui/material';
import {ThankYouIcon} from '../assets';
import { track } from '@vercel/analytics';



// 
export function DonateDialog (props) {

  const { donateDialogOpen , setDonateDialogOpen, picInfo } = props

  const buttonTrueClicked = ()=>{ 

    setDonateDialogOpen(false)
    window.open('https://patreon.com/user?u=84256488', '_blank');
    track('Donate',picInfo);

  }

/***********************************************/

  const buttonFalseClicked = ()=>{ 
    setDonateDialogOpen(false)
  }

/***********************************************/
    return(

      <Dialog 
        className="fpl-submit"  
        open={donateDialogOpen} 
        >

        <DialogTitle>{"האפליקציה שימושית בעיניך?"}</DialogTitle> 

        <DialogContent>

          <DialogContentText > {'הפיתוח, האחסנה והתחזוקה של האתר עולים כסף וממומנים באופן פרטי. אשמח אם תוכל\\י להשתתף במעט בעלויות כדי שהאפליקציה תוכל להמשיך לפעול ולהתעדכן.'}
          </DialogContentText>

          <div style={{textAlign: 'center'}}>
            <p></p>
            <ThankYouIcon/>
          </div> 

        </DialogContent>
          

        <DialogActions>
          <Button onClick={buttonFalseClicked}>{'פעם אחרת'}</Button>
          <Button variant="contained" onClick={buttonTrueClicked}>{'רוצה לעזור'}</Button> 
        </DialogActions>

      </Dialog>
    
     )    
}

export default DonateDialog;
