import {useState, useEffect, useRef} from "react";
import {Button, Dialog, DialogActions,DialogContent, DialogTitle, Alert, Collapse, } from '@mui/material'
import { FplTextField, } from '.'
import { PilotIcon } from '../assets';
import { ValidatePicInfo } from '../utils';

/***********************************************/

export function PicInfoDialog (props) {

    const {picInfo, setPicInfo, setActivateRules, picInfoDialogOpen, closePicInfoDialog} = props;

    const [alertMessage,        setAlertMessage]         = useState("");
    const [alertOpen,           setAlertOpen]            = useState(false);

    const [picName,      setPicName] = useState(picInfo.picName);
    const [licenseNum,   setLicenseNum] = useState(picInfo.licenseNum);
    const [phoneNumber,  setPhoneNumber] = useState(picInfo.phoneNumber);
    const [emailAddress, setEmailAddress] = useState(picInfo.emailAddress);
    

/***********************************************/
    const picNameRef = useRef();
    const licenseNumRef = useRef();
    const phoneNumberRef = useRef();
    const emailAddressRef = useRef();

/***********************************************/
useEffect(() => {

    const alertMsg =  ValidatePicInfo(picInfo)
    if(alertMsg){
        showAlert(alertMsg)
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

/***********************************************/

    function picInfoChanged (target){

        const {name, value} = target

        switch(name){
            case "picName":
                setPicName(value.trim())
                break;

            case "phoneNumber":
                setPhoneNumber(value.trim())
                break;

            case "emailAddress":
                setEmailAddress(value.trim())
                break;

            case "licenseNum":
                setLicenseNum(value.trim())
                break;

            default:
                break;
        }
    }

/***********************************************/

    const closeDialog = ()=>{ 
        setAlertOpen(false)
        setAlertMessage("")
        closePicInfoDialog();
    }

/***********************************************/

    const showAlert = (message) =>{
        setAlertMessage(message);
        setAlertOpen(true);
    }

/***********************************************/

function verifyDialogFields(){

        const piInfoFields = 
        [
            [picName,     picNameRef ,      "אנא ציין טייס מפקד"],
            [licenseNum,  licenseNumRef,    "אנא ציין את מספר רשיון הטייס"],
            [phoneNumber, phoneNumberRef ,  "אנא מלא את מספר הטלפון של הטייס"],
            [emailAddress,emailAddressRef , "אנא מלא את כתובת האימייל של הטייס"]
        ]
       
        for (let i = 0; i < piInfoFields.length; i++) {
            if(piInfoFields[i][0].length ===0){
                showAlert(piInfoFields[i][2])
                piInfoFields[i][1].current.focus();
                return false;
            }
        }

        const alertMsg =  ValidatePicInfo({
            picName: picName,
            licenseNum: licenseNum,
            phoneNumber: phoneNumber,
            emailAddress: emailAddress
        })

        if(alertMsg){
            showAlert(alertMsg); 
            return false;
        }

        return true
    }

    /***********************************************/

    function verifyAndExcept()
    {
        if(verifyDialogFields()){

            if(picInfo.licenseNum !== licenseNum)
                setActivateRules(true);

            setPicInfo ({
                picName: picName,
                licenseNum: licenseNum,
                phoneNumber: phoneNumber,
                emailAddress: emailAddress
            })
            
            closeDialog();
        }
    }

    /***********************************************/

    return(
        <>

        <Dialog className="fpl-dialog" open={picInfoDialogOpen}>

        <Collapse in={alertOpen}>
            <Alert dir = "rtl" sx={{textAlign: "right"}} severity="error">
                {alertMessage}
            </Alert>
        </Collapse>

        <div style={{textAlign: 'center'}}>
          <p></p>
          <PilotIcon/>
        </div> 

        <DialogTitle textAlign="center">פרטי הטייס המפקד </DialogTitle>

        <DialogContent >

        {/* <DialogContentText > {'אנא מלא את פרטי הטייס המפקד. ניתן לשנות אותם אחר כך בתפריט'}</DialogContentText> */}

            <FplTextField
                defaultValue={picName}
                name="picName"
                label="Pilot In Command"
                inputRef = {picNameRef}
                setFunc = {picInfoChanged}
            />

            <FplTextField
                defaultValue={licenseNum}
                name="licenseNum"
                label="License Number"
                inputRef = {licenseNumRef}
                setFunc = {picInfoChanged}
            />

            <FplTextField
                defaultValue={phoneNumber}
                name="phoneNumber"
                label="Phone Number"
                inputRef = {phoneNumberRef}
                setFunc = {picInfoChanged}
            />

            <FplTextField 
                defaultValue={emailAddress} 
                name="emailAddress"
                label="Email Address"
                inputRef = {emailAddressRef}
                setFunc = {picInfoChanged}
            />

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={verifyAndExcept}>המשך</Button>
          </DialogActions>
        
      </Dialog>
      </>
    )    
}

export default PicInfoDialog;
