import {useState, useEffect, useRef} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Collapse,DialogContentText, Stack, Box} from '@mui/material'
import {FplSubmit, FplSelector, FplTextField, RouteNames, RouteAlert,MsgDialog} from '.'
import {VerifyRoute, CreateEmailContent,useLocalStorage, VerifySendingTime, IsTimeOverlap,isSuspectedAirport, checkIfAtcPermissionRoutes} from '../utils/'
import {isAirport,} from '../airways/'
import {flightTimeValues, enduranceValues, speedValues, lsaPobValues, gaPobValues, aircraftTypeValues } from "../data";
import { ErrorIcon } from '../assets';


/***********************************************/

export function FplDialog (props) {

    const {
        route,
        fplDialogOpen, 
        closeFplDialog, 
        routeLength, 
        mapUsed, 
        departureTime, 
        timeCategory, 
        picInfo,
        setPicInfoDialogOpen,
        setTimeDialogOpen,
        patreonPilot
    } = props

    const [fplSubmitDialogOpen, setFplSubmitDialogOpen]  = useState(false);
    const [routeAlertDialogOpen,setRouteAlertDialogOpen] = useState(false);
    const [alertMessage,        setAlertMessage]         = useState("");
    const [alertOpen,           setAlertOpen]            = useState(false);
    const [emailContent,        setEmailContent]         = useState({icao_plan:""});
    const [routeAlerts,         setRouteAlerts]          = useState([]);

    const [msgDialogOpen,       setMsgDialogOpen]        = useState(false);
    const [msgTitle,            setMsgTitle]             = useState("");
    const [msgContent,          setMsgContent]           = useState("");
    const [trueButtonText,      setTrueButtonText]       = useState("");

    const [selectionDialogOpen, setSelectionDialogOpen]  = useState(false);
    const [airportDialogOpen,   setAirportDialogOpen]    = useState(false);

    const [lastFplTimes,         setLastFplTimes]        = useLocalStorage('lastFplTimes',[]);

/***********************************************/
    const callSignRef = useRef();

/***********************************************/

    const [fplContent, setFplContent] = useLocalStorage('fplSavedContent', 
    {
        callSign: "",
        endurance: "0400",
        aircraftSpeed: "0090",
        flightTime: "0300",
        aircraftType: "ULAC",
        pob: "001",
    });


    function fplContentChanged (target){
        const {name, value} = target
        setFplContent (prevValue => {
            return({
                ...prevValue,
                [name]: value.trim()
                }
            )
        })
    }

/***********************************************/

    const closeDialog = ()=>{ 
        setAlertOpen(false)
        setAlertMessage("")
        closeFplDialog();
    }

/***********************************************/

    const OpenPicInfoDialog = ()=>{
        setPicInfoDialogOpen(true)
    }
    
/***********************************************/
    const OpenTimeDialog = ()=>{
        setTimeDialogOpen(true)
    }
    
/***********************************************/

    const showAlert = (message) =>{
        setAlertMessage(message);
        setAlertOpen(true);
    }

/***********************************************/

    const openRouteAlertDialog = () => {
        setRouteAlertDialogOpen(true);
    };

    const closeRouteAlertDialog = (submit) => {
        setRouteAlertDialogOpen(false);
        if(submit){
            submitFpl()
        }
    };

/***********************************************/

    const openFplSubmitDialog = () => {
      setFplSubmitDialogOpen(true);
    };
  
    const closeFplSubmitDialog = () => {
      setFplSubmitDialogOpen(false);
    };

    const fplSubmitted = () =>{
        // Save the departure and the landing time
        const index = flightTimeValues.findIndex((a) => a[0] === fplContent.flightTime);
        if (index > -1) {
            const depTime     =  new Date(departureTime.getTime()); 
            const landingTime =  new Date(departureTime.getTime()); 
            landingTime.setMinutes(landingTime.getMinutes() + flightTimeValues[index][2])

            var fplTime = {departure: depTime, landing: landingTime};
            let timeArray = [...lastFplTimes]
            timeArray.push(fplTime)
            while (timeArray.length > 4) timeArray.shift()

            setLastFplTimes(timeArray)
        }
    }

        
/***********************************************/

    // Calculate the flight time
    useEffect(() => {
        // exact flight time in minutes
        const exactFlightTime = routeLength / parseFloat(fplContent.aircraftSpeed) * 60

        const index = flightTimeValues.findIndex((a) => exactFlightTime <=  a[2]);

        const flightTimeString =  index > -1 ? flightTimeValues[index][0] : flightTimeValues[flightTimeValues.length - 1][0]

        fplContentChanged({name: "flightTime", value: flightTimeString})

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeLength,fplContent.aircraftSpeed]);

/***********************************************/

    // alert from verify route
    useEffect(() => {
        if(fplDialogOpen){
            const verifyAlerts = VerifyRoute(route, departureTime, mapUsed, timeCategory)
            setRouteAlerts(verifyAlerts)
            if(verifyAlerts.length){
                var message = "";
                for (let i = 0; i < verifyAlerts.length; i++) {
                    message += verifyAlerts[i];
                    if(i !== verifyAlerts.length-1){
                        message +=" <> "
                    }
                }
                showAlert(message)
            }else{
                setAlertOpen(false);
            }
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departureTime,fplDialogOpen]);


    /***********************************************/
    function verifyDialogFields(){

        const fplFields = 
        [
            [fplContent.callSign,    callSignRef,   "אנא ציין את אות הקריאה"],
        ]
       
        for (let i = 0; i < fplFields.length; i++) {
            if(fplFields[i][0].length ===0){
                showAlert(fplFields[i][2])
                fplFields[i][1].current.focus();
                return false;
            }
        }


        if(fplContent.callSign.trim().length !== 3 && fplContent.callSign.substr(0,2).toUpperCase() !== '4X'){
            showAlert("מטוסים ברישום ישראלי בלבד"); 
            return false;
        }

        if(fplContent.callSign.trim().indexOf(' ') > 3 || fplContent.callSign.trim().indexOf(',') > 0){
            showAlert("ניתן להגיש תוכנית למטוס אחד בלבד"); 
            return false;
        }

        if(fplContent.callSign.trim().length > 8){
            showAlert("אות הקריאה אינו חוקי"); 
            return false;
        }

        return true
    }


    /***********************************************/

    // Create the email 
    function submitFpl(timeOverlap = false)
    {
        const email = CreateEmailContent(fplContent, picInfo, departureTime, route, mapUsed, timeOverlap)

        if (email ){
            setEmailContent(email)
            openFplSubmitDialog();
            closeDialog();
        } 
    }

    const closeMsgDialog = () => setMsgDialogOpen(false)

    const showMessage = (title, content, trueButtonText) =>{
        setMsgTitle(title);
        setMsgContent(content);
        setTrueButtonText(trueButtonText);
        setMsgDialogOpen(true);
    }

    const errorMessage = (errorStr) => {
        showMessage("שגיאה בתוכנית הטיסה",errorStr, "חזור" )
    }

    /***********************************************/

    const checkNoErrors = () =>{

        // Check if sending time is legal
        const sendingTimeError = VerifySendingTime(departureTime)
        if(sendingTimeError){
            errorMessage(sendingTimeError)
            return false;
        }

        // Check that the route is for one lag only 
        for (let i = 1; i < route.length - 1 ; i++) {
            if(isAirport(route[i].icao)){
                const err = "סימנת נחיתת ביניים ב" + route[i].name + ".\nיש להגיש תוכנית טיסה נפרדת לכל לג."
                errorMessage(err)            
                return false;
            }
        }

        // Check if plan used atc permission routes
        const atcPermissionMessages =  checkIfAtcPermissionRoutes(route, mapUsed)
        if(atcPermissionMessages.length){
            var atcError = ""
            atcPermissionMessages.forEach(message => {
                atcError += message + '.\n'
            });
            errorMessage(atcError)            
            return false;
        }

        return true;
    }

    /***********************************************/
    const timeOverlapSelectionResult = (result) => {
        setSelectionDialogOpen(false)
        if(!result){
            submitFpl(true)           
        }
    }

    /***********************************************/
    const airportSelectionResult = (result) => {
        setAirportDialogOpen(false)
        if(!result){
            submitFpl(true)           
        } else{
            closeDialog()
        }
    }
    /***********************************************/

    function verifyAndSubmitFpl()
    {
        if(checkNoErrors())
        {
            if(verifyDialogFields()){
                if (routeAlerts.length > 0){
                    openRouteAlertDialog();
                    closeDialog();
                } else {

                    // Check if the plan time overlap previous sent plan
                    const timeOverlap = IsTimeOverlap(departureTime, fplContent.flightTime, lastFplTimes)
                    if(timeOverlap){
                        setMsgContent(timeOverlap)
                        setSelectionDialogOpen(true)
                    }else{
                        const suspectedAirport = isSuspectedAirport(route, fplContent.aircraftType)
                        if(suspectedAirport){
                            setMsgContent(suspectedAirport)
                            setAirportDialogOpen(true)
                        }else{
                            submitFpl()           
                        }
                    }
                }
            }
        }
    }


    const timePadding = `המראה ב ${departureTime.toLocaleDateString('he-IL')} זמן מקומי: ${departureTime.toLocaleTimeString('he-IL')}`;
    const picPadding = `PIC: ${picInfo.picName}, ${picInfo.licenseNum}, ${picInfo.emailAddress}.`


    /***********************************************/

    return(
        <>

        <RouteAlert 
            routeAlerts = {routeAlerts}
            routeAlertDialogOpen = {routeAlertDialogOpen} 
            closeRouteAlertDialog = {closeRouteAlertDialog}
        />

        <FplSubmit 
            eContent = {emailContent} 
            departureTime = {departureTime}
            fplContent = {fplContent}
            picInfo = {picInfo}
            route = {route} 
            fplSubmitDialogOpen = {fplSubmitDialogOpen} 
            closeFplSubmitDialog = {closeFplSubmitDialog}
            fplSubmitted = {fplSubmitted}
            mapUsed = {mapUsed}
            patreonPilot = {patreonPilot}
        />
    
        <MsgDialog
            msgDialogOpen = {selectionDialogOpen}
            msgTitle = "הגשה כפולה"
            msgContent = {msgContent}
            closeMsgDialog = {timeOverlapSelectionResult}
            trueButtonText = "חזור"
            falseButtonText = "ביטלתי בשיחה עם מודיעין טייס"
        />

        <MsgDialog

        msgDialogOpen = {airportDialogOpen}
            msgTitle = "אנא וודא נקודות המראה או נחיתה"
            msgContent = {msgContent}
            closeMsgDialog = {airportSelectionResult}
            trueButtonText = "חזור"
            falseButtonText = "אני מודע לכך - המשך"
        />


        <MsgDialog
            msgDialogOpen = {msgDialogOpen}
            msgTitle = {msgTitle}
            msgContent = {msgContent}
            closeMsgDialog = {closeMsgDialog}
            trueButtonText = {trueButtonText}
            icon = {<ErrorIcon/>}
        />

        <Dialog className="fpl-dialog" scroll='body' open={fplDialogOpen}>

        <Collapse in={alertOpen}>
            <Alert dir = "rtl" sx={{textAlign: "right"}} severity="error">
                {alertMessage}
            </Alert>
        </Collapse>

        <DialogTitle textAlign="center">תוכנית טיסה</DialogTitle>
          <DialogContent>

            <RouteNames route = {route} routeLength = {routeLength} />
         
            <p></p>
            {/* <DialogContentText dir = "rtl" sx={{textAlign: "right"}}>{timePadding}</DialogContentText> */}

            <Box dir = "rtl" sx={{textAlign: "right", display: 'flex', alignItems: 'center' }}>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <DialogContentText >{timePadding}</DialogContentText>
                </Stack>
                    <Button onClick={OpenTimeDialog} sx={{ textTransform: 'none' }}>עדכן</Button>
            </Box>

            <FplTextField 
                defaultValue={fplContent.callSign} 
                name="callSign"
                label="Call Sign"
                inputRef = {callSignRef}
                setFunc = {fplContentChanged}
            />

            <FplSelector  
                label="Aircraft Type"
                value={fplContent.aircraftType ?? "ULAC"}
                name="aircraftType"
                setFunc = {fplContentChanged}
                menuItems = {aircraftTypeValues }
            />


            <FplSelector  
                label="Aircraft Speed"
                value={fplContent.aircraftSpeed ?? "0080"}
                name="aircraftSpeed"
                setFunc = {fplContentChanged}
                menuItems = {speedValues}
            />

            <FplSelector  
                label="Flight Duration"
                value={fplContent.flightTime ?? "0300"}
                name="flightTime"
                setFunc = {fplContentChanged}
                menuItems = {flightTimeValues}
            />

            <FplSelector  
                label="Fuel endurance"
                value={fplContent.endurance ?? "0400"}
                name="endurance"
                setFunc = {fplContentChanged}
                menuItems = {enduranceValues}
            />

            <FplSelector  
                label="Persons On Board"
                value={(!fplContent.pob || (props.mapUsed === "lsa" && parseInt(fplContent.pob) > 2 )) ? "001" : fplContent.pob}
                name="pob"
                setFunc = {fplContentChanged}
                menuItems = {(props.mapUsed === "lsa") ? lsaPobValues : gaPobValues }
            />

            <p></p>            
            <p></p>  

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <DialogContentText >{picPadding}</DialogContentText>
                </Stack>
                    <Button onClick={OpenPicInfoDialog} sx={{ textTransform: 'none' }}>Update</Button>
            </Box>
            
          </DialogContent>

          <DialogActions className="fpl-submit">
            <Button onClick={closeDialog}>בטל</Button>
            <Button variant="contained" onClick={verifyAndSubmitFpl }>המשך</Button>
          </DialogActions>
        
      </Dialog>
      </>
    )    
}

export default FplDialog;
