import {useState, useEffect} from "react";


// get and set the variables in the local storage
export function useLocalStorage (storageKey, fallbackState) {
    const [value, setValue] = useState(
      JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
    );
  
    useEffect(() => {
      localStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);
  
    return [value, setValue]; 
};

// get and set the variables in the local storage
export function getLocalStorageKey (storageKey) {
  return JSON.parse(localStorage.getItem(storageKey))
};