import {useEffect,useRef,useState} from "react";
import {useSearchParams } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import {Polyline, Marker ,Pane, useMap} from 'react-leaflet';
import {ArrowheadsPolyline} from "../utils/react-leaflet-arrowheads"
import GeoUtil from "leaflet-geometryutil";
import {LoadUrlParams} from "../utils";
import {FindNearestFeature} from "../airways";


export function RenderRoute(props){

    const {route, appendToRoute, setPlanedRoute, changePoint, insertPoint, removePoint, mapUsed ,timeCategory, rules, openAirways,resetRoute} = props

  
    var origLatLng = null;
    var lineIndex = -1;
    const [movedMarkerKey,setMovedMarkerKey] = useState(0);
    const lineRef = useRef(null)
    const dragMarkerRef = useRef(null)
    const flightMap = useMap()
    const defaultDragMarkerPosition = [32.1158, 34.8522]

    const waypointIcon = 
      L.divIcon({
        iconAnchor: [6, 6],
        html: ReactDOMServer.renderToString(
          <svg 
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <circle fill="blue" cx="20" cy="20" r="2"/>
          </svg>),
        className: 'waypoint-icon',
      })

      const StartWaypointIcon = 
      L.divIcon({
        iconAnchor: [6, 6],
        html: ReactDOMServer.renderToString(
         <svg 
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <circle fill="blue" stroke="#fff" strokeWidth="5px" cx="20" cy="20" r="18"/>
         </svg>),
        className: 'waypoint-icon',
      })

   /***********************************************/
    // Change the latlng of a point in the polyline. used while moving a point

    const setPolylineLatLng = (mLatlng) => {
      
      const polyline = lineRef.current.polylineRef

      if( polyline && lineIndex > -1){

        // Get the polyline's latlngs
        var pLatLngs = polyline.getLatLngs()

        // Replace the old latlng with the new
        pLatLngs.splice(lineIndex, 1, mLatlng);

        // Update the polyline with the new latlngs
        polyline.setLatLngs(pLatLngs);
      }

    }

/***********************************************/
    // start handling of moving a point in the polyline

    const dragStartHandler = (e) => {

      // save the original latlng
      origLatLng = e.target.getLatLng()

      const polyline = lineRef.current.polylineRef

      if(polyline){
        // Get the polyline's latlngs
        var latlngs = polyline.getLatLngs()

        // Iterate the polyline's latlngs
        for (var i = 0; i < latlngs.length; i++) {

          // Compare each to the marker's latlng
          if (origLatLng.equals(latlngs[i])) {

            // If equals store key in marker instance
            lineIndex = i;
          }
        }
      }
    }

/***********************************************/

    // called while a marker in the polyline is moved
    const dragHandler = (e) => {
      setPolylineLatLng(e.target.getLatLng())
    }

/***********************************************/

    // end dragging of the waypoint
    const dragEndHandler = (e) => {

      setMovedMarkerKey(e.target.options.waypointKey)

      const point = {
        "latlng": e.target.getLatLng(),
        "name"  : e.target.options.title,
        "icao"  : "none",
        "key"   : e.target.options.waypointKey
      }
      const updatedPoint = FindNearestFeature(point,mapUsed, timeCategory,rules, openAirways)

      // If found a feature update the point
      if(updatedPoint.icao !== "none"){
        changePoint(updatedPoint)
      } else {
        // if not found, return the marker to the original position
        if(origLatLng){
          e.target.setLatLng(origLatLng)
          setPolylineLatLng(origLatLng)
        }
      }
      origLatLng = null;
      lineIndex = -1;
    }

/***********************************************/

    // start drag the transparent marker on the line segment
    const DMdragStartHandler = (e) => {

      // save the original latlng
      origLatLng = e.target.getLatLng()

      const polyline = lineRef.current.polylineRef

      if(polyline){

        // Get the polyline's latlngs
        var pLatlngs = polyline.getLatLngs()

        var SegmentIndex = -1;
        var pointDistance = 10000;
        
        for (let i = 0; i < pLatlngs.length - 1; i++) {
  
          const distanceToSegment  = GeoUtil.distanceSegment(flightMap,origLatLng, pLatlngs[i], pLatlngs[i+1])
  
          if(distanceToSegment < pointDistance ){
            pointDistance = distanceToSegment;
            SegmentIndex = i;
           }
        }

        if(SegmentIndex > -1)
        {
          pLatlngs.splice(SegmentIndex + 1, 0, origLatLng)

          // Update the polyline with the new latlngs
          polyline.setLatLngs(pLatlngs);

          // store key in marker instance
          lineIndex = SegmentIndex + 1;
        }
      }
    }

/***********************************************/

    // End dragging of the transparent marker on the line segment
    const DMdragEndHandler = (e) => {

      const point = {
        "latlng": e.target.getLatLng(),
        "name"  : e.target.options.title,
        "icao"  : "none",
        "key"  : Math.random() * 1000
      }

      const updatedPoint = FindNearestFeature(point, mapUsed, timeCategory,rules, openAirways)

      // If found a feature insert a new point
      if(updatedPoint.icao !== "none"){
        insertPoint(updatedPoint,lineIndex)
      } else {
        // else: remove the point from the polyline
        const polyline = lineRef.current.polylineRef
        if(polyline){
          var pLatlngs = polyline.getLatLngs()
          pLatlngs.splice(lineIndex, 1);
          polyline.setLatLngs(pLatlngs);
        }
      }

      origLatLng = null;
      lineIndex = -1;
    }

/***********************************************/

// Create  a marker on the polyline when hovering on it.
    const WaypointClicked = (e) => {

      if(movedMarkerKey !== e.target.options.waypointKey){

        const latlng =  e.target.getLatLng();
        const name = e.target.options.title;
        const icao = e.target.options.icao;
        
        appendToRoute(latlng,name,icao);
      }
    }

/***********************************************/

    // Create  a marker on the polyline and start dragging.
    const lineMouseDown = (e) => {

      const dragMarker = dragMarkerRef.current
      dragMarker.setLatLng(e.latlng)

      flightMap.dragging._draggable.finishDrag();
      dragMarker.dragging.enable();
      dragMarker.dragging._draggable._onDown(e.originalEvent)
    }
  
/***********************************************/

    // right-click handler - delete the marker
    const deleteMarker = (e) => {
      const key = e.target.options.waypointKey
      if(key){
        removePoint(key)
      }
    }
  
// /***********************************************/

    // Load a route from the command line

    /* eslint-disable no-unused-vars */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable no-unused-vars */
    useEffect(() => 
    {
      const  mapToUse = (searchParams.get("map") === 'cvfr' ) ? 'cvfr': 'lsa'

      var routeStr = searchParams.get("route");

      if(routeStr && routeStr.length){
        LoadUrlParams(routeStr,setPlanedRoute,resetRoute,flightMap,mapToUse, timeCategory,rules, openAirways)
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAirways,timeCategory, rules]); 

/***********************************************/
    useEffect(() => 
    {
      setMovedMarkerKey(0)
    }, [route.length]); 

/***********************************************/

    const blueOptions = { color: 'blue',weight: 7,}
    const transparentOptions = { weight: 30,opacity: 0}
    const polylinePositions = route.map ((point) => [point.latlng.lat,point.latlng.lng]);

/***********************************************/

         
    return (

      <>
        <Pane name="line" style={{ zIndex: 1000 }}>
          <Polyline 
            pathOptions={transparentOptions} 
            eventHandlers={{
              mousedown: lineMouseDown,
              touchstart: lineMouseDown,
            }}
            positions={polylinePositions} 
            bubblingMouseEvents = {true}
            zIndexOffset = {500}
          />

          <ArrowheadsPolyline 
            pathOptions={blueOptions} 
            eventHandlers={{
              mousedown: lineMouseDown,
              touchstart: lineMouseDown,
            }}
            positions={polylinePositions} 
            arrowheads={{ size: '15px'}}
            bubblingMouseEvents = {true}
            zIndexOffset = {1000}
            ref={lineRef}
          />
        </Pane>

        <Pane name="marker-pane" style={{ zIndex: 10000 }}>

          {route.map((waypoint,index) => 
            <Marker
                eventHandlers={{
                  dragstart: dragStartHandler,
                  dragend: dragEndHandler,
                  drag: dragHandler,
                  contextmenu: deleteMarker,
                  click: WaypointClicked,
                }}
                draggable={true}
                autoPan={true}
                title={waypoint.name}
                waypointKey= {waypoint.key}
                key={"wp" + waypoint.key}
                icao= {waypoint.icao}
                icon= {index === 0 ? StartWaypointIcon:  waypointIcon}
                zIndexOffset = {3000}
                bubblingMouseEvents = {false}
                position={[
                  waypoint.latlng.lat,
                  waypoint.latlng.lng
                ]}
              >
           </Marker>
          ) }

          <Marker
            eventHandlers={{
              dragstart: DMdragStartHandler,
              dragend: DMdragEndHandler,
              drag: dragHandler,
            }}
            draggable={true}
            autoPan={true}
            icon={waypointIcon}
            opacity={0}
            zIndexOffset = {3000}
            position={defaultDragMarkerPosition}
            ref={dragMarkerRef}
          >
          </Marker>

        </Pane>
      </>        
    )
  }

  export default RenderRoute;
