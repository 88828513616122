import { Main} from './components';
import { logError } from './utils/';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import {ErrorBoundary} from 'react-error-boundary';
import { inject } from '@vercel/analytics';

const App = () => {
  const TRACKING_ID = 'G-82VGEJQFD6'; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  inject();

  const logErrorMessage = (error, info) => {
    console.log('error: ', error.message);
    console.log('info: ', info.componentStack);
    if(process.env.REACT_APP_RUN_ENV === "SERVER"){
      logError("ErrorBoundary", error.message, info.componentStack, null)
    }
  };

  const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
    return (
      <div>
        <h1>An error occurred: {error.message}</h1>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={OurFallbackComponent} onError={logErrorMessage}>
      <Router>
        <div className="map-wrapper">
          <Main/>
        </div>
      </Router>
      </ErrorBoundary>
  );
};

export default App;
