import {FormControl } from '@mui/material'
import {LocalizationProvider, DatePicker, TimePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
// import enLocale from "date-fns/locale/en-GB";
import heLocale from "date-fns/locale/he";


export function FlightTime(props){

    const {departureTime, updateDepartureTime} = props

    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={heLocale}
      >
        <FormControl variant='standard' sx={{ mt: 2, mr: 2, minWidth: 120 }} dir="ltr">
          <DatePicker
            label='Departure Date'
            value={departureTime}
            format='dd/MM/yyyy'
            mask='__/__/____'
            disablePast
            onChange={(newValue) => {
              updateDepartureTime(newValue);
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ mt: 2, mr: 2, minWidth: 120 }} dir="ltr">
          <TimePicker
            // sx={{ m: 1, minWidth: 120 }}
            mode='24h'
            label='Departure Local Time'
            value={departureTime}
            disablePast={true}
            minutesStep={5}
            // minTime={new Date()}
            disableIgnoringDatePartForTimeValidation = {true}
            onChange={(newValue) => {
              updateDepartureTime(newValue);
            }}
          />
        </FormControl>
      </LocalizationProvider>
    );
}

export default FlightTime