export const lsaNegevReportPoints = [
  {
    "name": "אוהלים",
    "icao": "OHLIM",
    "latlng": [
      31.191111,
      34.804722
    ],
    "key": "OHLIM",
    "route": [
      {
        "icao": "ZSARA",
        "distance": 23,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "HOVAV",
        "distance": 33,
        "alt": 1700,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "באר אורה",
    "icao": "BRORA",
    "latlng": [
      29.708056,
      34.997222
    ],
    "key": "BRORA",
    "route": [
      {
        "icao": "SAMAR",
        "distance": 76,
        "alt": 1300,
        "time": 5,
        "active": true
      },
      {
        "icao": "EILAT",
        "distance": 93,
        "alt": 500,
        "time": 5,
        "active": false
      }
    ]
  },
  {
    "name": "אילת",
    "icao": "EILAT",
    "latlng": [
      29.56106216802423,
      34.95806553380164
    ],
    "key": "EILAT",
    "route": [
      {
        "icao": "BRORA",
        "distance": 93,
        "alt": 500,
        "time": 5,
        "active": false
      }
    ]
  },
  {
    "name": "באר מנוחה",
    "icao": "BMNUH",
    "latlng": [
      30.303333,
      35.134167
    ],
    "key": "BMNUH",
    "route": [
      {
        "icao": "ZOFAR",
        "distance": 164,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "YAHEL",
        "distance": 132,
        "alt": 1400,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZZHOR",
        "distance": 63,
        "alt": 1400,
        "time": 5,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 113,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 83,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "הר גבס",
    "icao": "HRGVS",
    "latlng": [
      30.622778,
      34.95
    ],
    "key": "HRGVS",
    "route": [
      {
        "icao": "ZOFAR",
        "distance": 128,
        "alt": 2600,
        "time": 5,
        "active": true
      },
      {
        "icao": "RUHOT",
        "distance": 76,
        "alt": 3500,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "חורבות עבדת",
    "icao": "OVDAT",
    "latlng": [
      30.802778,
      34.793333
    ],
    "key": "OVDAT",
    "route": [
      {
        "icao": "KNFHA",
        "distance": 46,
        "alt": 2700,
        "time": 5,
        "active": true
      },
      {
        "icao": "BOKER",
        "distance": 43,
        "alt": 3000,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "חטיבת הנגב",
    "icao": "HTIVA",
    "latlng": [
      31.26035600000001,
      34.832055
    ],
    "key": "HTIVA",
    "route": [
      {
        "icao": "SOKET",
        "distance": 40,
        "alt": 2200,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZGOAL",
        "distance": 30,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZSARA",
        "distance": 22,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "NCITY",
        "distance": 26,
        "alt": 2000,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "חצבה",
    "icao": "HAZVA",
    "latlng": [
      30.773333,
      35.236389
    ],
    "key": "HAZVA",
    "route": [
      {
        "icao": "ZOFAR",
        "distance": 130,
        "alt": 500,
        "time": 5,
        "active": true
      },
      {
        "icao": "ARAVA",
        "distance": 135,
        "alt": 800,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLEY",
        "distance": 95,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BZHAR",
        "distance": 66,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 209,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "טללים",
    "icao": "TLLIM",
    "latlng": [
      30.990556,
      34.762222
    ],
    "key": "TLLIM",
    "route": [
      {
        "icao": "BOKER",
        "distance": 76,
        "alt": 2700,
        "time": 5,
        "active": true
      },
      {
        "icao": "NEGEV",
        "distance": 60,
        "alt": 2500,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "יהל",
    "icao": "YAHEL",
    "latlng": [
      30.08222200000001,
      35.133333
    ],
    "key": "YAHEL",
    "route": [
      {
        "icao": "KTORA",
        "distance": 63,
        "alt": 1400,
        "time": 5,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 132,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 64,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "יטבתה",
    "icao": "YOTVT",
    "latlng": [
      29.89949856068781,
      35.06645407100982
    ],
    "key": "YOTVT",
    "route": [
      {
        "icao": "SAMAR",
        "distance": 25,
        "alt": 1000,
        "time": 5,
        "active": true
      },
      {
        "icao": "KTORA",
        "distance": 36,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLYO",
        "distance": 5,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "כובשים",
    "icao": "KUVSH",
    "latlng": [
      31.2591938207152,
      34.7635424594819
    ],
    "key": "KUVSH",
    "route": [
      {
        "icao": "NCITY",
        "distance": 20,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLBS",
        "distance": 25,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "כלא נפחא",
    "icao": "KNFHA",
    "latlng": [
      30.727222,
      34.775278
    ],
    "key": "KNFHA",
    "route": [
      {
        "icao": "RUHOT",
        "distance": 44,
        "alt": 3200,
        "time": 5,
        "active": true
      },
      {
        "icao": "OVDAT",
        "distance": 46,
        "alt": 3000,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "מיתר",
    "icao": "MYTAR",
    "latlng": [
      31.314722,
      34.938056
    ],
    "key": "MYTAR",
    "route": [
      {
        "icao": "SOKET",
        "distance": 19,
        "alt": 3000,
        "time": 5,
        "active": true
      },
      {
        "icao": "TARAD",
        "distance": 99,
        "alt": 2200,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "מעלה מישר",
    "icao": "MESAR",
    "latlng": [
      30.501667,
      34.943611
    ],
    "key": "MESAR",
    "route": [
      {
        "icao": "ZZHOR",
        "distance": 151,
        "alt": 2200,
        "time": 5,
        "active": true
      },
      {
        "icao": "RUHOT",
        "distance": 118,
        "alt": 3500,
        "time": 5,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 58,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BHARF",
        "distance": 86,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "נוה זוהר",
    "icao": "ZOHAR",
    "latlng": [
      31.150833,
      35.3725
    ],
    "key": "ZOHAR",
    "route": [
      {
        "icao": "ENGDI",
        "distance": 190,
        "alt": 800,
        "time": 5,
        "active": true
      },
      {
        "icao": "ARAVA",
        "distance": 102,
        "alt": 500,
        "time": 5,
        "active": true
      },
      {
        "icao": "TZHTR",
        "distance": 79,
        "alt": 2600,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 107,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "סמר",
    "icao": "SAMAR",
    "latlng": [
      29.83072832562986,
      35.02815388352084
    ],
    "key": "SAMAR",
    "route": [
      {
        "icao": "BRORA",
        "distance": 76,
        "alt": 700,
        "time": 5,
        "active": true
      },
      {
        "icao": "YOTVT",
        "distance": 25,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLER",
        "distance": 62,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "ערד",
    "icao": "ARRAD",
    "latlng": [
      31.25409192295236,
      35.20978546218794
    ],
    "key": "ARRAD",
    "route": [
      {
        "icao": "TARAD",
        "distance": 45,
        "alt": 2600,
        "time": 5,
        "active": true
      },
      {
        "icao": "TZHTR",
        "distance": 33,
        "alt": 2300,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLAR",
        "distance": 18,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 48,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BMTAR",
        "distance": 127,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת גורל",
    "icao": "ZGOAL",
    "latlng": [
      31.310833,
      34.7875
    ],
    "key": "ZGOAL",
    "route": [
      {
        "icao": "HTIVA",
        "distance": 30,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZLHAV",
        "distance": 37,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "SOKET",
        "distance": 58,
        "alt": 1700,
        "time": 6,
        "active": false
      },
      {
        "icao": "NASIH",
        "distance": 47,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת הנגב",
    "icao": "NEGEV",
    "latlng": [
      31.06495496668587,
      34.83801485217604
    ],
    "key": "NEGEV",
    "route": [
      {
        "icao": "YRUHM",
        "distance": 59,
        "alt": 2200,
        "time": 6,
        "active": true
      },
      {
        "icao": "TLLIM",
        "distance": 60,
        "alt": 2200,
        "time": 5,
        "active": true
      },
      {
        "icao": "HOVAV",
        "distance": 45,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSHEV",
        "distance": 150,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 180,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "SECHR",
        "distance": 52,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת הערבה",
    "icao": "ARAVA",
    "latlng": [
      30.9910548345531,
      35.30907302726861
    ],
    "key": "ARAVA",
    "route": [
      {
        "icao": "ZOHAR",
        "distance": 102,
        "alt": 800,
        "time": 5,
        "active": true
      },
      {
        "icao": "HAZVA",
        "distance": 135,
        "alt": 500,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת הרוחות",
    "icao": "RUHOT",
    "latlng": [
      30.66121434522548,
      34.80898558656826
    ],
    "key": "RUHOT",
    "route": [
      {
        "icao": "HRGVS",
        "distance": 76,
        "alt": 3200,
        "time": 5,
        "active": true
      },
      {
        "icao": "KNFHA",
        "distance": 44,
        "alt": 3500,
        "time": 5,
        "active": true
      },
      {
        "icao": "MESAR",
        "distance": 118,
        "alt": 3200,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLMR",
        "distance": 5,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 55,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BHARF",
        "distance": 139,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 167,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת חתרורים",
    "icao": "TZHTR",
    "latlng": [
      31.21829302478802,
      35.24792442592972
    ],
    "key": "TZHTR",
    "route": [
      {
        "icao": "ARRAD",
        "distance": 33,
        "alt": 2600,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZOHAR",
        "distance": 79,
        "alt": 2300,
        "time": 5,
        "active": true
      },
      {
        "icao": "BZHAR",
        "distance": 206,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת להבים",
    "icao": "ZLHAV",
    "latlng": [
      31.3725,
      34.793333
    ],
    "key": "ZLHAV",
    "route": [
      {
        "icao": "SOKET",
        "distance": 75,
        "alt": 2200,
        "time": 5,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 46,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZGOAL",
        "distance": 37,
        "alt": 1700,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת ציחור",
    "icao": "ZZHOR",
    "latlng": [
      30.276667,
      35.02
    ],
    "key": "ZZHOR",
    "route": [
      {
        "icao": "SITIM",
        "distance": 60,
        "alt": 2200,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 63,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "MESAR",
        "distance": 151,
        "alt": 2500,
        "time": 5,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 123,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 67,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BZNIF",
        "distance": 70,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת קטורה",
    "icao": "KTORA",
    "latlng": [
      29.989444,
      35.076389
    ],
    "key": "KTORA",
    "route": [
      {
        "icao": "SIZFN",
        "distance": 51,
        "alt": 2500,
        "time": 6,
        "active": true
      },
      {
        "icao": "YOTVT",
        "distance": 36,
        "alt": 1000,
        "time": 5,
        "active": true
      },
      {
        "icao": "YAHEL",
        "distance": 63,
        "alt": 1700,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צומת שוקת",
    "icao": "SOKET",
    "latlng": [
      31.3075,
      34.901111
    ],
    "key": "SOKET",
    "route": [
      {
        "icao": "MYTAR",
        "distance": 19,
        "alt": 2500,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZLHAV",
        "distance": 75,
        "alt": 2500,
        "time": 5,
        "active": true
      },
      {
        "icao": "HTIVA",
        "distance": 40,
        "alt": 2200,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSHEV",
        "distance": 52,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BMTAR",
        "distance": 38,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "ZGOAL",
        "distance": 58,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת שרה",
    "icao": "ZSARA",
    "latlng": [
      31.22434864432154,
      34.82797945345876
    ],
    "key": "ZSARA",
    "route": [
      {
        "icao": "OHLIM",
        "distance": 23,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "HTIVA",
        "distance": 22,
        "alt": 2000,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צופר",
    "icao": "ZOFAR",
    "latlng": [
      30.559722,
      35.186667
    ],
    "key": "ZOFAR",
    "route": [
      {
        "icao": "HRGVS",
        "distance": 128,
        "alt": 2900,
        "time": 5,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 164,
        "alt": 1400,
        "time": 5,
        "active": true
      },
      {
        "icao": "HAZVA",
        "distance": 130,
        "alt": 800,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLEY",
        "distance": 37,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 82,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "צפון העיר",
    "icao": "NCITY",
    "latlng": [
      31.287213,
      34.790883
    ],
    "key": "NCITY",
    "route": [
      {
        "icao": "HTIVA",
        "distance": 26,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "KUVSH",
        "distance": 20,
        "alt": 2000,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "רמת חובב",
    "icao": "HOVAV",
    "latlng": [
      31.135556,
      34.805
    ],
    "key": "HOVAV",
    "route": [
      {
        "icao": "NEGEV",
        "distance": 45,
        "alt": 1700,
        "time": 5,
        "active": true
      },
      {
        "icao": "OHLIM",
        "distance": 33,
        "alt": 2000,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSVTA",
        "distance": 148,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "שדה בוקר",
    "icao": "BOKER",
    "latlng": [
      30.875,
      34.785833
    ],
    "key": "BOKER",
    "route": [
      {
        "icao": "TLLIM",
        "distance": 76,
        "alt": 3000,
        "time": 5,
        "active": true
      },
      {
        "icao": "OVDAT",
        "distance": 43,
        "alt": 2700,
        "time": 5,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 77,
        "alt": 0,
        "time": 5,
        "active": true
      },
      {
        "icao": "BSVTA",
        "distance": 164,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "תל ערד",
    "icao": "TARAD",
    "latlng": [
      31.2775,
      35.125556
    ],
    "key": "TARAD",
    "route": [
      {
        "icao": "MYTAR",
        "distance": 99,
        "alt": 2500,
        "time": 5,
        "active": true
      },
      {
        "icao": "ARRAD",
        "distance": 45,
        "alt": 2300,
        "time": 5,
        "active": true
      },
      {
        "icao": "LLAR",
        "distance": 44,
        "alt": 0,
        "time": 5,
        "active": true
      }
    ]
  }
]