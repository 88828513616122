
export const byAtcPermissionLsaRoutes = [
    ["BNTOF1","ELYKM"],
    ["BNTOF1","FRDIS"],
    ["BNTOF1","CNHRL"],
    ["BNTOF1","BOREN"],
    ["BSARS","MHOLA"],
    ["TAVOR","ZEMAH"],
    ["NSHRM","NAHEM"],
    ["ETZEM","NEGEV"],
]

export const byAtcPermissionCvfrRoutes = [
    ["AYLON","LLBG"],
    ["NSHRM","LLBG"],
    ["BSEMS","NSHRM"],
    ["BSEMS","SORES"],
    ["BSEMS","LTRUN"],
    ["BSEMS","SHARG"],
    ["TYONA","NMASD"],
    ["GALIM","AAKKO"],
    ["TAVOR","ALUMT"],
    ["ALUMT","LLFK"],
    ["YRUHM","NEGEV"],
    ["TAVOR","LLFK"],
]


// One way routes check
export const oneWayRoutes = [
    ["HADRA","OLGAH"],
    ["SDTYM","FRDIS"],
    ["OLGAH","SDTYM"],
    ["OLGAH","FRDIS"],
    ["SDTYM","EIRON"],
    ["AAKKO","AHIUD"],
    ["SFAIM","LLHZ"],
    ["LLHZ","KNTRY"],
    ["LLHZ","HTZUK"],
    ["ARRAD","MMORR"],
    ["LLMZ","ARRAD"]
]

// export const weekendRoutes = [
//     ["NSHRM","NCSHN"],
//     ["NCSHN","NSHRM"],
//     ["SHFAM","TYONA"],
// ]


export const lsaOneWayPatterns = [
    [["LLRS", "TYONA"], ["TYONA", "SHEFC", "SOREK", "MEHOL", "LLRS" ]],
    [["MNSZN", "RSNIM", "IKKEA", "MEHOL","LLRS"],["LLRS", "SOREK", "BOVED", "IANES"]],
    [["TYONA", "SHEFC", "SOREK"],["SOREK", "MEHOL", "TYONA"]],
    [["SOREK", "MEHOL","LLRS"],["LLRS", "SOREK"]],
    [["MNSZN", "RSNIM", "IKKEA", "MEHOL"],["SOREK", "BOVED", "IANES"]],
]


export const cvfrOneWayPatterns = [
    [["RSNIM", "LLRS"],["LLRS", "SOREK", "BOVED"]],
    [["OLGAH", "HADRA", "EIRON"], ["EIRON", "SDTYM", "OLGAH"]],
    [["FRDIS", "SDTYM", "OLGAH"], ["OLGAH", "HADRA", "FRDIS"]],
    [["LLMZ", "MMORR", "ARRAD"], ["ARRAD", "LLMZ"]],
    [["VINGT","SFAIM", "APOLN", "ARENA","HTZUK", "KNTRY", "LLHZ"], ["LLHZ", "SFAIM","VINGT"]],
    [["RIDNG", "HTZUK", "KNTRY","LLHZ"], ["LLHZ","SFAIM","APOLN", "ARENA", "HTZUK", "RIDNG"]],
]

export const cvfrReplacePatterns = [
    [["LLRS","SOREK", "BOVED", "NAGID"],["LLRS", "TYONA", "CLORE", "TYONA", "SUPER", "NTAIM", "BOVED", "NAGID"]],
    [["BOVED", "SIRNI", "RSNIM", "LLRS"],["BOVED", "NTAIM", "SUPER", "TYONA", "CLORE", "TYONA", "LLRS"]],
]

export const nonLandingPoints = [
    "IKKEA","MEHOL", "SOREK", "TYONA", "SHEFC", 
    "DAROM", "GALIM", 
    "BAZRA", "GNYAM", "ARENA"
]






