import {useState, useEffect} from "react";
import {MsgDialog} from '.'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, TextField , Link , ListItemText, ListItemIcon, MenuItem, Menu} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {submitHelpEmail, deviceInfo} from '../utils/'
import RestartAltIcon from '@mui/icons-material/RestartAlt';



const CustomFontTheme = createTheme({
  typography: {
    fontSize: 14
  }
});

// 
export function HelpDialog (props) {

    const {setHelpDialogOpen, helpDialogOpen,picInfo, mapUsed, patreonPilot} = props

    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [msgDialogOpen, setMsgDialogOpen] = useState(false);
    const [msgTitle, setMsgTitle] = useState("");
    const [msgContent, setMsgContent] = useState("");
    const [trueButtonText, setTrueButtonText] = useState("");

    useEffect(() => {
        setName(picInfo.picName);
        setEmailAddress(picInfo.emailAddress)
        setPhoneNumber(picInfo.phoneNumber)
    }, [picInfo]);

    const closeDialog = ()=>{ 
      setHelpDialogOpen(false)
    }

    const messageBox = (title,content,buttonText = 'חזור') => {
      setMsgTitle(title);
      setMsgContent(content);
      setMsgDialogOpen(true);
      setTrueButtonText(buttonText)
    }

    const doarSuccess = (msg) =>{
      messageBox( "ההודעה נשלחה", "נשתדל לחזור אליך בהקדם")
    }

    const doarFailed = (msg) =>{
      messageBox( "בעיה בשליחת ההודעה","הודעת השגיאה היא: " + msg.text)
    }

    /***********************************************/
  
    const submitMessage = () => {

        const eContent = {
          from_name: name ? name : 'unknown',
          from_email: emailAddress ? emailAddress : 'unknown',
          message: message ? message : 'unknown',
          phone: phoneNumber ? phoneNumber : 'unknown',
          mapUsed: mapUsed ? mapUsed : 'unknown',
          deviceInfo: deviceInfo(),
          patreonPilot: patreonPilot,
        }

        submitHelpEmail (eContent, doarSuccess, doarFailed) 

        setMessage("")

        messageBox( "ההודעה נשלחת...","","")

        closeDialog()
    }
    
    /***********************************************/
    const handleOpenMenu = (e) => {
      // console.log('handleOpenMenu');
      e.stopPropagation()
      e.preventDefault();
      closeDialog();
      setAnchorElMenu(e.currentTarget);
    };
    /***********************************************/

    const handleCloseMenu = () => {
      setAnchorElMenu(null);
    };
   
    /***********************************************/
   
    const resetLocalStorage = () => {
     handleCloseMenu()
     localStorage.clear();
     window.location.reload(true);
   }
   
   /***********************************************/
   function MItem (props){
    const {text, icon, action} = props
    return(
      <MenuItem onClick={action} disabled = {props.disabled}>
          <ListItemIcon size="small"> 
            {icon} 
          </ListItemIcon>
          <ListItemText primary={text}  primaryTypographyProps={{fontSize: '14px', textAlign: "right"}}/>
      </MenuItem>
    )
   }    
  
    return(
      <>
        <Dialog className="fpl-submit" open={helpDialogOpen} onClose={closeDialog}>
          <DialogTitle onContextMenu={handleOpenMenu}>עזרה</DialogTitle>
          <ThemeProvider theme={CustomFontTheme}>
            <DialogContent >

              <DialogContentText dir="rtl" textAlign="right" >
                לפני תחילת השמוש באפליקציה יש לקרוא את ההוראות 
                <Link href="data/manual.pdf" target="_blank">{' '}כאן </Link>
              </DialogContentText>
              
              <p></p>

              <DialogContentText dir="rtl" textAlign="right">
                לעזרה בתפעול האפליקציה והצעות לשיפורים:          
              </DialogContentText>

              <p></p>

              <TextField
                dir = "RTL"
                required
                margin="dense"
                defaultValue={name}
                placeholder= "שם"
                name="name"
                size="sm"
                fullWidth
                variant="outlined"
                InputLabelProps={{ style: { fontSize: 14 } }}
                inputProps={{style: {height: "15px",  fontSize: 14}}}
                onChange={(event) => {setName(event.target.value.trim())}} 
              />

              <TextField
                dir = "RTL"
                required
                margin="dense"
                defaultValue={emailAddress}
                placeholder= "כתובת אימייל"
                name="Email"
                size="sm"
                fullWidth
                variant="outlined"
                InputLabelProps={{ style: { fontSize: 14 } }}
                inputProps={{style: {height: "15px",  fontSize: 14}}}
                onChange={(event) => {setEmailAddress(event.target.value.trim())}} 
              />
    
              <TextField
                dir = "RTL"
                required
                margin="dense"
                defaultValue={message}
                placeholder= "שאלה"
                name="message"
                multiline
                minRows={4}
                size="sm"
                fullWidth
                variant="outlined"
                InputLabelProps={{ style: { fontSize: 14 } }}
                inputProps={{style: { fontSize: 14}}}
                onChange={(event) => {setMessage(event.target.value.trim())}} 
              />
    
            </DialogContent>
          </ThemeProvider>

          <DialogActions>
          <Button onClick={closeDialog}>בטל</Button>
          <Button 
              variant="contained"
              onClick={submitMessage}
              disabled={(emailAddress.length === 0) || (name.length === 0) || (message.length === 0)}
              >
              שלח
          </Button>
          </DialogActions>
        </Dialog>

        <MsgDialog
          msgDialogOpen = {msgDialogOpen}
          msgTitle = {msgTitle}
          msgContent = {msgContent}
          closeMsgDialog =  {() => setMsgDialogOpen(false)}
          trueButtonText = {trueButtonText}        >
        </MsgDialog>

        <Menu 
          dir="RTL" 
          sx={{textAlign: "right",  mt: '45px'}}
          anchorEl={anchorElMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{ style: { width: 280,},}} 
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          >
          {/* <MItem text = "מחק נקודה אחרונה" icon = {<UndoIcon/>} action = {removeLastPointInRouteEvent} /> */}
          <MItem text = "אפס את נתוני האפליקציה" icon = {<RestartAltIcon/>} action = {resetLocalStorage} />
      </Menu>

      </>
    )    
}

export default HelpDialog;
