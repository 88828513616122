import {useState,useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import {submitPlan, sendMailtoMessage, useLocalStorage, deviceInfo} from '../utils/'
import {MsgDialog, DonateDialog, SentSuccessDialog} from '.'
// import {ThumbUpIcon} from '../assets';


// 
export function FplSubmit (props) {

    const { eContent,fplContent,picInfo, departureTime, route , fplSubmitDialogOpen ,closeFplSubmitDialog, fplSubmitted, mapUsed, patreonPilot } = props

    const [airportMessage, setAirportMessage] = useState("");
    // const [patreonPilots, setPatreonPilots] = useState({});
    // const [patreonPilot, setPatreonPilot] = useState(false);

    const [msgDialogOpen, setMsgDialogOpen] = useState(false);
    const [doarFailedDialogOpen, setDoarFailedDialogOpen] = useState(false);
    const [msgTitle,   setMsgTitle] = useState("");
    const [msgContent, setMsgContent] = useState("");
    const [msgIcon,    setMsgIcon] = useState(null);
    const [msgProgress,setMsgProgress] = useState(false);
    const [trueButtonText,setTrueButtonText] = useState("");
    const [doarSent,setDoarSent] = useState(false);
    const [backAndForthRoute,setBackAndForthRoute] = useState(false);
    const [donateDialogOpen, setDonateDialogOpen] = useState(false);
    const [sentDialogOpen, setSentDialogOpen] = useState(false);
    const [sendFplCopy,   setSendFplCopy] = useLocalStorage('sendFplCopy',true);

    const clearSelections = ()=>{
        setVv1Checked(false);
        setVv2Checked(false);
        setVv3Checked(false);
    }

/***********************************************/

    function submitFpl()
    {
        clearSelections();

        eContent.cc_to = sendFplCopy && eContent.reply_to ? eContent.reply_to : ""
        eContent.fplContent = fplContent;
        eContent.picInfo = picInfo;
        eContent.mapUsed = mapUsed
        eContent.route = route
        eContent.departureTime = departureTime
        eContent.deviceInfo = deviceInfo()

        closeFplSubmitDialog();

        setMsgTitle("התוכנית נשלחת למודיעין טייס...");
        setMsgContent("")
        setMsgProgress(true)
        setTrueButtonText("");
        setMsgDialogOpen(true)
        
        submitPlan (eContent, doarSuccess, doarFailed) 
    }

/***********************************************/

    const doarSuccess = (msg) => {

        fplSubmitted()

        setMsgDialogOpen(false)
        
        setSentDialogOpen(true)
       
    }


/***********************************************/

    const sendFromEmailClient = (toSend) =>{

        if(toSend){
            var emailText = ""
        
            eContent.icaoFpl.forEach(line => {
                emailText += line +  "\n"
            });
    
            
            sendMailtoMessage({
                emailSubject: eContent.subject, 
                emailText: emailText
            })
        }

        setDoarFailedDialogOpen(false);
    }


/***********************************************/

    const doarFailed = (msg) => {

        setMsgDialogOpen(false)

        var content = ""
        if(msg.status && msg.text){
            content += "הודעת השגיאה היא: \nStatus: " + msg.status + "\nMessage: " + msg.text + "\n\n\n"
        }

        content += " האפליקציה תנסה לפתוח את תוכנת האימייל שלך ותמלא שם את התוכנית. אנא שלח את התוכנית באופן ידני משם. "
        setMsgContent(content)
        setDoarFailedDialogOpen(true)
    }
    
/***********************************************/

    const closeMsgDialog = () =>{

        setMsgDialogOpen(false)
        setMsgIcon(null)
        setMsgProgress(false)

        // If second sent or land in the same place a departed and not a patreon pilot then display the donate dialog
        if ((doarSent || backAndForthRoute) && !patreonPilot){
            setDonateDialogOpen(true)
        }
        setDoarSent(true)
    }

/***********************************************/

    const closeDialog = ()=>{ 
        clearSelections();
        closeFplSubmitDialog()
    }

/***********************************************/

    var airports = ["LLIB", "LLHZ", "LLER"];
    var airstrips = ["LLRU", "LLSO", "LLNN", "LLUR", "LLTS", "LLHF", "LLSS", "LLGV","LLBO", "LLMG", "LLMZ", "LLAR", "LLFK", "LLKS", "LLRS"];

    useEffect(() => {

        if(route.length){

            var icaoDeparting = route[0].icao;
            var icaoLanding = route[route.length - 1].icao;

            if(airports.includes(icaoLanding) && icaoDeparting !== icaoLanding){
                var airport = route[route.length - 1].name
                var airportMsg =  "תאמתי עם מגדל " + airport
                if (icaoLanding === 'LLIB' && mapUsed === 'lsa')(
                    airportMsg += " 24 שעות מראש"
                )
                setAirportMessage(airportMsg);
            } else if(airstrips.includes(icaoLanding) && icaoDeparting !== icaoLanding){
                var airstrip = route[route.length - 1].name
                setAirportMessage("תאמתי עם מפעיל מנחת " + airstrip);
            } else{
                setAirportMessage("")
            }

            setBackAndForthRoute(icaoDeparting === icaoLanding)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fplSubmitDialogOpen]);

    const [v1Checked, setVv1Checked] = useState(false);
    const [v2Checked, setVv2Checked] = useState(false);
    const [v3Checked, setVv3Checked] = useState(false);

    const handleV1Change = (event) => {setVv1Checked(event.target.checked);};
    const handleV2Change = (event) => {setVv2Checked(event.target.checked);};
    const handleV3Change = (event) => {setVv3Checked(event.target.checked);};
   
    var verify1 = 'בדקתי שהתוכנית מתאימה לפמ"ת ולנוטאמים המפורסמים';
    var verify2 = "בדקתי את תחזית מזג האויר";
    var verify3 = airportMessage;
    
/***********************************************/
    
    return(
    
        <>
        <Dialog className="fpl-submit" open={fplSubmitDialogOpen}>
        <DialogTitle>אנא אשר\י:</DialogTitle>
        <DialogContent >

          <FormGroup>
            <FormControlLabel 
                control={ <Checkbox checked={v1Checked} onChange={handleV1Change} />} 
                label= {verify1} 
            />

            <FormControlLabel 
                control={ <Checkbox checked={v2Checked} onChange={handleV2Change} />} 
                label= {verify2} 
            />

            {verify3.length > 0 &&
                <FormControlLabel 
                    control={<Checkbox checked={v3Checked} onChange={handleV3Change} />} 
                    label= {verify3} 
                />
            }

          </FormGroup>

          <DialogContentText style={{mb:0, color:'#000000'}} dir="rtl" textAlign="right">
            זו תוכנית הטיסה:
          </DialogContentText>

         <p></p>
          <DialogContentText style={{mt:0, textAlign: 'left', color:'#000000', fontSize: 12}} dir="ltr" >

            {eContent.icaoFpl ? eContent.icaoFpl.map((line,index) => <span  key={index}> {line}<br/></span>) : null}
                
          </DialogContentText>
          <p></p>

          <DialogContentText style={{color:'#000000'}} dir="rtl" textAlign="right">
            בלחיצה על שלח, התוכנית תשלח למודיעין טייס.
          </DialogContentText>
          {eContent.reply_to ? <DialogContentText style={{color:'#000000'}} dir="rtl" textAlign="right">התשובה תתקבל לכתובת {eContent.reply_to}</DialogContentText>:null}

          <FormControlLabel 
                control={ <Checkbox checked={sendFplCopy}  onChange={(event) => {setSendFplCopy(event.target.checked)}} />} 
                label= {"שלח לי עותק"} 
           />

        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog}>בטל</Button>
          <Button 
            variant="contained"
            onClick={submitFpl}
            disabled={(v1Checked ?false:true) || (v2Checked ?false:true) || ((verify3.length && !v3Checked)?true:false)}
            >
            שלח
          </Button>
        </DialogActions>
      </Dialog>

      <MsgDialog
        msgDialogOpen = {msgDialogOpen}
        msgTitle = {msgTitle}
        msgContent = {msgContent}
        closeMsgDialog = {closeMsgDialog}
        trueButtonText = {trueButtonText}
        icon = {msgIcon}
        progress = {msgProgress}
      >
      </MsgDialog>

      <MsgDialog
        msgDialogOpen = {doarFailedDialogOpen}
        msgTitle = "בעיה בשליחת תוכנית הטיסה"
        msgContent = {msgContent}
        closeMsgDialog = {sendFromEmailClient}
        trueButtonText = "שלח מתוכנת האימייל שלי"
        falseButtonText = "בטל"
      >
      </MsgDialog>

      <DonateDialog
        donateDialogOpen = {donateDialogOpen}
        setDonateDialogOpen = {setDonateDialogOpen}
        picInfo = {picInfo}
      ></DonateDialog>

      <SentSuccessDialog
        sentDialogOpen = {sentDialogOpen}
        setSentDialogOpen = {setSentDialogOpen}
        picInfo = {picInfo}
      ></SentSuccessDialog>

    </>
    )    
}

export default FplSubmit;
