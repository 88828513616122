import { memo, useState} from "react";
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import {LoadAirstrips} from "../airways";
import {AirstripIcon} from "../assets";
import {NotamNotification} from ".";

  export function Airstrips(props) {

    const {addToRoute,mapUsed, rules, openAirways} = props
    console.log('Loading airstrips');
    // const airstrips = LoadAirstrips( rules);
    const airstrips = LoadAirstrips(mapUsed,rules, openAirways);;

    const [notamNotificationOpen , setNotamNotificationOpen] = useState(false)
    const [closeDescription , setCloseDescription] = useState("")
    const [originalNotam , setOriginalNotam] = useState("")


    const airstripClicked = (latLng, airstrip) => {


      if(airstrip.closed){
        setCloseDescription(airstrip.closeDescription)
        airstrip.originalNotam && setOriginalNotam(airstrip.originalNotam)
        setNotamNotificationOpen(true)
      }else {
        addToRoute(latLng,airstrip.title,airstrip.icao)
      }
    }

    const createMarker = (airstrip) => {

      const color = airstrip.closed? "#ff0000" : "#0000ff"
            
      const icon = L.divIcon({
        iconAnchor: [16, 16],
        className: 'airstrip-icon',
        html: ReactDOMServer.renderToString(<AirstripIcon direction={airstrip.direction} color = {color} />)
      });


      return (
        <Marker
          key={Math.random() * 1000}
          title={airstrip.name}
          icao={airstrip.icao}
          position={airstrip.latlng}
          icon={icon}
          closed = {airstrip.closed}
          closeDescription = {airstrip.closeDescription}
          originalNotam = {airstrip.originalNotam}
          eventHandlers={{
            click: (e) => {
              airstripClicked(e.target.getLatLng(), e.target.options)

            //  addToRoute(e.target.getLatLng(),e.target.options.title,e.target.options.icao)
            },
          }}
        />
      )
    }

    // Create markers for all the airstrips
    return (
      <>
      {airstrips.map((airstrip) => createMarker(airstrip))}

      <NotamNotification  
        notamNotificationOpen = {notamNotificationOpen}  
        setNotamNotificationOpen = {setNotamNotificationOpen}
        description = {closeDescription}
        originalNotam = {originalNotam} 
      />

      </>

    )
    
  }

export default memo(Airstrips);
