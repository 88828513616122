import * as React from "react"

export function SadFaceIcon(props) {

  return (

<svg 
  xmlns="http://www.w3.org/2000/svg" 
  // xmlns:xlink="http://www.w3.org/1999/xlink" 
  version="1.1" 
  width="64" 
  height="64" 
  viewBox="0 0 64 64" 

  // xml:space="preserve"
  >

<defs>
</defs>
  <g 
    style={{stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1}}
    transform="scale(0.7 0.7)" 
    >
    <circle cx="45" cy="45" r="45" 
      style= {{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:" rgb(255,202,40)", fillRule: "nonzero", opacity: 1 }}
      transform="  matrix(1 0 0 1 0 0) "
    />
    <circle cx="31" cy="35.55" r="5" 
      style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(96,67,35)", fillRule: "nonzero", opacity: 1}} 
      transform="  matrix(1 0 0 1 0 0) "
    />
    <circle cx="59" cy="35.55" r="5" 
      style={{ stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(96,67,35)", fillRule: "nonzero", opacity: 1}} 
      transform="matrix(1 0 0 1 0 0) "
    />
    <path 
      d="M 63.087 67.345 c -0.647 0 -1.283 -0.313 -1.668 -0.894 c -3.671 -5.529 -9.809 -8.83 -16.419 -8.83 s -12.749 3.301 -16.418 8.83 c -0.611 0.92 -1.852 1.173 -2.772 0.56 c -0.92 -0.61 -1.171 -1.852 -0.561 -2.772 C 29.662 57.59 37.046 53.621 45 53.621 s 15.338 3.969 19.751 10.617 c 0.611 0.921 0.36 2.162 -0.56 2.772 C 63.851 67.237 63.467 67.345 63.087 67.345 z" 
      style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(96,67,35)", fillRule: "nonzero", opacity: 1}} 
      transform=" matrix(1 0 0 1 0 0) " 
      strokeLinecap="round" 
    />
    <path
      d="M 82.583 20.258 C 84.779 25.758 86 31.752 86 38.035 C 86 64.525 64.525 86 38.035 86 c -6.283 0 -12.277 -1.221 -17.777 -3.417 C 27.358 87.266 35.858 90 45 90 c 24.853 0 45 -20.147 45 -45 C 90 35.858 87.266 27.358 82.583 20.258 z" 
      style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:" rgb(231,182,34)", fillRule: "nonzero", opacity: 1}} 
      transform=" matrix(1 0 0 1 0 0)" 
      strokeLinecap="round" 
    />
  </g>
</svg>
  )
}

export default SadFaceIcon
