import {Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, List} from '@mui/material';

// 
export function RouteAlert (props) {

    const { routeAlerts, routeAlertDialogOpen , closeRouteAlertDialog } = props


/***********************************************/

    const closeDialogAndSubmit = ()=>{ 
        closeRouteAlertDialog(true)
    }

/***********************************************/

    const closeDialogAndCancel = ()=>{ 
        closeRouteAlertDialog(false)
    }

/***********************************************/
    
    return(
    
        <Dialog className="fpl-submit" open={routeAlertDialogOpen}>
        <DialogTitle>שים לב:</DialogTitle>
        <DialogContent >

          <List
            //   color="primary" 
              style={{ 
                overflow: 'auto' 
              }}>
              {routeAlerts.map((alert,index) => (
                  <ListItem style={{ textAlign:"right", paddingRight: 5, paddingLeft: 5}} key={index} > 
                    - {alert} 
                  </ListItem>
              ))}
          </List>
          <p style={{color: 'red'}}>
          במצב זה קיימים סיכויים טובים שתוכנית הטיסה לא תאושר!
          </p>
          
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialogAndSubmit} >
            המשך בכל זאת
          </Button>
          <Button variant="contained" onClick={closeDialogAndCancel}>
            חזור
          </Button>
        </DialogActions>
      </Dialog>
    )    
}

export default RouteAlert;
