import * as React from "react"

export function ThankYouIcon(props) {

  return (

<svg 
  xmlns="http://www.w3.org/2000/svg" 
  version="1.1" 
  width="64" 
  height="64" 
  viewBox="0 0 64 64" 
  >
<defs>
</defs>
<g 
  style={{stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1}}
  transform="translate(1.4065934065934016 1.4065934065934016) scale(0.7 0.7)" >
  <path 
    d="M 15.846 64.098 c 5.726 7.477 12.507 13.308 20.791 16.797 v 2.999 c 0 2.82 -2.286 5.106 -5.106 5.106 H 6.938 c -2.633 0 -4.768 -2.135 -4.768 -4.768 V 73.485 c 0 -1.857 1.078 -3.544 2.762 -4.325 L 15.846 64.098 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(67,152,209)", fillRule: "nonzero", opacity: 1}}
    transform="matrix(1 0 0 1 0 0)" 
    strokeLinecap="round" 
  />
	<path 
    d="M 36.528 4.355 l -2.147 23.168 c -3.108 8.418 -5.549 16.646 -5.179 25.71 c -0.346 5.186 -6.342 10.969 -11.053 13.711 c 4.618 5.406 9.897 9.781 16.032 12.819 l 2.21 -1.452 C 41.764 74.78 45 68.782 45 62.352 V 5.582 c 0 -3.452 -3.817 -5.784 -6.726 -3.925 C 37.29 2.285 36.61 3.249 36.528 4.355 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(254,201,163)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 41.996 1.286 v 53.027 c 0 7.371 -3.709 14.247 -9.869 18.295 l -2.534 1.665 c -4.047 -2.004 -7.758 -4.53 -11.197 -7.481 c -0.082 0.049 -0.166 0.104 -0.248 0.151 c 4.618 5.406 9.897 9.781 16.032 12.819 l 2.21 -1.452 C 41.764 74.78 45 68.782 45 62.352 V 5.582 C 45 3.551 43.676 1.917 41.996 1.286 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(237,182,143)", fillRule: "nonzero", opacity: 1}}
    transform="matrix(1 0 0 1 0 0)" 
    strokeLinecap="round" 
    />
	<path 
    d="M 23.341 72.291 c -2.687 -2.437 -5.182 -5.173 -7.495 -8.193 l -1.171 0.543 C 17.34 67.54 20.215 70.111 23.341 72.291 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(59,139,193)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 31.631 78.373 v 1.516 c 0 2.82 -2.286 5.106 -5.106 5.106 H 2.247 C 2.615 87.263 4.566 89 6.938 89 h 24.593 c 2.82 0 5.106 -2.286 5.106 -5.106 v -2.999 C 34.899 80.163 33.241 79.305 31.631 78.373 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(59,139,193)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 74.154 64.098 c -5.726 7.477 -12.507 13.308 -20.791 16.797 v 2.999 c 0 2.82 2.286 5.106 5.106 5.106 h 24.593 c 2.633 0 4.768 -2.135 4.768 -4.768 V 73.485 c 0 -1.857 -1.078 -3.544 -2.762 -4.325 L 74.154 64.098 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(67,152,209)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 53.472 4.355 l 2.147 23.168 c 3.108 8.418 5.549 16.646 5.179 25.71 c 0.346 5.186 6.342 10.969 11.053 13.711 c -4.618 5.406 -9.897 9.781 -16.032 12.819 l -2.21 -1.452 C 48.236 74.78 45 68.782 45 62.352 V 5.582 c 0 -3.452 3.817 -5.784 6.726 -3.925 C 52.71 2.285 53.39 3.249 53.472 4.355 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(254,201,163)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 60.798 53.232 c 0.37 -9.063 -2.071 -17.292 -5.179 -25.71 L 53.472 4.355 c -0.082 -1.106 -0.762 -2.07 -1.746 -2.699 c -0.439 -0.281 -0.9 -0.455 -1.364 -0.555 c 0.156 0.355 0.272 0.725 0.288 1.12 l 1.387 23.226 c 2.83 8.515 5 16.819 4.334 25.866 c 0.176 5.194 4.955 12.149 9.574 15.044 c -4.434 4.859 -8.547 7.975 -14.071 10.661 c 0.551 0.46 1.128 0.895 1.736 1.294 l 2.21 1.452 c 6.136 -3.038 11.414 -7.414 16.032 -12.819 C 67.14 64.201 61.144 58.418 60.798 53.232 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(237,182,143)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 85.068 69.16 l -2.271 -1.053 c 0.01 0.124 0.027 0.247 0.027 0.373 v 10.746 c 0 2.633 -2.135 4.768 -4.768 4.768 H 53.462 c -0.031 0 -0.06 -0.009 -0.09 -0.009 C 53.421 86.762 55.68 89 58.469 89 h 24.593 c 2.633 0 4.768 -2.135 4.768 -4.768 V 73.485 C 87.83 71.629 86.752 69.941 85.068 69.16 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(59,139,193)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 31.531 90 H 6.938 c -3.181 0 -5.768 -2.588 -5.768 -5.769 V 73.485 c 0 -2.237 1.312 -4.291 3.341 -5.232 l 10.914 -5.063 c 0.425 -0.197 0.929 -0.072 1.215 0.299 c 6.007 7.845 12.675 13.237 20.385 16.484 c 0.371 0.156 0.612 0.52 0.612 0.922 v 2.999 C 37.637 87.261 34.898 90 31.531 90 z M 15.544 65.34 L 5.353 70.067 c -1.326 0.615 -2.183 1.957 -2.183 3.418 v 10.746 C 3.17 86.31 4.861 88 6.938 88 h 24.593 c 2.264 0 4.106 -1.842 4.106 -4.105 v -2.341 C 28.067 78.236 21.482 72.923 15.544 65.34 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 34.181 80.763 c -0.151 0 -0.303 -0.034 -0.444 -0.104 c -5.964 -2.953 -11.464 -7.35 -16.349 -13.066 c -0.193 -0.226 -0.275 -0.524 -0.226 -0.817 c 0.05 -0.292 0.227 -0.547 0.483 -0.696 c 4.873 -2.836 10.213 -8.299 10.555 -12.862 c -0.35 -8.833 1.822 -16.757 5.197 -25.917 l 2.134 -23.029 c 0.106 -1.37 0.909 -2.629 2.204 -3.457 c 1.618 -1.033 3.612 -1.085 5.334 -0.142 C 44.877 1.662 46 3.543 46 5.582 v 56.77 c 0 6.788 -3.387 13.066 -9.06 16.795 l -2.211 1.452 C 34.564 80.707 34.373 80.763 34.181 80.763 z M 19.676 67.176 c 4.416 4.981 9.153 8.732 14.438 11.434 l 1.728 -1.135 C 40.95 74.117 44 68.464 44 62.352 V 5.582 c 0 -1.308 -0.725 -2.517 -1.892 -3.156 c -1.089 -0.597 -2.291 -0.57 -3.296 0.074 c -0.763 0.487 -1.232 1.19 -1.287 1.93 c 0 0.006 -0.001 0.012 -0.001 0.018 l -2.147 23.168 c -0.008 0.087 -0.027 0.172 -0.058 0.254 c -3.325 9.006 -5.467 16.759 -5.118 25.322 c 0.001 0.036 0.001 0.072 -0.001 0.107 C 29.833 58.8 24.044 64.339 19.676 67.176 z M 36.528 4.355 h 0.01 H 36.528 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 83.062 90 H 58.469 c -3.366 0 -6.105 -2.739 -6.105 -6.105 v -2.999 c 0 -0.402 0.241 -0.766 0.611 -0.922 c 7.711 -3.247 14.378 -8.64 20.385 -16.484 c 0.285 -0.372 0.79 -0.496 1.215 -0.299 l 10.914 5.063 c 2.03 0.941 3.342 2.995 3.342 5.232 v 10.746 C 88.83 87.412 86.242 90 83.062 90 z M 54.363 81.554 v 2.341 c 0 2.264 1.842 4.105 4.105 4.105 h 24.593 c 2.078 0 3.769 -1.69 3.769 -3.769 V 73.485 c 0 -1.461 -0.857 -2.803 -2.184 -3.418 l -10.19 -4.728 C 68.519 72.922 61.934 78.236 54.363 81.554 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 55.819 80.763 c -0.192 0 -0.383 -0.056 -0.549 -0.164 l -2.21 -1.452 C 47.387 75.418 44 69.14 44 62.352 V 5.582 c 0 -2.039 1.123 -3.92 2.931 -4.91 c 1.724 -0.943 3.717 -0.891 5.334 0.142 c 1.296 0.828 2.099 2.087 2.203 3.458 L 56.603 27.3 c 3.375 9.16 5.547 17.084 5.196 25.918 c 0.342 4.563 5.683 10.024 10.556 12.861 c 0.257 0.149 0.434 0.404 0.483 0.696 c 0.049 0.293 -0.033 0.592 -0.226 0.817 c -4.885 5.717 -10.386 10.113 -16.35 13.066 C 56.123 80.729 55.971 80.763 55.819 80.763 z M 49.502 1.997 c -0.54 0 -1.088 0.143 -1.61 0.429 C 46.725 3.065 46 4.274 46 5.582 v 56.77 c 0 6.113 3.05 11.767 8.158 15.123 l 1.728 1.135 c 5.285 -2.701 10.022 -6.452 14.438 -11.434 c -4.368 -2.837 -10.156 -8.377 -10.523 -13.877 c -0.002 -0.035 -0.003 -0.071 -0.001 -0.107 c 0.35 -8.563 -1.793 -16.316 -5.118 -25.322 c -0.03 -0.082 -0.049 -0.167 -0.058 -0.254 L 52.476 4.447 c 0 -0.006 -0.001 -0.012 -0.001 -0.019 C 52.42 3.689 51.951 2.986 51.188 2.5 C 50.665 2.165 50.088 1.997 49.502 1.997 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1}} 
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
    />
</g>
</svg>
 )
}

export default ThankYouIcon
