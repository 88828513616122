export const cvfrReportPoints = [
  {
    "name": "כפר חסידים",
    "icao": "HASID",
    "latlng": [
      32.7463334787741,
      35.07737734554715
    ],
    "key": "HASID",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 59,
        "alt": 2500,
        "active": false
      },
      {
        "icao": "MOVIL",
        "distance": 79,
        "alt": 2500,
        "active": false
      },
      {
        "icao": "LLHA",
        "distance": 40,
        "alt": 2500,
        "active": false
      },
      {
        "icao": "FRDIS",
        "distance": 113,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "מחלף נס ציונה",
    "icao": "MNSZN",
    "latlng": [
      31.939125,
      34.815399
    ],
    "key": "MNSZN",
    "route": [
      {
        "icao": "RSNIM",
        "distance": 10,
        "alt": 700,
        "active": true
      }
    ]
  },
  {
    "name": "שורק",
    "icao": "SOREK",
    "latlng": [
      31.94158240272018,
      34.75150138723555
    ],
    "key": "SOREK",
    "route": [
      {
        "icao": "BOVED",
        "distance": 16,
        "alt": 400,
        "active": true
      }
    ]
  },
  {
    "name": "יטבתה",
    "icao": "YOTVT",
    "latlng": [
      29.90058298080351,
      35.06887899207007
    ],
    "key": "YOTVT",
    "route": [
      {
        "icao": "SAMAR",
        "distance": 48,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "KTORA",
        "distance": 53,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "LLYO",
        "distance": 5,
        "alt": 0,
        "active": true
      },
      {
        "icao": "SHRUT",
        "distance": 33,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "שחרות",
    "icao": "SHRUT",
    "latlng": [
      29.9081145469474,
      35.00577948573437
    ],
    "key": "SHRUT",
    "route": [
      {
        "icao": "YOTVT",
        "distance": 33,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "עין גדי",
    "icao": "ENGDI",
    "latlng": [
      31.46548574385501,
      35.39802321764745
    ],
    "key": "ENGDI",
    "route": [
      {
        "icao": "SHALM",
        "distance": 56,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "MMORR",
        "distance": 129,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 85,
        "alt": 0,
        "active": true
      },
      {
        "icao": "MZDOT",
        "distance": 161,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "אילת",
    "icao": "EILAT",
    "latlng": [
      29.55781768729787,
      34.95835591354852
    ],
    "key": "EILAT",
    "route": [
      {
        "icao": "BRORA",
        "distance": 93,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "אוהלים",
    "icao": "OHLIM",
    "latlng": [
      31.19082292169153,
      34.80391027317636
    ],
    "key": "OHLIM",
    "route": [
      {
        "icao": "OMMER",
        "distance": 55,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "HOVAV",
        "distance": 33,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "להב",
    "icao": "LAHAV",
    "latlng": [
      31.38025759043893,
      34.82610076556047
    ],
    "key": "LAHAV",
    "route": [
      {
        "icao": "SOKET",
        "distance": 57,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 47,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "צדפה",
    "icao": "ZDAFA",
    "latlng": [
      31.78089536821476,
      34.59996563555352
    ],
    "key": "ZDAFA",
    "route": [
      {
        "icao": "NITZA",
        "distance": 37,
        "alt": 800,
        "active": true
      },
      {
        "icao": "NMASD",
        "distance": 34,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "צומת פורדיס",
    "icao": "FRDIS",
    "latlng": [
      32.59194444444444,
      34.94638888888888
    ],
    "key": "FRDIS",
    "route": [
      {
        "icao": "BOREN",
        "distance": 74,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "SDTYM",
        "distance": 65,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLBO",
        "distance": 36,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 78,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 113,
        "alt": 2500,
        "active": false
      }
    ]
  },
  {
    "name": "צומת פצאל",
    "icao": "FAZEL",
    "latlng": [
      32.04756420021243,
      35.46184723860319
    ],
    "key": "FAZEL",
    "route": [
      {
        "icao": "ALLON",
        "distance": 49,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "ARGMN",
        "distance": 83,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "NAAMA",
        "distance": 83,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "צופר",
    "icao": "ZOFAR",
    "latlng": [
      30.55853574417191,
      35.18687295003799
    ],
    "key": "ZOFAR",
    "route": [
      {
        "icao": "HAZVA",
        "distance": 130,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "HRGVS",
        "distance": 128,
        "alt": 4500,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 164,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "LLEY",
        "distance": 37,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צלמון",
    "icao": "ZALMN",
    "latlng": [
      32.85245593820697,
      35.41135667950888
    ],
    "key": "ZALMN",
    "route": [
      {
        "icao": "SEGEV",
        "distance": 75,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "DESHE",
        "distance": 61,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "MOVIL",
        "distance": 106,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "המוביל",
    "icao": "MOVIL",
    "latlng": [
      32.75852092147753,
      35.23314490539818
    ],
    "key": "MOVIL",
    "route": [
      {
        "icao": "ZALMN",
        "distance": 106,
        "alt": 2500,
        "active": false
      },
      {
        "icao": "HASID",
        "distance": 79,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "רווחה",
    "icao": "REVAH",
    "latlng": [
      31.64444444444445,
      34.73027777777778
    ],
    "key": "REVAH",
    "route": [
      {
        "icao": "NOAAM",
        "distance": 49,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "HODYA",
        "distance": 45,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "רוקח",
    "icao": "ROKCH",
    "latlng": [
      32.1019973475952,
      34.80279400502956
    ],
    "key": "ROKCH",
    "route": [
      {
        "icao": "PARDS",
        "distance": 22,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "RIDNG",
        "distance": 21,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "שבי ציון",
    "icao": "SZION",
    "latlng": [
      32.97873093587514,
      35.09318135319071
    ],
    "key": "SZION",
    "route": [
      {
        "icao": "SMART",
        "distance": 10,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "שגב",
    "icao": "SEGEV",
    "latlng": [
      32.86041394858018,
      35.26202477884839
    ],
    "key": "SEGEV",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 60,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "ZALMN",
        "distance": 75,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "שמרת",
    "icao": "SMRAT",
    "latlng": [
      32.95166666666667,
      35.09
    ],
    "key": "SMRAT",
    "route": [
      {
        "icao": "AKKOO",
        "distance": 12,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "SZION",
        "distance": 10,
        "alt": 1500,
        "active": true
      }
    ]
  },
  {
    "name": "שער הגיא",
    "icao": "SHARG",
    "latlng": [
      31.81913888497825,
      35.02018722121893
    ],
    "key": "SHARG",
    "route": [
      {
        "icao": "SORES",
        "distance": 30,
        "alt": 2800,
        "active": true
      },
      {
        "icao": "LTRUN",
        "distance": 21,
        "alt": 2300,
        "active": true
      }
    ]
  },
  {
    "name": "תל ערד",
    "icao": "TARAD",
    "latlng": [
      31.27704747998006,
      35.12381544734021
    ],
    "key": "TARAD",
    "route": [
      {
        "icao": "MYTAR",
        "distance": 98,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ARRAD",
        "distance": 45,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "מצפה שלם",
    "icao": "SHALM",
    "latlng": [
      31.55856485602047,
      35.40168637907792
    ],
    "key": "SHALM",
    "route": [
      {
        "icao": "ENGDI",
        "distance": 56,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "ZUKIM",
        "distance": 97,
        "alt": 4000,
        "active": true
      }
    ]
  },
  {
    "name": "נווה זוהר",
    "icao": "ZOHAR",
    "latlng": [
      31.15083333333333,
      35.3725
    ],
    "key": "ZOHAR",
    "route": [
      {
        "icao": "HATRU",
        "distance": 75,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "MMORR",
        "distance": 62,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ARAVA",
        "distance": 101,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "נעמה",
    "icao": "NAAMA",
    "latlng": [
      31.90828548290784,
      35.46192926803884
    ],
    "key": "NAAMA",
    "route": [
      {
        "icao": "ALMOG",
        "distance": 113,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "FAZEL",
        "distance": 83,
        "alt": 4000,
        "active": true
      }
    ]
  },
  {
    "name": "נצר סירני",
    "icao": "SIRNI",
    "latlng": [
      31.92263032301317,
      34.82595311060315
    ],
    "key": "SIRNI",
    "route": [
      {
        "icao": "NTAIM",
        "distance": 27,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "NSHRM",
        "distance": 32,
        "alt": 800,
        "active": true
      },
      {
        "icao": "RSNIM",
        "distance": 20,
        "alt": 700,
        "active": true
      }
    ]
  },
  {
    "name": "עירון",
    "icao": "EIRON",
    "latlng": [
      32.48152895848391,
      35.02463549679003
    ],
    "key": "EIRON",
    "route": [
      {
        "icao": "ZMGID",
        "distance": 100,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 57,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "SDTYM",
        "distance": 71,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "פלג",
    "icao": "PELEG",
    "latlng": [
      32.33074426434401,
      34.8372822889575
    ],
    "key": "PELEG",
    "route": [
      {
        "icao": "VINGT",
        "distance": 46,
        "alt": 800,
        "active": true
      },
      {
        "icao": "OLGAH",
        "distance": 62,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "SHARO",
        "distance": 34,
        "alt": 1500,
        "active": true
      }
    ]
  },
  {
    "name": "צומת הנגב",
    "icao": "NEGEV",
    "latlng": [
      31.06533325126234,
      34.83856889210528
    ],
    "key": "NEGEV",
    "route": [
      {
        "icao": "HOVAV",
        "distance": 44,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "TLLIM",
        "distance": 59,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "YRUHM",
        "distance": 59,
        "alt": 3500,
        "active": false
      }
    ]
  },
  {
    "name": "צומת הערבה",
    "icao": "ARAVA",
    "latlng": [
      30.98874223032734,
      35.30948756081563
    ],
    "key": "ARAVA",
    "route": [
      {
        "icao": "ZOHAR",
        "distance": 101,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "HAZVA",
        "distance": 134,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "צומת השרון",
    "icao": "SHARO",
    "latlng": [
      32.32379742155054,
      34.90420141703046
    ],
    "key": "SHARO",
    "route": [
      {
        "icao": "PELEG",
        "distance": 34,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "ZYAAR",
        "distance": 51,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "DEROR",
        "distance": 39,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "צומת להבים",
    "icao": "ZLHAV",
    "latlng": [
      31.3725,
      34.79333333333333
    ],
    "key": "ZLHAV",
    "route": [
      {
        "icao": "BKAMA",
        "distance": 47,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "GORAL",
        "distance": 36,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "צומת נחל חדרה",
    "icao": "HADRA",
    "latlng": [
      32.46472222222223,
      34.91222222222222
    ],
    "key": "HADRA",
    "route": [
      {
        "icao": "FRDIS",
        "distance": 78,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "EIRON",
        "distance": 57,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "ZYAAR",
        "distance": 33,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "זכריה",
    "icao": "ZHRYA",
    "latlng": [
      31.69889529532545,
      34.94433899775301
    ],
    "key": "ZHRYA",
    "route": [
      {
        "icao": "GOVRN",
        "distance": 62,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "BSEMS",
        "distance": 38,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "חצבה",
    "icao": "HAZVA",
    "latlng": [
      30.7726212743969,
      35.23738695493662
    ],
    "key": "HAZVA",
    "route": [
      {
        "icao": "ARAVA",
        "distance": 134,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ZOFAR",
        "distance": 130,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "LLEY",
        "distance": 95,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "טירת צבי",
    "icao": "TIRAT",
    "latlng": [
      32.42333983480563,
      35.52662620732821
    ],
    "key": "TIRAT",
    "route": [
      {
        "icao": "ARGMN",
        "distance": 152,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "EITAN",
        "distance": 48,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "נווה איתן",
    "icao": "EITAN",
    "latlng": [
      32.49482735199354,
      35.5334204578533
    ],
    "key": "EITAN",
    "route": [
      {
        "icao": "KOYAR",
        "distance": 72,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "TIRAT",
        "distance": 48,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "AFULA",
        "distance": 175,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "טללים",
    "icao": "TLLIM",
    "latlng": [
      30.99050290238135,
      34.76325451329951
    ],
    "key": "TLLIM",
    "route": [
      {
        "icao": "NEGEV",
        "distance": 59,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "BOKER",
        "distance": 76,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "יבנה מזרח",
    "icao": "YAVNE",
    "latlng": [
      31.87164685427276,
      34.75643907162662
    ],
    "key": "YAVNE",
    "route": [
      {
        "icao": "NAGID",
        "distance": 10,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "ZASHD",
        "distance": 36,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "יהל",
    "icao": "YAHEL",
    "latlng": [
      30.08202795802153,
      35.13498272017107
    ],
    "key": "YAHEL",
    "route": [
      {
        "icao": "KTORA",
        "distance": 62,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 132,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "ירוחם",
    "icao": "YRUHM",
    "latlng": [
      30.98933731092914,
      34.91189829709948
    ],
    "key": "YRUHM",
    "route": [
      {
        "icao": "BOKER",
        "distance": 97,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "DIMON",
        "distance": 71,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "NEGEV",
        "distance": 59,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "כוכב הירדן",
    "icao": "KOYAR",
    "latlng": [
      32.59733901681891,
      35.54316375792931
    ],
    "key": "KOYAR",
    "route": [
      {
        "icao": "ALUMT",
        "distance": 69,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "EITAN",
        "distance": 72,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "כוכב השחר",
    "icao": "SHAHR",
    "latlng": [
      31.95665364303023,
      35.33589110177388
    ],
    "key": "SHAHR",
    "route": [
      {
        "icao": "ALLON",
        "distance": 54,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "MIHMS",
        "distance": 33,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "כפר אדומים",
    "icao": "DUMIM",
    "latlng": [
      31.80361670386151,
      35.32415756539175
    ],
    "key": "DUMIM",
    "route": [
      {
        "icao": "YRIHO",
        "distance": 33,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "ANATA",
        "distance": 31,
        "alt": 5000,
        "active": true
      },
      {
        "icao": "MIHMS",
        "distance": 31,
        "alt": 4000,
        "active": true
      }
    ]
  },
  {
    "name": "כפר הנגיד",
    "icao": "NAGID",
    "latlng": [
      31.88918844158264,
      34.75548863313618
    ],
    "key": "NAGID",
    "route": [
      {
        "icao": "BOVED",
        "distance": 20,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "YAVNE",
        "distance": 10,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "כפר תבור",
    "icao": "TAVOR",
    "latlng": [
      32.69027777777777,
      35.42
    ],
    "key": "TAVOR",
    "route": [
      {
        "icao": "AFULA",
        "distance": 92,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "DESHE",
        "distance": 121,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "ALUMT",
        "distance": 65,
        "alt": 2500,
        "active": false
      }
    ]
  },
  {
    "name": "לטרון",
    "icao": "LTRUN",
    "latlng": [
      31.83861111111111,
      34.98666666666667
    ],
    "key": "LTRUN",
    "route": [
      {
        "icao": "SHARG",
        "distance": 21,
        "alt": 1800,
        "active": true
      },
      {
        "icao": "AYLON",
        "distance": 29,
        "alt": 1600,
        "active": true
      },
      {
        "icao": "ESTOL",
        "distance": 38,
        "alt": 2200,
        "active": false
      },
      {
        "icao": "BSEMS",
        "distance": 50,
        "alt": 2500,
        "active": false
      }
    ]
  },
  {
    "name": "צומת נחשון",
    "icao": "NCSHN",
    "latlng": [
      31.81654324422609,
      34.91804006870549
    ],
    "key": "NCSHN",
    "route": [
      {
        "icao": "NSHRM",
        "distance": 57,
        "alt": 1600,
        "active": false
      },
      {
        "icao": "BSEMS",
        "distance": 49,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "מור",
    "icao": "MMORR",
    "latlng": [
      31.25444444444445,
      35.36833333333333
    ],
    "key": "MMORR",
    "route": [
      {
        "icao": "ARRAD",
        "distance": 81,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ZOHAR",
        "distance": 62,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "ENGDI",
        "distance": 129,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 45,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף הראל",
    "icao": "HAREL",
    "latlng": [
      31.79861111111111,
      35.14611111111111
    ],
    "key": "HAREL",
    "route": [
      {
        "icao": "SORES",
        "distance": 35,
        "alt": 5000,
        "active": true
      },
      {
        "icao": "HNINA",
        "distance": 36,
        "alt": 4500,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף ראשון",
    "icao": "MRISN",
    "latlng": [
      31.97694444444444,
      34.79194444444444
    ],
    "key": "MRISN",
    "route": [
      {
        "icao": "NTAIM",
        "distance": 20,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "LLBG",
        "distance": 52,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "אבליים",
    "icao": "EVLYM",
    "latlng": [
      32.82407678318937,
      35.15094789802967
    ],
    "key": "EVLYM",
    "route": [
      {
        "icao": "GILAM",
        "distance": 8,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "AHIUD",
        "distance": 55,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "SEGEV",
        "distance": 60,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 59,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "אולגה",
    "icao": "OLGAH",
    "latlng": [
      32.43083152651656,
      34.86555511598031
    ],
    "key": "OLGAH",
    "route": [
      {
        "icao": "PELEG",
        "distance": 62,
        "alt": 800,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 31,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 65,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת יער",
    "icao": "ZYAAR",
    "latlng": [
      32.41044355117526,
      34.90460112638889
    ],
    "key": "ZYAAR",
    "route": [
      {
        "icao": "HADRA",
        "distance": 33,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "SHARO",
        "distance": 51,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "אחיהוד",
    "icao": "AHIUD",
    "latlng": [
      32.91107448841782,
      35.18087342231185
    ],
    "key": "AHIUD",
    "route": [
      {
        "icao": "AAKKO",
        "distance": 42,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "EVLYM",
        "distance": 55,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "אלומות",
    "icao": "ALUMT",
    "latlng": [
      32.71416666666667,
      35.54638888888889
    ],
    "key": "ALUMT",
    "route": [
      {
        "icao": "DESHE",
        "distance": 93,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "KOYAR",
        "distance": 69,
        "alt": 0,
        "active": true
      },
      {
        "icao": "HAROV",
        "distance": 69,
        "alt": 2500,
        "active": false
      },
      {
        "icao": "TAVOR",
        "distance": 65,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "כפר חרוב",
    "icao": "HAROV",
    "latlng": [
      32.75921138394546,
      35.66663615933437
    ],
    "key": "HAROV",
    "route": [
      {
        "icao": "ALUMT",
        "distance": 93,
        "alt": 3000,
        "active": false
      },
      {
        "icao": "LLFK",
        "distance": 29,
        "alt": 2500,
        "active": false
      }
    ]
  },
  {
    "name": "אלון",
    "icao": "ALLON",
    "latlng": [
      32.04083478408515,
      35.36623603303303
    ],
    "key": "ALLON",
    "route": [
      {
        "icao": "FAZEL",
        "distance": 49,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "SHAHR",
        "distance": 54,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "אלמוג",
    "icao": "ALMOG",
    "latlng": [
      31.78972222222222,
      35.45638888888889
    ],
    "key": "ALMOG",
    "route": [
      {
        "icao": "NAAMA",
        "distance": 113,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "YRIHO",
        "distance": 39,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ZUKIM",
        "distance": 45,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "אמנון",
    "icao": "AMNON",
    "latlng": [
      32.90492694971211,
      35.56621501806672
    ],
    "key": "AMNON",
    "route": [
      {
        "icao": "LLIB",
        "distance": 47,
        "alt": 0,
        "active": true
      },
      {
        "icao": "DESHE",
        "distance": 28,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "HULAT",
        "distance": 88,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "ארגמן",
    "icao": "ARGMN",
    "latlng": [
      32.17198526840247,
      35.53149183968335
    ],
    "key": "ARGMN",
    "route": [
      {
        "icao": "TIRAT",
        "distance": 152,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "FAZEL",
        "distance": 83,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "אשל הנשיא",
    "icao": "NASIH",
    "latlng": [
      31.32946479493418,
      34.69718980116054
    ],
    "key": "NASIH",
    "route": [
      {
        "icao": "SOVAL",
        "distance": 54,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "LLBS",
        "distance": 28,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "באר אורה",
    "icao": "BRORA",
    "latlng": [
      29.70805555555555,
      34.99722222222222
    ],
    "key": "BRORA",
    "route": [
      {
        "icao": "SAMAR",
        "distance": 75,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "EILAT",
        "distance": 93,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "LLER",
        "distance": 16,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "באר מנוחה",
    "icao": "BMNUH",
    "latlng": [
      30.30287654287833,
      35.13526054210727
    ],
    "key": "BMNUH",
    "route": [
      {
        "icao": "ZOFAR",
        "distance": 164,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "YAHEL",
        "distance": 132,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "ZZHOR",
        "distance": 63,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "בית גוברין",
    "icao": "GOVRN",
    "latlng": [
      31.60611111111111,
      34.89277777777778
    ],
    "key": "GOVRN",
    "route": [
      {
        "icao": "NOAAM",
        "distance": 63,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "ZHRYA",
        "distance": 62,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "בית עובד",
    "icao": "BOVED",
    "latlng": [
      31.91975032367341,
      34.76971952029565
    ],
    "key": "BOVED",
    "route": [
      {
        "icao": "NTAIM",
        "distance": 18,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "NAGID",
        "distance": 20,
        "alt": 800,
        "active": true
      },
      {
        "icao": "SIRNI",
        "distance": 31,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "בית קמה",
    "icao": "BKAMA",
    "latlng": [
      31.44107342882983,
      34.7646628259974
    ],
    "key": "BKAMA",
    "route": [
      {
        "icao": "SOVAL",
        "distance": 20,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "LAHAV",
        "distance": 47,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "NOAAM",
        "distance": 77,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "ZLHAV",
        "distance": 46,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "BHAIL",
        "distance": 97,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "בית שמש",
    "icao": "BSEMS",
    "latlng": [
      31.75409714428584,
      34.98079115012845
    ],
    "key": "BSEMS",
    "route": [
      {
        "icao": "ZHRYA",
        "distance": 38,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "NCSHN",
        "distance": 49,
        "alt": 4000,
        "active": false
      },
      {
        "icao": "LTRUN",
        "distance": 50,
        "alt": 3000,
        "active": false
      },
      {
        "icao": "ESTOL",
        "distance": 21,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "אשתאול",
    "icao": "ESTOL",
    "latlng": [
      31.78006065499025,
      35.01139469936242
    ],
    "key": "ESTOL",
    "route": [
      {
        "icao": "BSEMS",
        "distance": 21,
        "alt": 3500,
        "active": false
      },
      {
        "icao": "LTRUN",
        "distance": 38,
        "alt": 2200,
        "active": false
      },
      {
        "icao": "SORES",
        "distance": 55,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "הודיה",
    "icao": "HODYA",
    "latlng": [
      31.68260934088906,
      34.65135466491698
    ],
    "key": "HODYA",
    "route": [
      {
        "icao": "NITZA",
        "distance": 48,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "REVAH",
        "distance": 45,
        "alt": 1500,
        "active": true
      }
    ]
  },
  {
    "name": "תל אשקלון",
    "icao": "ASKLN",
    "latlng": [
      31.66819099570078,
      34.53855861897099
    ],
    "key": "ASKLN",
    "route": [
      {
        "icao": "NITZA",
        "distance": 35,
        "alt": 1200,
        "active": false
      },
      {
        "icao": "GVARM",
        "distance": 45,
        "alt": 1500,
        "active": false
      }
    ]
  },
  {
    "name": "גברעם",
    "icao": "GVARM",
    "latlng": [
      31.59374860563681,
      34.60868266871634
    ],
    "key": "GVARM",
    "route": [
      {
        "icao": "BHAIL",
        "distance": 30,
        "alt": 1500,
        "active": false
      },
      {
        "icao": "ASKLN",
        "distance": 56,
        "alt": 1200,
        "active": false
      }
    ]
  },
  {
    "name": "ברור חיל",
    "icao": "BHAIL",
    "latlng": [
      31.55717182355584,
      34.63179669272323
    ],
    "key": "BHAIL",
    "route": [
      {
        "icao": "GVARM",
        "distance": 30,
        "alt": 2000,
        "active": false
      },
      {
        "icao": "BKAMA",
        "distance": 97,
        "alt": 2500,
        "active": false
      }
    ]
  },
  {
    "name": "הר גבס",
    "icao": "HRGVS",
    "latlng": [
      30.62182471523796,
      34.95091813022776
    ],
    "key": "HRGVS",
    "route": [
      {
        "icao": "ZOFAR",
        "distance": 128,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "RUHOT",
        "distance": 76,
        "alt": 4500,
        "active": true
      }
    ]
  },
  {
    "name": "מעלה מישר",
    "icao": "MESAR",
    "latlng": [
      30.50174726347143,
      34.94565788316474
    ],
    "key": "MESAR",
    "route": [
      {
        "icao": "RUHOT",
        "distance": 118,
        "alt": 4500,
        "active": false
      },
      {
        "icao": "ZZHOR",
        "distance": 151,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "צומת ציחור",
    "icao": "ZZHOR",
    "latlng": [
      30.27622513892907,
      35.02164699794189
    ],
    "key": "ZZHOR",
    "route": [
      {
        "icao": "MESAR",
        "distance": 118,
        "alt": 4000,
        "active": false
      },
      {
        "icao": "BMNUH",
        "distance": 63,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "שיטים",
    "icao": "SITIM",
    "latlng": [
      30.17553179257309,
      35.01603253587221
    ],
    "key": "SITIM",
    "route": []
  },
  {
    "name": "שזפון",
    "icao": "SIZFN",
    "latlng": [
      30.04715248002912,
      35.01672041008769
    ],
    "key": "SIZFN",
    "route": []
  },
  {
    "name": "בית  חנינא",
    "icao": "HNINA",
    "latlng": [
      31.83023876674454,
      35.20712472207072
    ],
    "key": "HNINA",
    "route": [
      {
        "icao": "HAREL",
        "distance": 36,
        "alt": 5000,
        "active": true
      },
      {
        "icao": "ANATA",
        "distance": 31,
        "alt": 4500,
        "active": true
      }
    ]
  },
  {
    "name": "ראשונים",
    "icao": "RSNIM",
    "latlng": [
      31.9497173956512,
      34.80206921471677
    ],
    "key": "RSNIM",
    "route": [
      {
        "icao": "LLRS",
        "distance": 30,
        "alt": 400,
        "active": true
      }
    ]
  },
  {
    "name": "גורל",
    "icao": "GORAL",
    "latlng": [
      31.31283726047297,
      34.78814347317463
    ],
    "key": "GORAL",
    "route": [
      {
        "icao": "NCITY",
        "distance": 20,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "ZLHAV",
        "distance": 36,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "OMMER",
        "distance": 45,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "צפון העיר",
    "icao": "NCITY",
    "latlng": [
      31.28661503068826,
      34.79125093422548
    ],
    "key": "NCITY",
    "route": [
      {
        "icao": "OMMER",
        "distance": 20,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "GORAL",
        "distance": 23,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "KUVSH",
        "distance": 19,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "LLBS",
        "distance": 35,
        "alt": 2000,
        "active": false
      }
    ]
  },
  {
    "name": "כובשים",
    "icao": "KUVSH",
    "latlng": [
      31.25780761831416,
      34.76256952080509
    ],
    "key": "KUVSH",
    "route": [
      {
        "icao": "LLBS",
        "distance": 27,
        "alt": 0,
        "active": true
      },
      {
        "icao": "NCITY",
        "distance": 22,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "עומר",
    "icao": "OMMER",
    "latlng": [
      31.27364257955475,
      34.82867815149955
    ],
    "key": "OMMER",
    "route": [
      {
        "icao": "NCITY",
        "distance": 20,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "SOKET",
        "distance": 42,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "OHLIM",
        "distance": 55,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "GORAL",
        "distance": 45,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "סופרלנד",
    "icao": "SUPER",
    "latlng": [
      31.97541319512819,
      34.7416865633067
    ],
    "key": "SUPER",
    "route": [
      {
        "icao": "TYONA",
        "distance": 18,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "NTAIM",
        "distance": 28,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "קלור",
    "icao": "CLORE",
    "latlng": [
      32.05240810829823,
      34.73582479985193
    ],
    "key": "CLORE",
    "route": [
      {
        "icao": "TYONA",
        "distance": 29,
        "alt": 800,
        "active": true
      },
      {
        "icao": "RIDNG",
        "distance": 37,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "רידינג",
    "icao": "RIDNG",
    "latlng": [
      32.11027777793508,
      34.76250000023574
    ],
    "key": "RIDNG",
    "route": [
      {
        "icao": "HTZUK",
        "distance": 23,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "ROKCH",
        "distance": 21,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "CLORE",
        "distance": 37,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "הצוק",
    "icao": "HTZUK",
    "latlng": [
      32.14555555554981,
      34.77833333319627
    ],
    "key": "HTZUK",
    "route": [
      {
        "icao": "RIDNG",
        "distance": 23,
        "alt": 800,
        "active": true
      },
      {
        "icao": "ARENA",
        "distance": 12,
        "alt": 1600,
        "active": true
      },
      {
        "icao": "KNTRY",
        "distance": 12,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "ארנה",
    "icao": "ARENA",
    "latlng": [
      32.1647222218691,
      34.7855555553396
    ],
    "key": "ARENA",
    "route": [
      {
        "icao": "HTZUK",
        "distance": 12,
        "alt": 800,
        "active": true
      },
      {
        "icao": "APOLN",
        "distance": 17,
        "alt": 1600,
        "active": true
      }
    ]
  },
  {
    "name": "אפולוניה",
    "icao": "APOLN",
    "latlng": [
      32.19030683642918,
      34.79753825804704
    ],
    "key": "APOLN",
    "route": [
      {
        "icao": "SFAIM",
        "distance": 13,
        "alt": 1600,
        "active": true
      },
      {
        "icao": "ARENA",
        "distance": 17,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "שדות ים",
    "icao": "SDTYM",
    "latlng": [
      32.49542983817707,
      34.88474846625111
    ],
    "key": "SDTYM",
    "route": [
      {
        "icao": "OLGAH",
        "distance": 40,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "בית אורן",
    "icao": "BOREN",
    "latlng": [
      32.71444444417643,
      34.97083333378859
    ],
    "key": "BOREN",
    "route": [
      {
        "icao": "FRDIS",
        "distance": 74,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "HOTRM",
        "distance": 29,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "LLBO",
        "distance": 44,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "החותרים",
    "icao": "HOTRM",
    "latlng": [
      32.75388888924003,
      34.93694444396873
    ],
    "key": "HOTRM",
    "route": [
      {
        "icao": "DAROM",
        "distance": 26,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "BOREN",
        "distance": 29,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "צומת עפולה",
    "icao": "AFULA",
    "latlng": [
      32.60296330752671,
      35.28156806349146
    ],
    "key": "AFULA",
    "route": [
      {
        "icao": "LLMG",
        "distance": 25,
        "alt": 0,
        "active": true
      },
      {
        "icao": "TAVOR",
        "distance": 92,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "ZMGID",
        "distance": 49,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 53,
        "alt": 0,
        "active": true
      },
      {
        "icao": "EITAN",
        "distance": 175,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "צומת ערד",
    "icao": "ARRAD",
    "latlng": [
      31.25348558218001,
      35.2101405447637
    ],
    "key": "ARRAD",
    "route": [
      {
        "icao": "TARAD",
        "distance": 45,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "HATRU",
        "distance": 30,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 102,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "LLAR",
        "distance": 18,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת קטורה",
    "icao": "KTORA",
    "latlng": [
      29.98928147385089,
      35.07783803820107
    ],
    "key": "KTORA",
    "route": [
      {
        "icao": "YAHEL",
        "distance": 62,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "YOTVT",
        "distance": 53,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "צומת שוקת",
    "icao": "SOKET",
    "latlng": [
      31.30723407817105,
      34.90046873545203
    ],
    "key": "SOKET",
    "route": [
      {
        "icao": "LAHAV",
        "distance": 57,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "HOVAV",
        "distance": 114,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "MYTAR",
        "distance": 20,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "OMMER",
        "distance": 42,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "צומת אשדוד",
    "icao": "ZASHD",
    "latlng": [
      31.82541880814065,
      34.70734775594364
    ],
    "key": "ZASHD",
    "route": [
      {
        "icao": "YAVNE",
        "distance": 36,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "NMASD",
        "distance": 36,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "קאנטרי קלאב",
    "icao": "KNTRY",
    "latlng": [
      32.14108761149475,
      34.8006406294925
    ],
    "key": "KNTRY",
    "route": [
      {
        "icao": "LLHZ",
        "distance": 30,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "קיריון",
    "icao": "KRYON",
    "latlng": [
      32.84261510870171,
      35.08836744859051
    ],
    "key": "KRYON",
    "route": [
      {
        "icao": "LLHA",
        "distance": 30,
        "alt": 0,
        "active": true
      },
      {
        "icao": "AAKKO",
        "distance": 45,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "GALIM",
        "distance": 66,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "רמת חובב",
    "icao": "HOVAV",
    "latlng": [
      31.13480148432519,
      34.80531028445243
    ],
    "key": "HOVAV",
    "route": [
      {
        "icao": "NEGEV",
        "distance": 44,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "OHLIM",
        "distance": 33,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "SOKET",
        "distance": 114,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "שדה בוקר",
    "icao": "BOKER",
    "latlng": [
      30.87428717592917,
      34.78712877642224
    ],
    "key": "BOKER",
    "route": [
      {
        "icao": "TLLIM",
        "distance": 76,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "OVDAT",
        "distance": 43,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "YRUHM",
        "distance": 97,
        "alt": 4000,
        "active": true
      }
    ]
  },
  {
    "name": "שובל",
    "icao": "SOVAL",
    "latlng": [
      31.41138888888889,
      34.74638888888889
    ],
    "key": "SOVAL",
    "route": [
      {
        "icao": "BKAMA",
        "distance": 20,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "NASIH",
        "distance": 54,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "שורש",
    "icao": "SORES",
    "latlng": [
      31.79945424243948,
      35.07426375918857
    ],
    "key": "SORES",
    "route": [
      {
        "icao": "SHARG",
        "distance": 30,
        "alt": 3300,
        "active": true
      },
      {
        "icao": "HAREL",
        "distance": 35,
        "alt": 4500,
        "active": true
      },
      {
        "icao": "ESTOL",
        "distance": 55,
        "alt": 3500,
        "active": false
      }
    ]
  },
  {
    "name": "שפיים",
    "icao": "SFAIM",
    "latlng": [
      32.21011971526843,
      34.806854886314
    ],
    "key": "SFAIM",
    "route": [
      {
        "icao": "APOLN",
        "distance": 13,
        "alt": 800,
        "active": true
      },
      {
        "icao": "VINGT",
        "distance": 27,
        "alt": 1600,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 106,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "תל יונה",
    "icao": "TYONA",
    "latlng": [
      32.00472222222222,
      34.72722222222222
    ],
    "key": "TYONA",
    "route": [
      {
        "icao": "SUPER",
        "distance": 18,
        "alt": 800,
        "active": true
      },
      {
        "icao": "CLORE",
        "distance": 29,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "LLRS",
        "distance": 26,
        "alt": 0,
        "active": true
      },
      {
        "icao": "NMASD",
        "distance": 115,
        "alt": 800,
        "active": false
      }
    ]
  },
  {
    "name": "מיתר",
    "icao": "MYTAR",
    "latlng": [
      31.31472222222222,
      34.93805555555555
    ],
    "key": "MYTAR",
    "route": [
      {
        "icao": "SOKET",
        "distance": 20,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "TARAD",
        "distance": 98,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "MZDOT",
        "distance": 91,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "מצודות",
    "icao": "MZDOT",
    "latlng": [
      31.35799373816631,
      35.10835956492298
    ],
    "key": "MZDOT",
    "route": [
      {
        "icao": "MYTAR",
        "distance": 91,
        "alt": 4000,
        "active": false
      },
      {
        "icao": "ENGDI",
        "distance": 161,
        "alt": 4000,
        "active": false
      }
    ]
  },
  {
    "name": "נטעים",
    "icao": "NTAIM",
    "latlng": [
      31.94361111111111,
      34.78083333333333
    ],
    "key": "NTAIM",
    "route": [
      {
        "icao": "SUPER",
        "distance": 28,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "BOVED",
        "distance": 18,
        "alt": 800,
        "active": true
      },
      {
        "icao": "SIRNI",
        "distance": 27,
        "alt": 800,
        "active": true
      },
      {
        "icao": "MRISN",
        "distance": 20,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "ניצן",
    "icao": "NITZA",
    "latlng": [
      31.72490274260117,
      34.57083338777277
    ],
    "key": "NITZA",
    "route": [
      {
        "icao": "ZDAFA",
        "distance": 37,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "HODYA",
        "distance": 48,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "ASKLN",
        "distance": 35,
        "alt": 800,
        "active": false
      }
    ]
  },
  {
    "name": "נמל אשדוד",
    "icao": "NMASD",
    "latlng": [
      31.83140936546503,
      34.62370047868576
    ],
    "key": "NMASD",
    "route": [
      {
        "icao": "ZDAFA",
        "distance": 34,
        "alt": 800,
        "active": true
      },
      {
        "icao": "ZASHD",
        "distance": 36,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "TYONA",
        "distance": 115,
        "alt": 1200,
        "active": false
      }
    ]
  },
  {
    "name": "נעם",
    "icao": "NOAAM",
    "latlng": [
      31.5718316247127,
      34.77456755519553
    ],
    "key": "NOAAM",
    "route": [
      {
        "icao": "GOVRN",
        "distance": 63,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 77,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "REVAH",
        "distance": 49,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף נשרים",
    "icao": "NSHRM",
    "latlng": [
      31.90916666666666,
      34.89166666666667
    ],
    "key": "NSHRM",
    "route": [
      {
        "icao": "SIRNI",
        "distance": 32,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "AYLON",
        "distance": 34,
        "alt": 800,
        "active": true
      },
      {
        "icao": "LLNN",
        "distance": 16,
        "alt": 0,
        "active": true
      },
      {
        "icao": "LLBG",
        "distance": 60,
        "alt": 1200,
        "active": false
      },
      {
        "icao": "NCSHN",
        "distance": 57,
        "alt": 1600,
        "active": false
      }
    ]
  },
  {
    "name": "סמר",
    "icao": "SAMAR",
    "latlng": [
      29.83094762265632,
      35.02863837544101
    ],
    "key": "SAMAR",
    "route": [
      {
        "icao": "YOTVT",
        "distance": 48,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "BRORA",
        "distance": 75,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "LLER",
        "distance": 62,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "עינות צוקים",
    "icao": "ZUKIM",
    "latlng": [
      31.71511399200654,
      35.44884607718283
    ],
    "key": "ZUKIM",
    "route": [
      {
        "icao": "ALMOG",
        "distance": 97,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "SHALM",
        "distance": 97,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "מעלה מכמש",
    "icao": "MIHMS",
    "latlng": [
      31.85450467272668,
      35.329682600024
    ],
    "key": "MIHMS",
    "route": [
      {
        "icao": "YRIHO",
        "distance": 36,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "SHAHR",
        "distance": 63,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ANATA",
        "distance": 36,
        "alt": 5000,
        "active": true
      },
      {
        "icao": "DUMIM",
        "distance": 31,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "מצפה יריחו",
    "icao": "YRIHO",
    "latlng": [
      31.81963606183846,
      35.38783172520396
    ],
    "key": "YRIHO",
    "route": [
      {
        "icao": "ALMOG",
        "distance": 39,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "MIHMS",
        "distance": 36,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "DUMIM",
        "distance": 33,
        "alt": 4000,
        "active": true
      }
    ]
  },
  {
    "name": "ענאתא",
    "icao": "ANATA",
    "latlng": [
      31.82123175483554,
      35.27162371648757
    ],
    "key": "ANATA",
    "route": [
      {
        "icao": "DUMIM",
        "distance": 31,
        "alt": 4500,
        "active": true
      },
      {
        "icao": "MIHMS",
        "distance": 36,
        "alt": 4500,
        "active": true
      },
      {
        "icao": "HNINA",
        "distance": 31,
        "alt": 5000,
        "active": true
      }
    ]
  },
  {
    "name": "עכו",
    "icao": "AAKKO",
    "latlng": [
      32.91861111111111,
      35.09638888888889
    ],
    "key": "AAKKO",
    "route": [
      {
        "icao": "KRYON",
        "distance": 45,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "SMRAT",
        "distance": 20,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "LLSO",
        "distance": 20,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "פרדס",
    "icao": "PARDS",
    "latlng": [
      32.10277777777778,
      34.84777777777778
    ],
    "key": "PARDS",
    "route": [
      {
        "icao": "ROKCH",
        "distance": 22,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "GNYAM",
        "distance": 37,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "LLBG",
        "distance": 59,
        "alt": 1200,
        "active": true
      }
    ]
  },
  {
    "name": "צומת הרוחות",
    "icao": "RUHOT",
    "latlng": [
      30.66,
      34.80888888888889
    ],
    "key": "RUHOT",
    "route": [
      {
        "icao": "HRGVS",
        "distance": 76,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "OVDAT",
        "distance": 85,
        "alt": 4500,
        "active": true
      },
      {
        "icao": "LLMR",
        "distance": 5,
        "alt": 0,
        "active": true
      },
      {
        "icao": "MESAR",
        "distance": 118,
        "alt": 3500,
        "active": false
      }
    ]
  },
  {
    "name": "צומת חתרורים",
    "icao": "HATRU",
    "latlng": [
      31.21807058555043,
      35.24912636738798
    ],
    "key": "HATRU",
    "route": [
      {
        "icao": "ARRAD",
        "distance": 30,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "ZOHAR",
        "distance": 75,
        "alt": 3500,
        "active": true
      }
    ]
  },
  {
    "name": "צומת מגידו",
    "icao": "ZMGID",
    "latlng": [
      32.57365479421811,
      35.19012790206304
    ],
    "key": "ZMGID",
    "route": [
      {
        "icao": "EIRON",
        "distance": 100,
        "alt": 3500,
        "active": true
      },
      {
        "icao": "AFULA",
        "distance": 49,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "LLMG",
        "distance": 27,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "וינגייט",
    "icao": "VINGT",
    "latlng": [
      32.25715970946211,
      34.81709603597721
    ],
    "key": "VINGT",
    "route": [
      {
        "icao": "PELEG",
        "distance": 46,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "SFAIM",
        "distance": 27,
        "alt": 800,
        "active": true
      }
    ]
  },
  {
    "name": "חולתה",
    "icao": "HULAT",
    "latlng": [
      33.04286348539944,
      35.62948996657867
    ],
    "key": "HULAT",
    "route": [
      {
        "icao": "LLIB",
        "distance": 47,
        "alt": 0,
        "active": true
      },
      {
        "icao": "BASAN",
        "distance": 61,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "AMNON",
        "distance": 88,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "חורבות עבדת",
    "icao": "OVDAT",
    "latlng": [
      30.80277777777778,
      34.79333333333333
    ],
    "key": "OVDAT",
    "route": [
      {
        "icao": "BOKER",
        "distance": 43,
        "alt": 4000,
        "active": true
      },
      {
        "icao": "RUHOT",
        "distance": 85,
        "alt": 4000,
        "active": true
      }
    ]
  },
  {
    "name": "כרי דשא",
    "icao": "DESHE",
    "latlng": [
      32.86977585568882,
      35.53147468856729
    ],
    "key": "DESHE",
    "route": [
      {
        "icao": "AMNON",
        "distance": 28,
        "alt": 2500,
        "active": true
      },
      {
        "icao": "ZALMN",
        "distance": 61,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "TAVOR",
        "distance": 121,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "ALUMT",
        "distance": 93,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "להבות הבשן",
    "icao": "BASAN",
    "latlng": [
      33.14568857502277,
      35.63779086325378
    ],
    "key": "BASAN",
    "route": [
      {
        "icao": "HULAT",
        "distance": 61,
        "alt": 3000,
        "active": true
      },
      {
        "icao": "LLKS",
        "distance": 44,
        "alt": 2500,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף דרום",
    "icao": "DAROM",
    "latlng": [
      32.79584048689519,
      34.94142995591113
    ],
    "key": "DAROM",
    "route": [
      {
        "icao": "GALIM",
        "distance": 41,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "HOTRM",
        "distance": 26,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "אילון",
    "icao": "AYLON",
    "latlng": [
      31.87103998289209,
      34.94279606667346
    ],
    "key": "AYLON",
    "route": [
      {
        "icao": "LTRUN",
        "distance": 29,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "NSHRM",
        "distance": 34,
        "alt": 1200,
        "active": true
      },
      {
        "icao": "LLBG",
        "distance": 87,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "אפק",
    "icao": "AFFEK",
    "latlng": [
      32.83987260188155,
      35.12718347624958
    ],
    "key": "AFFEK",
    "route": [
      {
        "icao": "LLHA",
        "distance": 46,
        "alt": 2000,
        "active": true
      }
    ]
  },
  {
    "name": "בני דרור",
    "icao": "DEROR",
    "latlng": [
      32.25811349283185,
      34.89240499533228
    ],
    "key": "DEROR",
    "route": [
      {
        "icao": "BAZRA",
        "distance": 24,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "SHARO",
        "distance": 39,
        "alt": 1500,
        "active": true
      }
    ]
  },
  {
    "name": "בצרה",
    "icao": "BAZRA",
    "latlng": [
      32.22011925918252,
      34.88342612920066
    ],
    "key": "BAZRA",
    "route": [
      {
        "icao": "DEROR",
        "distance": 24,
        "alt": 800,
        "active": true
      },
      {
        "icao": "LLHZ",
        "distance": 33,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בת גלים",
    "icao": "GALIM",
    "latlng": [
      32.84111111111111,
      34.98111111111111
    ],
    "key": "GALIM",
    "route": [
      {
        "icao": "DAROM",
        "distance": 41,
        "alt": 2000,
        "active": true
      },
      {
        "icao": "LLHA",
        "distance": 36,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "GILAM",
        "distance": 82,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "KRYON",
        "distance": 66,
        "alt": 1500,
        "active": true
      }
    ]
  },
  {
    "name": "גילעם",
    "icao": "GILAM",
    "latlng": [
      32.82016157986524,
      35.13040904836473
    ],
    "key": "GILAM",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 8,
        "alt": 1500,
        "active": true
      },
      {
        "icao": "LLHA",
        "distance": 46,
        "alt": 0,
        "active": true
      },
      {
        "icao": "GALIM",
        "distance": 82,
        "alt": 3000,
        "active": true
      }
    ]
  },
  {
    "name": "גני עם",
    "icao": "GNYAM",
    "latlng": [
      32.15027777777777,
      34.89416666666666
    ],
    "key": "GNYAM",
    "route": [
      {
        "icao": "PARDS",
        "distance": 37,
        "alt": 12000,
        "active": true
      },
      {
        "icao": "LLHZ",
        "distance": 36,
        "alt": 0,
        "active": true
      }
    ]
  },
  {
    "name": "דימונה",
    "icao": "DIMON",
    "latlng": [
      31.067190268295,
      35.00711691866177
    ],
    "key": "DIMON",
    "route": [
      {
        "icao": "YRUHM",
        "distance": 71,
        "alt": 3500,
        "active": true
      }
    ]
  }
]