import {useState, useEffect} from "react";
import {Dialog, DialogActions, DialogContent,IconButton, DialogContentText} from '@mui/material';
import CancelIcon from '@mui/icons-material/TaskAlt';


export function NotamNotification (props) {

  const { notamNotificationOpen , setNotamNotificationOpen, description, originalNotam} = props
  const [notamFields , setNotamFields] = useState("")

/***********************************************/

  const buttonOkClicked = ()=>{ 
      setNotamNotificationOpen(false)
  }

  /***********************************************/

  useEffect(() => {

    if(notamNotificationOpen && originalNotam){

      var fields = "\n"

      // if(originalNotam.abbrContent) {fields += "Content: "    + originalNotam.abbrContent + '\n'}
      // if(originalNotam.date)        {fields += "Date: "       + originalNotam.date + '\n'}
      // if(originalNotam.lowerAlt)    {fields += "Lower Alt: "  + originalNotam.lowerAlt + '\n'}
      // if(originalNotam.upperAlt)    {fields += "upper Alt: "  + originalNotam.upperAlt + '\n'}
      // if(originalNotam.startTime)   {fields += "Start Time: " + originalNotam.startTime + '\n'}
      // if(originalNotam.endTime)     {fields += "End Time: "   + originalNotam.endTime + '\n'}
      // if(originalNotam.schedule)    {fields += "Schedule: "   + originalNotam.schedule + '\n'}
      // if(originalNotam.timeReason)  {fields += "Time Reason: "+ originalNotam.timeReason + '\n'}

      fields += JSON.stringify(originalNotam,null,2);

      setNotamFields(fields)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notamNotificationOpen]);


/***********************************************/
    
    return(
    
      <Dialog 
        dir="rtl"
        open={notamNotificationOpen} 
        onClose={buttonOkClicked} 
        >

        <DialogContent>
            <DialogContentText > 
              {description} 
            </DialogContentText>
            <DialogContentText dir="ltr" style={{ whiteSpace: 'pre-wrap' }}> 
              {notamFields && notamFields}
            </DialogContentText>
        </DialogContent>

        <DialogActions>
            <IconButton 
                aria-label="ok" 
                color="primary"
                onClick={buttonOkClicked}
                ><CancelIcon />
            </IconButton>
        </DialogActions>

      </Dialog>
    )    
}

export default NotamNotification;
