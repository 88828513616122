import {isInTimeCategory, filterNavPointsByRules,} from "../utils";
import {LoadReportPoints, LoadAirstrips, LoadBubblesPoints, isAirport, dbPointToRoutePoint,} from ".";
import { cvfrReplacePatterns} from "../data";
import gju from "geojson-utils";
import dijkstra from "./dijkstra"

export function CalcBestRoute(mapUsed,timeCategory, planedRoute, setPlanedRoute, rules, openAirways,  setAlertDialogOpen, setAlertDialogMessage,onlyLastPoints){

    if(planedRoute.length < 2){
        setPlanedRoute(planedRoute)
        return
    }
    
    var newRoute = []

    const reportPoints = LoadReportPoints(mapUsed,timeCategory,openAirways)
    const airstrips    = LoadAirstrips(mapUsed,rules, openAirways);
    const bubblePoints = LoadBubblesPoints(mapUsed,timeCategory, rules, openAirways);

    var navPoints = [...reportPoints,...bubblePoints]

    navPoints = filterNavPointsByRules(navPoints,rules,mapUsed,timeCategory)

    const findNavPoint = (icao) => {return navPoints.find(point => point.icao === icao)}

    // Prepare the report points graph
    var navPointsGraph = {}
    navPoints.forEach(point => {
        const edges = findAirwayEdges(point, timeCategory,mapUsed)
        if(edges){
            navPointsGraph[point.icao] = edges
        }
    });

    newRoute.push(planedRoute[0])

    const len = planedRoute.length;

    if(onlyLastPoints){

        if(len > 1){
            let startPoint = planedRoute[len -2]
            let endPoint   = planedRoute[len - 1]

            let newSegment = findBestRoute(startPoint,endPoint)

            if(newSegment){
                newRoute = [...planedRoute.slice(0, len - 1), ...newSegment, planedRoute[len -1]]
            } else {
                newRoute = [...planedRoute.slice(0, len - 1)]
            }
        } else {
            newRoute = [...planedRoute]
        }
    } else {

        for (let i = 0; i < planedRoute.length - 1; i++) {

            let startPoint = planedRoute[i]
            let endPoint = planedRoute[i+1]

            let newSegment = findBestRoute(startPoint,endPoint)

            if(newSegment){
                newRoute = [...newRoute,...newSegment,planedRoute[i+ 1]]
            } else {
                i = i + 2;
                while (i < planedRoute.length - 1){
                    let endPoint = planedRoute[i]
                    newSegment = findBestRoute(startPoint,endPoint)
                    i++;
                    if(newSegment){
                        newRoute = [...newRoute,...newSegment,planedRoute[i]]
                        break;
                    }
                }
            }
        }
    }

    setPlanedRoute(newRoute)
    
    function findBestRoute(startPoint,endPoint){

        const icaoStart = startPoint.icao
        const icaoEnd   = endPoint.icao

        var newSegment = []

        var routeGraph = {...navPointsGraph}

        // add to the graph in case of airport
        var startEdges = findAirstripEdges(icaoStart)
        if(startEdges){
            routeGraph[icaoStart] = startEdges;
        }

        // add to the graph in case of airport
        var endEdges = findAirstripEdges(icaoEnd)
        if(endEdges){
            routeGraph[icaoEnd] = endEdges;
        }

        if((icaoStart !== icaoEnd) && (startEdges || findNavPoint(icaoStart)) && (endEdges || findNavPoint(icaoEnd))){

            // Check if start point or end point are bubbles. in that case we have to update the location to the point location
            const startBubble = bubblePoints.find((p) => p.icao === startPoint.icao) ;
            const endBubble = bubblePoints.find((p) => p.icao === endPoint.icao);

            // adjust the route points distance of the start point in case it is bubble
            if(startBubble && startBubble.route){

                // If the starting point is a bubble, update its distances out
                startBubble.route.forEach(routePoint => {
                    var destLat = 0;
                    var destLng = 0;
                    const destIcao = routePoint.icao

                    if(destIcao === endPoint.icao){
                        destLat = endPoint.latlng.lat 
                        destLng = endPoint.latlng.lng 
                    } else {
                        var navP = navPoints.find((g) => g.icao === destIcao)
                        if(navP){
                            destLat = navP.latlng[0]
                            destLng = navP.latlng[1]
                        }
                    }

                    if(destLat > 0 && destLng > 0)
                    {
                        var newDistance =  Math.floor(gju.pointDistance(
                            {type: 'Point',coordinates:[destLng,destLat]},
                            {type: 'Point',coordinates:[startPoint.latlng.lng, startPoint.latlng.lat]}) / 185);

                        if(routeGraph[destIcao]){
                            routeGraph[startPoint.icao][destIcao] = newDistance;
                        }
                    }
                })
            }

            // adjust the route points of the graph points who point to the end point in case of bubble
            if(endBubble){
                for(let routeIcao in routeGraph ){

                    const routePoint = routeGraph[routeIcao];

                    if(routeIcao !== startPoint.icao && routePoint[endBubble.icao]){

                        var navP = navPoints.find((g) => g.icao === routeIcao)
                        if(navP){
                            var newDistance =  Math.floor(gju.pointDistance(
                                {type: 'Point',coordinates:[navP.latlng[1], navP.latlng[0]]},
                                {type: 'Point',coordinates:[endPoint.latlng.lng, endPoint.latlng.lat]}) / 185);

                                routeGraph[routeIcao][endBubble.icao] = newDistance;
                        }
                    }
                }
            }

            var path = dijkstra.find_path(routeGraph, icaoStart, icaoEnd);

            if(path.length === 1 ){

                const msg =`לא נמצא נתיב פתוח בין ${startPoint.name} ל${endPoint.name}`

                if(setAlertDialogOpen){
                    setAlertDialogMessage(msg)
                    setAlertDialogOpen(true);
                } 
                // else {
                //     alert(msg)
                // }
            
                newSegment = null
            } else {

                if(path.length > 2){

                    const newPath = replacePatterns(path, mapUsed)

                    for (let j = 1; j < newPath.length -1; j++) {

                        const icao =  newPath[j]
                        const rPoint = findNavPoint(icao)

                        if(rPoint){
                            newSegment.push(dbPointToRoutePoint(rPoint))
                        }
                    }
                }
            }
        }

        return newSegment;

    }


    function findAirstripEdges(icao){

        if(!isAirport(icao))
            return null

        const airstrip = airstrips.find(point => point.icao === icao)
            
        if(airstrip){
            return findAirwayEdges(airstrip, timeCategory,mapUsed)
        } else {
            return null
        }
    }

}

/***********************************************/

const findAirwayEdges = (airway,timeCategory,mapUsed) => {

    if(airway.route.length){
        var edges = {}
        var IsEmpty = true
        airway.route.forEach(routePoint => {

            if(routePoint.active && (mapUsed === 'cvfr' || isInTimeCategory(timeCategory,routePoint.time))){
                edges[routePoint.icao] = routePoint.distance
                IsEmpty = false;
            }
        })
        
        return IsEmpty? null: edges;
    } else{
        return null
    }
}

/***********************************************/

function matchPattern(pattern ,pointsArray, index)
{
    var isPattern = true
    for (let i = 0; i < pattern.length; i++) {
        if(pointsArray[index + i] !== pattern[i])
        {
            isPattern = false;
            break;    
        }
    }
    return isPattern;
}

/***********************************************/

// replace patterns defined in cvfrReplacePatterns
const replacePatterns = (pointsArray, mapUsed) => {

    if(mapUsed === 'cvfr'){

        cvfrReplacePatterns.forEach(pattern => {

            for (let i = 0; i < pointsArray.length - pattern[0].length + 1; i++) {
                if(matchPattern(pattern[0] ,pointsArray, i)){
                    var newPointsArray = [...pointsArray.slice(0,i), ...pattern[1], ...pointsArray.slice(i + pattern[0].length)]
                    pointsArray = [...newPointsArray]
                    break;
                } 
            }
        })
    }   
    return pointsArray;
}

/***********************************************/
