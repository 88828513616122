
import * as React from "react";

export function PilotIcon (props) {
return (

  <svg
    id="Layer_1"
    height="64px"
    width="64px"
    style={{
      enableBackground: "new 0 0 135 135",
    }}
    viewBox="0 0 135 135"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:#5E4F7A;}\n\t.st1{fill:#FFFFFF;}\n\t.st2{fill:#EAEAFA;}\n\t.st3{fill:#FFBF96;}\n\t.st4{fill:#FFA76E;}\n\t.st5{fill:#685D89;}\n\t.st6{fill:#923A3D;}\n\t.st7{opacity:0.5;}\n\t.st8{fill:#691D2B;}\n\t.st9{fill:#73382A;}\n\t.st10{fill:#FFB281;}\n\t.st11{fill:#D62440;}\n\t.st12{fill:#003344;}\n\t.st13{fill:#DADAF5;}\n\t.st14{fill:#4A4F72;}\n\t.st15{fill:#4B2945;}\n\t.st16{fill:#C8C8EE;}\n\t.st17{fill:#0D4750;}\n\t.st18{fill:#40E398;}\n\t.st19{fill:#36D685;}\n\t.st20{fill:#0A3940;}\n\t.st21{fill:#41B5F7;}\n\t.st22{fill:#36A2F5;}\n\t.st23{fill:#873137;}\n\t.st24{fill:#EB3D62;}\n\t.st25{fill:#FFD56F;}\n\t.st26{fill:#F4C762;}\n\t.st27{fill:#E5B451;}\n\t.st28{fill:#303856;}\n\t.st29{fill:#863B56;}\n\t.st30{fill:#9A4A6C;}\n\t.st31{fill:#3A4060;}\n\t.st32{fill:#105560;}\n\t.st33{fill:#C97742;}\n\t.st34{fill:#45C6FF;}\n\t.st35{fill:#BBCAEA;}\n\t.st36{fill:#4884D0;}\n\t.st37{fill:#8A4634;}\n\t.st38{fill:#B96833;}\n\t.st39{fill:#FE4C40;}\n\t.st40{fill:#EA241F;}\n\t.st41{fill:#F94877;}\n\t.st42{fill:#FF9558;}\n\t.st43{enable-background:new    ;}\n\t.st44{fill:#3A6BC7;}\n\t.st45{fill:#2B63BC;}\n\t.st46{fill:#2BC16F;}\n\t.st47{fill:#2A324C;}\n\t.st48{fill:#34C174;}\n\t.st49{fill:#2BB265;}\n\t.st50{opacity:0.4;}\n\t.st51{opacity:0.3;}\n\t.st52{fill:#FF4A7A;}\n\t.st53{fill:none;}\n\t.st54{fill:#F4844D;}\n\t.st55{fill:#29C16E;}\n"
      }
    </style>
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              className="st2"
                              d="M110.7324142,111.8261337C100.5548172,123.7889099,85.024353,131.5,67.5103836,131.5              c-17.165947,0-32.8239098-7.4438248-43.2436829-19.6849899              c-1.3455906-1.5842972-1.7254372-3.765213-1.0200539-5.7188263              c1.0309162-2.9078979,2.4090271-5.9138184,3.7872047-6.6630478              c1.5843601-0.8461914,11.8390903-3.5590973,21.8334599-7.378891              c0.0651054-0.0217209,0.1410751-0.0540466,0.2061806-0.0757675              c5.4719772-2.0780792,14.7479935-6.0678635,18.4152412-10.6131821              c7.737442,9.4049683,37.2852173,16.3741913,40.476532,18.0678406              c1.3781128,0.7492294,2.7562866,3.7980728,3.7980652,6.7282181              C112.4577866,108.0932388,112.0671463,110.2524414,110.7324142,111.8261337z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <path
                          className="st11"
                          d="M67.8603973,94.998909v0.0914459c-0.0101929-0.0203781-0.0203781-0.0308151-0.0305023-0.0407562            C67.8502045,95.0187912,67.8603973,94.998909,67.8603973,94.998909z"
                        />
                      </g>
                      <g>
                        <path
                          className="st28"
                          d="M73.8555603,130.3285217c-0.6208801,0.5089264-5.3539124,0.1729431-5.995163,0.1222534            c-0.0101929,0.0099487-0.0101929,0-0.0203781,0c-0.3766022,0.0303192-5.3946037,0.4070435-6.0459824-0.1222534            c-0.6718178-0.5496826,3.9187012-21.1308746,4.1629791-23.5124893            c0.244278-2.3920517-2.4529953-3.5525436-2.8805389-4.2851181            c-0.3969154-0.6923218,4.1019745-6.6468506,4.7126617-7.4405594            c0.0101852-0.0203781,0.0203781-0.0308151,0.0407562-0.0407562            c0.0101242,0.0099411,0.0203094,0.0203781,0.0305023,0.0407562            c0.6107483,0.7937088,5.1096344,6.7482376,4.7126541,7.4405594            c-0.427475,0.7325745-3.1248093,1.8930664-2.8805389,4.2851181            C69.9368515,109.1976471,74.5273132,129.7788391,73.8555603,130.3285217z"
                        />
                      </g>
                      <g>
                        <path
                          className="st11"
                          d="M67.829895,95.0495987c-0.0203781,0.0099411-0.030571,0.0203781-0.0407562,0.0407562V94.998909            C67.7891388,94.998909,67.799324,95.0187912,67.829895,95.0495987z"
                        />
                      </g>
                      <g>
                        <g>
                          <rect
                            className="st3"
                            height={20.7976837}
                            width={20.0797558}
                            x={57.6546326}
                            y={74.282341}
                          />
                        </g>
                        <g>
                          <path
                            className="st4"
                            d="M77.7343903,74.282341v9.8156204c-1.0333862,0.7583313-2.1276169,1.4189072-3.3029709,1.9826736             c-4.0726929,1.9627457-9.1582413,2.3907852-13.0689201,0.1746368             c-1.4790916-0.8551483-2.6947632-2.0016632-3.7078667-3.303833V74.282341H77.7343903z"
                          />
                        </g>
                        <g>
                          <g>
                            <g>
                              <path
                                className="st16"
                                d="M57.7145576,81.1081848c0,0-12.4848442,8.3727798,1.4844971,22.078598               c0.5734596,0.5626373,1.5430832,0.5072556,2.062252-0.1019745l6.1130142-7.1734619               c0.5353928-0.6282654,0.3743744-1.5598526-0.3495178-1.9796906               C64.1671524,92.2742691,57.9184418,87.8859024,57.7145576,81.1081848z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          className="st10"
                          d="M91.0313721,59.8765488c2.7042313-0.0940895,5.3279572-1.6076698,6.7294998-3.9222717            c1.4015427-2.3146057,1.5210114-5.3628006,0.2700424-7.7621346            c-0.6111679-1.1722069-1.5431519-2.1669769-2.6495056-2.8863602            c-0.5580902-0.3628845-1.1614532-0.6566658-1.7926483-0.8685455            c-0.4592743-0.1541672-1.3382034-0.5102348-1.8081207-0.2722931            c-0.5693893,0.288311-0.3310165,1.2502708-0.3083344,1.7428741            c0.0406189,0.8821335,0.0682678,1.7648659,0.0829391,2.6478157            c0.0296021,1.7813911,0.0063934,3.5636559-0.0695114,5.3436737            C91.4005737,55.8960228,91.2490997,57.8899117,91.0313721,59.8765488z"
                        />
                      </g>
                      <g>
                        <path
                          className="st3"
                          d="M98.2141571,55.1024361c0.4087677-2.5885849-0.5257034-5.3730049-2.4726105-7.1250305            c-0.9734573-0.8955002-2.1805267-1.5181694-3.4655533-1.8109627            c-0.2723541-0.057991-0.5257034-0.0969658-0.7980576-0.1169319v-0.1359406            c-0.0194931-0.5057373-0.2728348-1.4601784,0.3113327-1.7520256            c0.4672394-0.2338562,1.3432541,0.1169281,1.7912369,0.2728348c0.642395,0.2138939,1.246048,0.5057373,1.81073,0.8755379            c1.0901413,0.7205811,2.0246201,1.713047,2.647522,2.881382            C99.1291428,50.2941093,99.1681213,52.9226227,98.2141571,55.1024361z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          className="st10"
                          d="M44.3927078,59.8765488c-2.7042313-0.0940895-5.3279572-1.6076698-6.7294998-3.9222717            c-1.4015465-2.3146057-1.5210152-5.3628006-0.2700424-7.7621346            c0.6111679-1.1722069,1.543148-2.1669769,2.6495056-2.8863602            c0.558094-0.3628845,1.1614532-0.6566658,1.7926521-0.8685455            c0.4592667-0.1541672,1.3381996-0.5102348,1.8081169-0.2722931            c0.5693932,0.288311,0.3310165,1.2502708,0.3083344,1.7428741            c-0.0406189,0.8821335-0.0682678,1.7648659-0.0829391,2.6478157            c-0.0296021,1.7813911-0.0063972,3.5636559,0.0695152,5.3436737            C44.0235023,55.8960228,44.174984,57.8899117,44.3927078,59.8765488z"
                        />
                      </g>
                      <g>
                        <path
                          className="st3"
                          d="M37.2099266,55.1024361c-0.4087753-2.5885849,0.5257034-5.3730049,2.4726105-7.1250305            c0.9734497-0.8955002,2.1805229-1.5181694,3.4655495-1.8109627            c0.2723579-0.057991,0.5257034-0.0969658,0.7980614-0.1169319v-0.1359406            c0.0194855-0.5057373,0.272831-1.4601784-0.3113365-1.7520256            c-0.4672394-0.2338562-1.3432503,0.1169281-1.7912369,0.2728348            c-0.642395,0.2138939-1.2460518,0.5057373-1.81073,0.8755379c-1.0901413,0.7205811-2.0246201,1.713047-2.6475258,2.881382            C36.2949371,50.2941093,36.2559624,52.9226227,37.2099266,55.1024361z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          className="st3"
                          d="M92.2710876,57.3175964c-0.7841415,5.3412056-3.0586853,9.8863373-4.392067,12.2114792            c-0.5489273,0.9695969-1.2548752,1.9171143-2.065506,2.7783966            c-3.1110153,3.338913-6.3267136,5.836525-10.4838943,7.8609009            c-1.725296,0.8623352-3.6599426,1.3576508-5.6207733,1.5511551            c-0.2093582,0.0431137-0.3922272,0.0431137-0.601265,0.0641479l-0.8367996,0.0431137l-0.3137131,0.0220871            l-1.1243515-0.0652008c-2.1957092-0.150383-4.3397217-0.6667328-6.2220383-1.615303            c-0.3402061-0.1724625-0.6797752-0.3438797-1.0199852-0.5163422            c-3.6861153-1.9381485-6.6404381-4.2864304-9.490078-7.3445587            c-0.810627-0.8612823-1.490406-1.8087997-2.0393333-2.7783966            c-1.3595543-2.3251419-3.6079254-6.8702736-4.4182358-12.2114792            c-0.4969063-3.3599472-0.2878685-9.3037376,0.1043625-14.9898796            c0.3137169-4.7375755,0.7844543-9.2826996,1.0720062-11.9958954            c0.1831856-1.594265,0.2875481-2.5638657,0.2875481-2.5638657h45.7263794c0,0,0.4969025,4.2001953,0.9934921,9.563488            C92.4019318,44.1796303,92.9246902,52.9459877,92.2710876,57.3175964z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            className="st9"
                            d="M50.9073753,47.626194c-0.234375,0-0.46875-0.0812988-0.6582031-0.2473145             c-0.4160156-0.3637695-0.4580078-0.9956055-0.0947266-1.4111328             c1.5537109-1.7753906,3.7910156-2.7895508,6.1474609-2.7895508c0.0224609,0,0.0458984,0,0.0693359,0.0002441             l2.796875,0.0236816c0.5517578,0.0046387,0.9960938,0.4562988,0.9912109,1.0085449             c-0.0048828,0.5493164-0.4511719,0.9914551-1,0.9914551c-0.0029297,0-0.0058594,0-0.0087891,0l-2.796875-0.0236816             c-0.0166016-0.0002441-0.0341797-0.0002441-0.0517578-0.0002441c-1.7792969,0-3.46875,0.7658691-4.6416016,2.1064453             C51.4620628,47.5107155,51.1856956,47.626194,50.9073753,47.626194z"
                          />
                        </g>
                        <g>
                          <path
                            className="st9"
                            d="M60.0929222,53.9164772c-0.5527344,0-1-0.4477539-1-1             c0-1.1499023-0.9355469-2.0852051-2.0849609-2.0852051c-1.1503906,0-2.0859375,0.9353027-2.0859375,2.0852051             c0,0.5522461-0.4472656,1-1,1s-1-0.4477539-1-1c0-2.2526855,1.8329926-4.0852051,4.0859375-4.0852051             c2.2529297,0,4.0849609,1.8325195,4.0849609,4.0852051             C61.0929222,53.4687233,60.6456566,53.9164772,60.0929222,53.9164772z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            className="st9"
                            d="M85.0186996,47.626194c-0.2783203,0-0.5546875-0.1154785-0.7529297-0.3415527             c-1.1728516-1.3405762-2.8623047-2.1064453-4.6416016-2.1064453c-0.0175781,0-0.0351563,0-0.0517578,0.0002441             l-2.796875,0.0236816c-0.0029297,0-0.0058594,0-0.0087891,0c-0.5488281,0-0.9951172-0.4421387-1-0.9914551             c-0.0048828-0.5522461,0.4394531-1.0039063,0.9912109-1.0085449l2.796875-0.0236816             c0.0234375-0.0002441,0.046875-0.0002441,0.0693359-0.0002441c2.3564453,0,4.59375,1.0141602,6.1474609,2.7895508             c0.3632813,0.4155273,0.3212891,1.0473633-0.0947266,1.4111328             C85.4874496,47.544651,85.2530746,47.626194,85.0186996,47.626194z"
                          />
                        </g>
                        <g>
                          <path
                            className="st9"
                            d="M82.0040512,53.9164772c-0.5527344,0-1-0.4477539-1-1             c0-1.1499023-0.9355469-2.0852051-2.0859375-2.0852051c-1.1494141,0-2.0849609,0.9353027-2.0849609,2.0852051             c0,0.5522461-0.4472656,1-1,1s-1-0.4477539-1-1c0-2.2526855,1.8320313-4.0852051,4.0849609-4.0852051             s4.0859375,1.8325195,4.0859375,4.0852051C83.0040512,53.4687233,82.5567856,53.9164772,82.0040512,53.9164772z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            className="st10"
                            d="M83.1211472,60.9418182c2.4008408,2.6295815,1.309082,5.2737808,0.3946381,4.9235153             c-0.9902954-0.2270126-1.7667007-1.6599197-3.2968674-2.8457603             c-1.4454346-1.3036499-3.7041779-1.7600403-3.5359039-2.7430191             C76.7319107,59.1900177,80.6507187,58.3074455,83.1211472,60.9418182z"
                          />
                        </g>
                        <g>
                          <path
                            className="st10"
                            d="M52.804863,60.9418182c-2.4008408,2.6295815-1.3090782,5.2737808-0.3946381,4.9235153             c0.9902954-0.2270126,1.7667046-1.6599197,3.2968712-2.8457603             c1.4454308-1.3036499,3.7041779-1.7600403,3.5359001-2.7430191             C59.1940994,59.1900177,55.2752914,58.3074455,52.804863,60.9418182z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <path
                  className="st9"
                  d="M93.6806793,45.8811607c-0.3252792,0.7761002-0.6756058,1.5721817-1.0506744,2.3913994        c-0.2504501,0.5384331-0.5005951,1.0768661-0.8008347,1.6363297c-0.100174,0.2155838-0.2254028,0.452198-0.3503265,0.6677818        c0,0-1.8014145-20.0082359-4.4534531-21.3648319l-0.0250473,0.0210323        c-0.050087,0.0220833-0.1252213,0.0431156-0.2254028,0.1083164l-0.5253372,0.2366161        c-3.1275864,1.3565979-15.4128418,5.5347061-37.0558243-0.2586994c-0.0250435,0-0.0500908-0.0210323-0.0751343-0.0210323        v0.0210323c0,0.1083164,0.0250435,0.1724663,0.0250435,0.2797318        c0.0250473,0.2586994,0.0500908,0.5174007,0.0500908,0.7761002        c0.3503265,6.9133892-1.2009468,14.0423603-4.2033081,21.3858662        c-0.0500908,0.1724663-0.1252251,0.3449326-0.2003632,0.5173988c0,0-0.1750107-0.4953156-0.4251556-1.3786812v-0.0210304        c-1.9516907-6.7850914-8.8073387-35.8604469,16.1632843-40.7704887        c1.726284-0.3449326,2.7772636,0.6246662-2.7271729,5.4484749c1.9263382-1.3776302,14.4366989-9.8852844,26.4968491-6.7188387        c13.0857849,3.4461784,3.3780441,8.635952,2.8524017,9.0019188c0,0,9.0825272,1.8098488,9.0825272,6.9133892        C96.2328415,29.1687222,97.5590134,36.1031456,93.6806793,45.8811607z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  className="st38"
                  d="M69.14077,71.8156433c-1.7353516,0-3.3671875-0.2084961-4.59375-0.5869141        c-2.1982384-0.6779785-2.6591759-1.7785645-2.6591759-2.5827637c0-0.5505371,0.4453125-0.9934082,0.9960938-0.9956055        c0.0009766,0,0.0029297,0,0.0039063,0c0.5478516,0,0.9931641,0.4445801,1,0.9921875        c0.1171684,0.3366699,1.8378868,1.1730957,5.2529259,1.1730957c3.4248047,0,5.1455078-0.8413086,5.2529297-1.1760254        c0-0.5524902,0.4472656-0.9968262,1-0.9968262c0.5517578,0,0.9990234,0.4509277,0.9990234,1.0031738        C76.3927231,70.9828796,72.6466293,71.8156433,69.14077,71.8156433z"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className="st16"
                    d="M77.6675568,81.1081848c0,0,12.484848,8.3727798-1.4844894,22.078598         c-0.5734634,0.5626373-1.5430908,0.5072556-2.0622559-0.1019745l-6.1130142-7.1734619         c-0.5353928-0.6282654-0.3743668-1.5598526,0.3495178-1.9796906         C71.2149658,92.2742691,77.4636765,87.8859024,77.6675568,81.1081848z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  className="st26"
                  d="M33.076149,95.9676361c1.3860397,0.5968781,2.683445,1.2889175,3.8835373,2.0618896        c-0.7676735,0.4313736-1.5051994,0.8972092-2.2104988,1.3931274        c-1.2462616-0.6624374-2.5836029-1.2363815-4.0022945-1.7077713        C31.4876842,97.0990601,32.2646523,96.515213,33.076149,95.9676361z"
                />
              </g>
            </g>
            <g className="st43">
              <g>
                <g>
                  <path
                    className="st28"
                    d="M50.7047501,93.716713c0.2999382,0.4494247-0.1054573,0.7958755-0.8904266,0.8133011         c-6.528923,0.1766205-12.3007202,2.523941-16.5850677,6.0533981         c-0.4113846,0.3353271-0.9708633,0.5352325-1.4180603,0.521965         c-0.1116409-0.0018311-0.2158585-0.0194397-0.3093262-0.0548706c-0.7744408-0.2811813-1.576458-0.527771-2.403759-0.7376175         c-0.4879227-0.1179962-0.4856186-0.6457977,0.0526752-1.1684418c0.5113449-0.493187,1.0437355-0.9695969,1.5959854-1.4295502         c1.4185505,0.4709091,2.7562923,1.0457993,4.0024166,1.707756c0.7050972-0.4963913,1.4427795-0.9612427,2.2103157-1.3930969         c-1.1999512-0.7724915-2.4973602-1.4645309-3.8832321-2.0619354c0.834404-0.5633087,1.7048454-1.0895767,2.6088486-1.5754013         c1.3419876,0.7226715,2.5888405,1.5324783,3.7322884,2.4126205c0.8270988-0.3593216,1.6822739-0.6798401,2.5624199-0.9605484         c-1.0820313-0.9830399-2.2727318-1.9054718-3.5643845-2.7468872         c2.6042938-1.0814667,5.4336281-1.8364487,8.4187279-2.1884766c0.6826439-0.0831528,1.4677963,0.087616,1.929966,0.3838501         c0.1157875,0.0736465,0.2113228,0.1520233,0.2818642,0.2374039         C49.6391373,92.2438965,50.1922417,92.9740448,50.7047501,93.716713z M44.7759628,95.1127167         c0.9263077-0.1939621,1.8748856-0.3421478,2.8422585-0.445015c-0.9343376-1.1797028-1.985321-2.3156509-3.1484528-3.3899307         c-1.0406342,0.2021103-2.0583725,0.4539261-3.0506363,0.751503         C42.6469879,92.9925842,43.7679367,94.0267944,44.7759628,95.1127167"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className="st26"
                    d="M44.4698296,91.2777557c1.1631966,1.0742798,2.2141762,2.2102203,3.1484528,3.3899384         c-0.9674683,0.1018753-1.9158859,0.2510452-2.8423195,0.4450226         c-1.0078888-1.0854416-2.1287117-2.1196671-3.3566475-3.0834732         C42.411274,91.73172,43.4292603,91.479866,44.4698296,91.2777557z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className="st26"
                    d="M38.4156265,93.097374c1.2916527,0.841423,2.4821091,1.7638931,3.5642014,2.7469177         c-0.880146,0.2807083-1.7350769,0.6011887-2.5622368,0.9605179c-1.1435089-0.8801346-2.3905182-1.690918-3.7324257-2.4131012         c0.8490677-0.455925,1.7279396-0.8748398,2.6340485-1.254303         C38.3513374,93.1242294,38.3833389,93.1110764,38.4156265,93.097374z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  className="st26"
                  d="M102.4465408,95.2883224c-1.3183136,0.7344589-2.5387955,1.5545807-3.6542435,2.4453506        c0.8074875,0.3512497,1.5884781,0.7398224,2.3404617,1.1616135c1.1725998-0.7854996,2.4447937-1.4921951,3.8083191-2.1051254        C104.1416168,96.2527008,103.3094177,95.7507172,102.4465408,95.2883224z"
                />
              </g>
            </g>
            <g className="st43">
              <g>
                <g>
                  <path
                    className="st28"
                    d="M86.1088104,92.4944382c0.0615082-0.0921021,0.1485977-0.1797714,0.2563171-0.2647858         c0.429718-0.3416061,1.1934891-0.5911713,1.8810501-0.5777283c3.0054092,0.0472794,5.8967514,0.5112381,8.5973511,1.3228302         c-1.1995926,0.9681473-2.290535,2.0066528-3.2672195,3.0944214c0.9040833,0.1899414,1.7873764,0.42202,2.6466675,0.695549         c1.0482254-0.9916306,2.206459-1.92379,3.4681854-2.778923c0.9486313,0.3915787,1.8679886,0.8267975,2.755249,1.3025131         c-1.3180847,0.7349701-2.5385666,1.5550919-3.6539307,2.4453659         c0.8073959,0.3517456,1.5884476,0.7393265,2.3402786,1.1616058c1.1725159-0.7850037,2.4450073-1.4926758,3.8084488-2.1051178         c0.596077,0.401535,1.1740646,0.8214569,1.7328186,1.2602081c0.5885544,0.4653168,0.6444092,0.9901581,0.1709747,1.1570587         c-0.8017273,0.2927246-1.574585,0.6194305-2.3164902,0.9777527         c-0.0893936,0.0447311-0.1912842,0.0728226-0.3021622,0.0859756         c-0.4435425,0.0585785-1.0204163-0.0835114-1.463707-0.3753662         c-4.6204071-3.0764389-10.6006165-4.8259125-17.1137619-4.3390427         c-0.7826843,0.0623245-1.2211456-0.2412033-0.9683609-0.7187424         C85.1150131,94.0471725,85.5911636,93.2646561,86.1088104,92.4944382z M90.7203674,95.6251373         c0.8926239-1.1826096,1.9028244-2.3252335,3.0265808-3.4081802c-1.017334-0.195343-2.0553741-0.3425751-3.1111526-0.4380417         c-1.0481033,1.1867752-1.9783783,2.4235153-2.7881775,3.6919479         C88.8204422,95.475029,89.7791595,95.5261841,90.7203674,95.6251373"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className="st26"
                    d="M90.6357346,91.7789154c-1.0481644,1.1867676-1.9784393,2.4235077-2.7881775,3.6919403         c0.9728165,0.0031738,1.9314728,0.0553207,2.8728104,0.1542816c0.8925323-1.1821213,1.9026108-2.3247528,3.0263977-3.4081879         C92.7297363,92.0216217,91.6914444,91.8743744,90.6357346,91.7789154z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    className="st26"
                    d="M96.8433456,92.9747391c-1.1996002,0.9681549-2.2902908,2.0066757-3.2670364,3.0944366         c0.9040833,0.1899414,1.7871246,0.4220047,2.6464844,0.6955414c1.0482864-0.9916306,2.2065735-1.9247818,3.4682693-2.7794189         c-0.8909531-0.3674088-1.8078003-0.6949692-2.7477417-0.9805145         C96.9100266,92.9949341,96.8768539,92.9850998,96.8433456,92.9747391z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path
            className="st26"
            d="M99.7451706,109.3696671l-7.2819519,1.2996445v-1.5903931     c0-0.0147095-0.0061646-0.0271912-0.0090408-0.7634811c-0.001503-0.3835983-0.3129425-0.6993637-0.6965485-0.6993637h-1.042305     c-0.3836136,0-0.6950607,0.315773-0.6965485,0.699379c-0.0028687,0.7362747-0.0089874,0.7487564-0.0089874,0.7634659v1.6241913     l-7.2707138-1.3220139c-0.7617722-0.122757-1.0978699,0.9293823-0.4033127,1.254425l8.2790604,3.8204193     c0.3808212,0.1794205,0.817749,0.1794205,1.1986313,0l8.3126678-3.8422928     C100.8319244,110.2886124,100.5069504,109.2354736,99.7451706,109.3696671z"
          />
        </g>
        <g>
          <g>
            <path
              className="st31"
              d="M101.7425919,27.6383743c-2.0908661,3.740036-5.7847443,6.6213493-9.7342148,8.9791489      c-3.4267349,2.0327988-7.2019348,3.7751999-11.3023453,4.3213997      c-3.8100815,0.5223808-7.9453812-0.1281815-10.9655075-2.1842384c-3.020134,2.056057-7.1438713,2.7066193-10.9655113,2.1842384      c-4.1004829-0.5461998-7.8756866-2.2886009-11.3139763-4.3213997c-3.9494705-2.3577995-7.6433487-5.2391129-9.7225876-8.9791489      c-2.0908661-3.7519455-2.3000183-8.4794559,0.4065323-11.9064026c2.0676804-2.6136007,5.4827881-4.1586199,8.9210815-5.2039471      C50.504425,9.470787,54.1053581,8.7969685,57.3694496,7.403388c2.1024933-0.8944545,4.0424194-2.0793095,6.1565399-2.9391661      c1.9398537-0.7781816,4.1118507-0.96508,6.2145348-0.9642191c2.1141739,0.0008657,4.2630539,0.1860375,6.2029037,0.9642191      c2.1257477,0.8598566,4.0540466,2.0447116,6.1565399,2.9391661c3.2757187,1.3935804,6.8766479,2.067399,10.3150101,3.1246367      c3.4266663,1.0453272,6.8534012,2.5903463,8.9094543,5.2039471      C104.030983,19.1589184,103.8218307,23.8864288,101.7425919,27.6383743z"
            />
          </g>
          <g>
            <path
              className="st28"
              d="M94.0366287,36.1995049v0.0465088c0,0.7895241-0.4181595,1.5444527-1.1847839,2.264782      c-3.1595917,2.9385986-12.3130112,5.064415-23.1158752,5.064415s-19.9562874-2.1258163-23.1158066-5.064415      c-0.7666931-0.7203293-1.1848526-1.4752579-1.1848526-2.264782v-0.0465088      c0.0813179-4.031002,10.9306259-9.1770954,24.3006592-9.1770954S93.9553757,32.1685028,94.0366287,36.1995049z"
            />
          </g>
          <g>
            <path
              className="st25"
              d="M94.0366287,36.1995049v0.0465088c0,0.7895241-0.4181595,1.5444527-1.1847839,2.264782      c-5.6686172-2.5670929-13.9275818-4.4836197-23.1158752-4.4836197c-9.1882286,0-17.4472618,1.9165268-23.1158066,4.4836197      c-0.7666931-0.7203293-1.1848526-1.4752579-1.1848526-2.264782v-0.0465088      c5.6569862-2.8461494,14.4386826-5.0530758,24.3006592-5.0530758S88.3796387,33.3533554,94.0366287,36.1995049z"
            />
          </g>
        </g>
        <g>
          <path
            className="st26"
            d="M78.2435989,16.6152916l-7.2819519,1.2996502v-1.5903931     c0-0.0147114-0.0061646-0.0271969-0.0090408-0.763484c-0.001503-0.3836012-0.3129425-0.6993618-0.6965485-0.6993618h-1.042305     c-0.3836136,0-0.6950607,0.3157711-0.6965485,0.699378c-0.0028687,0.7362709-0.0089874,0.7487564-0.0089874,0.7634678v1.6241894     l-7.27071-1.3220158c-0.761776-0.1227589-1.0978699,0.9293861-0.4033165,1.2544231l8.2790604,3.820425     c0.3808212,0.1794167,0.817749,0.1794167,1.1986313,0l8.3126678-3.8422928     C79.3303528,17.5342407,79.0053711,16.481102,78.2435989,16.6152916z"
          />
        </g>
      </g>
    </g>
  </svg>
  )
};
export default PilotIcon;

