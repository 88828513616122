
 const validateEmail = (mail) =>
 {
     return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail))
 }
 
/***********************************************/
export function IsPicInfoFull(picInfo){
   return ( picInfo.picName && picInfo.picName.length && 
            picInfo.licenseNum && picInfo.licenseNum.length &&
            picInfo.phoneNumber && picInfo.phoneNumber.length &&
            picInfo.emailAddress && picInfo.emailAddress.length)
}

/***********************************************/
 export function ValidatePicInfo(picInfo){

     if(picInfo.phoneNumber.length && !picInfo.phoneNumber.match(/^[0-9-+ ]+$/)){
        return("מספר הטלפון אינו חוקי"); 
     }

     if(picInfo.picName.length && !picInfo.picName.match(/^[A-Za-z- ]+$/)){
        return("שם הטייס באנגלית בלבד"); 
     }

     if(picInfo.picName.length && picInfo.picName.trim().indexOf(' ') === -1){
        return("אנא ציין שם + שם משפחה"); 
     }

     if(picInfo.emailAddress.length && !validateEmail(picInfo.emailAddress)){
        return("כתובת האימייל אינה חוקית"); 
     }

     var lNum = picInfo.licenseNum.trim()
     if (picInfo.licenseNum.length && (isNaN(lNum) || lNum.length > 5 || lNum < 100 || lNum > 20000)){
        return("מספר רשיון הטייס אינו חוקי"); 
     }

     if(picInfo.licenseNum.length && lNum.match(/[ ,;]/)){
        return("ניתן להגיש תוכנית לטייס אחד בלבד"); 
     }

     return null;
 }