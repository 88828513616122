import { getDigits} from ".";
const SolarCalc = require('solar-calc');

export function filterTrafficRules(rules, departureTime,mapUsed){

    const departureDate= new Date(departureTime)

    var trafficRules = []

    if(rules){
      for(const rule of rules){
  
          if(rule.mapUsed && rule.mapUsed.includes(mapUsed)){
  
              if(checkInTimeRange(rule, departureDate) && checkSchedule(rule, departureDate)){
                  trafficRules.push(rule)
              }
          }
      }
    }
    return trafficRules;
}

/***********************************************/

function checkInTimeRange(rule ,departureDate){

    const ruleStartTime = new Date (rule.startTime)
    const ruleEndTime = new Date (rule.endTime)

    const landDate = new Date (departureDate)
    landDate.setHours(landDate.getHours() + 1)

    if(ruleStartTime < landDate && ruleEndTime > departureDate){
        var timeReason = ""
        var startHour = null;
        var startMinutes = null;
        var endHour = null;
        var endMinutes = null;

        if(departureDate.toDateString() === ruleStartTime.toDateString()){
            startHour = getDigits(ruleStartTime.getHours(),2);
            startMinutes = getDigits(ruleStartTime.getMinutes(),2);
            timeReason =  ` מ ${startHour}:${startMinutes}`;
        }
        if(departureDate.toDateString() === ruleEndTime.toDateString()){
            endHour = getDigits(ruleEndTime.getHours(),2);
            endMinutes = getDigits(ruleEndTime.getMinutes(),2);
            timeReason +=  ` עד ${endHour}:${endMinutes}`;
        }

        rule.timeReason = timeReason;

        return true
    } else{
        return false;
    }
}

/***********************************************/

function checkSchedule(rule, departureDate){

    const schedule = rule.schedule;

    if(!schedule || !schedule.in || !schedule.in.length )
      return true;
  
    var foundIn = false
    for(const sch of schedule.in){
      if(isInTimeRule(rule, sch,departureDate)){
        foundIn = true;
        break;
      }
    }
  
    var foundOut = false
  
    if(schedule.out){
      for(const sch of schedule.out){
        if(isInTimeRule(rule, sch,departureDate)){
          foundOut = true;
          break;
        }
      }
    }
 
    return (foundIn && !foundOut)
  
  }

  /***********************************************/
  
  function isInTimeRule(rule, sch, departureDate) {
  
    const {daysOfWeek, hours, daysOfMonth, months} = sch;
  
  
    const depDayOfWeek   = departureDate.getUTCDay();
    const depDayOfMonth  = departureDate.getUTCDate();
    const depMonth       = departureDate.getUTCMonth();
    const hour           = departureDate.getUTCHours();
    const minute         = departureDate.getUTCMinutes();

  
    if(daysOfWeek.length && !daysOfWeek.includes(depDayOfWeek)){
      return false;
    }
  
    if(daysOfMonth.length && !daysOfMonth.includes(depDayOfMonth)){
      return false;
    }
  
    if(months.length && !months.includes(depMonth)){
      return false;
    }
  
    if(hours.length){
      const depMinutes = hour * 60 + minute;
      const landMinutes = (hour + 1) * 60 + minute;
   
      for(const time of hours){
    
        var startH = time.start.hour
        var startM = time.start.minute
        var endH = time.end.hour
        var endM = time.end.minute

        if(startH === 'SR' || endH === 'SS'){
  
          // get the ss ans sr in LLRS
          const solar = new SolarCalc(departureDate,31.96,34.75);
  
          const SRiz = solar.civilDawn 
          const SSet = solar.civilDusk
 
          if (startH === 'SR'){
            startH = SRiz.getUTCHours();
            startM = SRiz.getUTCMinutes();
          }
  
          if (endH === 'SS'){
            endH = SSet.getUTCHours();
            endM = SSet.getUTCMinutes();
          }
        }

        const ruleStart = parseInt(startH) * 60 + parseInt(startM);
        const ruleEnd = parseInt(endH) * 60 + parseInt(endM);

        if (depMinutes <= ruleEnd && landMinutes >= ruleStart ){

          const startDate = new Date (departureDate)
          startDate.setUTCHours(startH)
          startDate.setUTCMinutes(startM);

          const endDate = new Date (departureDate)
          endDate.setUTCHours(endH)
          endDate.setUTCMinutes(endM);

          rule.timeReason =  time.start.hour === 'SR' ? ` מהזריחה` :  ` מ ${startDate.getHours()}:${startM}`
          rule.timeReason += time.end.hour === 'SS' ? `  עד השקיעה` :  ` עד ${endDate.getHours()}:${endM}`

          return true;
        }
      }
  
      // false since there are hour rules but there isn't any match
      return false;
  
    }
    
    return true;
  }
  