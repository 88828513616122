import {Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {FlightTime, } from '.'
import { DepartureIcon } from '../assets';
import {useState, useEffect} from "react";



// 
export function ChangeDepartureTime (props) {

    const { departureTime, updateDepartureTime, timeDialogOpen , setTimeDialogOpen } = props


    const [dlgDepartureTime, setDlgDepartureTime] = useState(departureTime);


    useEffect(() => {

      setDlgDepartureTime(departureTime)

    }, [departureTime]);

    const closeDialog = ()=>{ 
      updateDepartureTime(dlgDepartureTime)
      setTimeDialogOpen(false)
    }

    return(
    
        <Dialog className="fpl-submit" open={timeDialogOpen} onClose={closeDialog}>

        <div style={{textAlign: 'center'}}>
          <p></p>
          <DepartureIcon/>
        </div> 

        <DialogTitle  textAlign="center">זמן המראה</DialogTitle>

        <DialogContent dir="ltr">

          <FlightTime departureTime = {dlgDepartureTime} updateDepartureTime = {setDlgDepartureTime} />

        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={closeDialog}> OK </Button>
        </DialogActions>
      </Dialog>
    )    
}

export default ChangeDepartureTime;
