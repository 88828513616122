import {byAtcPermissionLsaRoutes, byAtcPermissionCvfrRoutes, oneWayRoutes, flightTimeValues, nonLandingPoints } from "../data";
import { LoadBubbles, isAirport} from "../airways";

/***********************************************/

export function getDigits(param,num){
    return (("0000" + param).slice(-num).toString())
}

/***********************************************/

function isTomorrow(date) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toDateString() === date.toDateString()
}

function isToday(date) {
    const today = new Date();
    return today.toDateString() === date.toDateString()
}


export function checkIfAtcPermissionRoutes(route, mapUsed){

    var byAtcMessages = []

    const byAtcPermissionRoutes = (mapUsed === 'lsa') ? byAtcPermissionLsaRoutes : byAtcPermissionCvfrRoutes

    const insertAtcRouteMessage = ((point1,point2) => byAtcMessages.push(`לא ניתן להגיש תוכנית טיסה לנתיב באישור בקר (מקווקו): ${point1.name} - ${point2.name}`))

    for (let i = 0; i < route.length ; i++) {
        byAtcPermissionRoutes.forEach(segment => {
            if(i !== route.length - 1 && route[i].icao === segment[0] && route[i+1].icao === segment[1]){
                insertAtcRouteMessage(route[i],route[i+1])
            }
            else if(i !== 0 && route[i].icao === segment[0] && route[i-1].icao === segment[1]){
                insertAtcRouteMessage(route[i-1],route[i])
            }
        });
    }

    return byAtcMessages 

}

export function VerifySendingTime(departureTime){

        var errorMessage = null;

        const hour =  1000 * 60 * 60;
        const currentTIme = new Date();   
        
        var timeDiff = departureTime.getTime() - currentTIme.getTime();
        if(timeDiff < hour){
            errorMessage= 'יש להגיש תוכנית טיסה שעה לפחות לפני זמן ההמראה';
        }else {
    
            const currentHour     = currentTIme.getHours();
            const departureHour   = departureTime.getHours();
            const departureMinute = departureTime.getMinutes();

            if(!isToday(departureTime)){
                if( (departureHour > 17) || (departureHour === 17 && departureMinute > 0)) {
                    errorMessage= 'לטיסה שממריאה אחרי 17:00 יש להגיש תוכנית ביום הטיסה';            
                }else {
                    if(!(isTomorrow(departureTime) && currentHour >= 18) ){
                        errorMessage= 'לטיסה שממריאה עד 17:00 ניתן להגיש תוכנית טיסה ערב לפניה, אחרי השעה 18:00 ';
                    }
                }
            }
        }

        return errorMessage
}


export function isSuspectedAirport(route, aircraftType ){
    var retMsg = "";
    const notLsaAirports = [
        ["LLHZ", "שדה התעופה הרצליה"],
        ["LLBG", "נמל התעופה בן גוריון"]
    ]

    if(aircraftType === "ULAC"){
        for (const airport of notLsaAirports){
            if(airport[0] === route[0].icao){
                retMsg += `מטוס אז"מ אינו מורשה להמריא מ` + airport[1] + ". ";
            }
            if(airport[0] === route[route.length - 1].icao){
                retMsg += `מטוס אז"מ אינו מורשה לנחות ב` + airport[1] + ". ";
            }
        }
    }

    return retMsg.length ? retMsg : null
}

export function IsTimeOverlap(departureTime, flightTime, lastFplTimes){

   if(lastFplTimes.length){

        const index = flightTimeValues.findIndex((a) => a[0] === flightTime);

        if (index > -1) {
    
            const landingTime =  new Date(departureTime.getTime()); 
            landingTime.setMinutes(landingTime.getMinutes() + flightTimeValues[index][2])
    
            for (let i = 0; i < lastFplTimes.length; i++) {
                let lastFplDeparture = new Date(lastFplTimes[i].departure)
                let lastFplLanding = new Date(lastFplTimes[i].landing)
                if( departureTime < lastFplLanding && landingTime > lastFplDeparture){

                    var errorMessage = "זמני התוכנית חופפים את אלו של תוכנית שנשלחה קודם לכן לזמנים: ";
                    errorMessage += lastFplDeparture.getHours() + ':' + getDigits( lastFplDeparture.getMinutes(),2);
                    errorMessage += ' ועד ' + lastFplLanding.getHours() + ':' + getDigits( lastFplLanding.getMinutes(),2);
                    errorMessage += ".\nלתיקון או ביטול תוכנית טיסה קודמת יש להתקשר למודיעין טייס.";
                    return errorMessage;
                }
            }
        }
   }
   
    return null
}



export function VerifyRoute (route,departureTime,mapUsed, timeCategory)
{
    var errorMessages = []

    if(!route || route.length < 2) {
        errorMessages.push("הנתיב ריק. אנא סמן שוב");
        return errorMessages;
    }


    if(mapUsed === 'lsa'){

        // Check if bubbles time fits
        const bubblesData = LoadBubbles("lsa", "AllOpen")
       
        var weekendMsgs = []
        var friday12Msgs = []
        var friday14Msgs = []

        if(timeCategory !== "AllOpen"){

            route.forEach(point => {

                const bubble = bubblesData.find(o => o.icao === point.icao)

                // if not all week bubble
                if(bubble){

                    var name = point.name;

                    const {timeOpened} = bubble;

                    if(timeCategory === "WeekDay"){
                        if(timeOpened !== 0 ){
                            weekendMsgs.push(name)
                        }
                    } 
                    else if(timeCategory === "OpenOnly6"){
                        if(timeOpened === 12 || timeOpened === 14 ){
                            friday12Msgs.push(name)
                        }
                    } else if(timeCategory === "OpenOnly12"){
                        if(timeOpened === 14 ){
                            friday14Msgs.push(name)
                        }
                    }
                }
            });
        }

        const pushBubbleMessages = (timeArray,timeMsg) => {
            const aLen = timeArray.length
            if(aLen){
                var msg = "";
                msg += timeArray[0]
                if (aLen > 1) {
                    for (let i = 1; i < aLen - 1; i++) {
                        msg += ', ' + timeArray[i] 
                    }
                    msg += ' ו' + timeArray[aLen - 1] + ` פעילות ב${timeMsg} בלבד`
                } 
                else {
                    msg += ` פעילה ב${timeMsg} בלבד`
                }
                errorMessages.push(msg)
            }
        }

        pushBubbleMessages(weekendMsgs,'סופ"ש')
        pushBubbleMessages(friday12Msgs,'שישי משעה 12:00')
        pushBubbleMessages(friday14Msgs,'שישי משעה 14:00')

    }


/***********************************************/

    if(mapUsed === 'cvfr'){

        const insertOneWayRouteMessage = ((point1,point2) => errorMessages.push(` נתיב ${point1.name} - ${point2.name} הוא חד כיווני`))

        for (let i = 0; i < route.length-1 ; i++) {
            oneWayRoutes.forEach(segment => {
                if(route[i].icao === segment[0] && route[i+1].icao === segment[1]){
                    insertOneWayRouteMessage(route[i],route[i+1])
                }
            });
        }
    }

/***********************************************/
    if(route.length && route[0].icao && route[0].icao === 'LLER'){
        errorMessages.push('לטיסה הממריאה מאילת ושהתה יותר מ30 דקות בשדה יש להגיש תוכנית למודיעין טייס אילת')
    }

/***********************************************/

    // Check that the routes are not a permission by atc only
    const atcPermissionMessages =  checkIfAtcPermissionRoutes(route, mapUsed)
    atcPermissionMessages.forEach(message => {
        errorMessages.push(message)
    });

/***********************************************/
    // Check that the route is for one lag only 

    for (let i = 1; i < route.length - 1 ; i++) {
        if(isAirport(route[i].icao)){
            errorMessages.push('יש להגיש תוכנית טיסה נפרדת לכל לג')            
            break;
        }
    }

/***********************************************/
    if(nonLandingPoints.includes(route[0].icao)){
        errorMessages.push('לא ניתן להמריא מנקודת ' + route[0].name)
    }

    if(nonLandingPoints.includes(route[route.length - 1].icao)){
        errorMessages.push('לא ניתן לנחות בנקודת ' + route[route.length - 1].name)
    }

/***********************************************/
    // Remove duplicate messages
    let uniqueMessages = errorMessages.filter((message, index) => {
        return errorMessages.indexOf(message) === index;
    });

/***********************************************/

    return uniqueMessages;

}

export default VerifyRoute;
