import {useEffect, useRef, forwardRef} from "react";
import { List, ListItem, Box } from "@mui/material";
import Control from "react-leaflet-custom-control";
import L from 'leaflet';

  // Display the list of route point on the right side of the screen
  export function RouteList (props, ref) {

    const {waypointRoute} = props

/***********************************************/

    // Scroll the list to the bottom
    const listEndRef = useRef(null);
  
    const scrollToBottom = () => {
      listEndRef.current.scrollTo({ 
        top: listEndRef.current.scrollHeight
      });
    };
    // useEffect(scrollToBottom, [waypointRoute]);
    useEffect(scrollToBottom);
    
/***********************************************/

    useEffect(() => {
        if (ref.current) {
            L.DomEvent.disableClickPropagation(ref.current);
        }
    });


/***********************************************/

    return (
      <>
      <Control position="topright">
        <Box 
          ref = {ref}
          backgroundColor = '#eee'
          sx={{ 
            maxWidth: {xs: 70, sm: 200, md: 200, lg: 200, xl: 200 },
            fontSize: {xs: 12, sm: 14 },
            boxShadow: 5,
            }}
        >
          <List className="route-list" 
              ref={listEndRef} 
              color="primary" 
              style={{ 
                overflow: 'auto' 
              }}>
              {waypointRoute.map(point => (
                  <ListItem style={{ paddingRight: 5, paddingLeft: 5}} key={point.key} > 
                    {point.name} 
                  </ListItem>
              ))}
          </List>
        </Box>
      </Control>
      </>
    );
  }

  export default  forwardRef(RouteList);
