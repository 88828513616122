import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText } from '@mui/material';
import {FlightTime} from '.'


// 
export function OpeningDialog (props) {

    const {departureTime, updateDepartureTime, openingDialogOpen , closeOpeningDialog} = props

    const [dlgDepartureTime, setDlgDepartureTime] = useState(departureTime);

    const closeDialog = ()=>{ 
      updateDepartureTime(dlgDepartureTime)
      closeOpeningDialog()
    }
  
    return(
    
        <Dialog className="fpl-submit" open={openingDialogOpen} onClose={closeOpeningDialog}>
        <DialogTitle>תוכנית טיסה</DialogTitle>

        <DialogContent >
          {/* <DialogContentText dir="rtl" textAlign="right">
            ישומון זו מאפשר הכנת תוכנית טיסה לשליחה באימייל.
          </DialogContentText> */}
          
          {/* <p></p> */}

          <DialogContentText dir="rtl" textAlign="right">
            הישומון מבצע בדיקות חוקיות בסיסיות בלבד ולכן האחריות על בדיקת כל הפרמטרים בתוכנית ובכללם גם תאימות לפמ"ת, נוטאמים, ומזג אוויר, חלה על הטייס.
          </DialogContentText>

          <p></p>

          <DialogContentText dir="rtl" textAlign="right">
           בשלב ראשון יש לבחור את שעת ההמראה.
          </DialogContentText>

          {/* <DialogContentText dir="rtl" textAlign="right">
          החל מהשעה 18:00 ניתן להגיש תוכנית לטיסה הממריאה עד השעה 17:00 למחרת.
          </DialogContentText> */}

          <p></p>
          <div  dir="ltr">
          <FlightTime departureTime = {dlgDepartureTime} updateDepartureTime = {setDlgDepartureTime} />
          </div>

          {/* <p></p> */}

          {/* <DialogContentText dir="rtl" textAlign="right">
          כעת, יש לסמן את נתיב הטיסה על המפה
          </DialogContentText> */}

        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={closeDialog}> המשך </Button>
        </DialogActions>
      </Dialog>
    )    
}

export default OpeningDialog;
