import * as React from "react"

export function DepartureIcon(props) {

  return (

    <svg
    height="64px"
    width="64px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#2D527C",
      }}
      d="M62.086,363.182c-1.996,0-3.992-0.421-5.851-1.265c-3.7-1.679-6.502-4.865-7.694-8.749 l-29.764-97.093c-2.094-6.829,1.227-14.15,7.745-17.076L420.988,61.978c32.471-14.57,70.748-0.01,85.321,32.464 c7.059,15.73,7.57,33.268,1.439,49.383c-6.131,16.114-18.171,28.877-33.902,35.936l-73.969,33.195 c-7.137,3.202-15.522,0.013-18.725-7.124c-3.203-7.138-0.013-15.522,7.124-18.725l73.969-33.195 c18.22-8.177,26.39-29.651,18.213-47.871s-29.654-26.391-47.871-18.215L49.53,259.729l21.396,69.793l217.396-97.56 c7.138-3.203,15.522-0.013,18.725,7.124c3.203,7.137,0.013,15.522-7.124,18.725L67.885,361.941 C66.041,362.769,64.063,363.182,62.086,363.182z"
    />
    <polygon
      style={{
        fill: "#CEE8FA",
      }}
      points="62.512,189.712 14.169,211.407 62.086,349.016 156.151,306.803 "
    />
    <path
      style={{
        fill: "#2D527C",
      }}
      d="M62.086,363.182c-1.908,0-3.816-0.385-5.607-1.157c-3.646-1.571-6.465-4.601-7.771-8.351 L0.791,216.065c-2.417-6.94,0.874-14.575,7.579-17.583l48.343-21.694c5.894-2.645,12.829-0.966,16.863,4.077l93.637,117.091 c2.737,3.423,3.736,7.917,2.707,12.177c-1.03,4.258-3.972,7.801-7.97,9.595l-94.065,42.213 C66.041,362.769,64.063,363.182,62.086,363.182z M31.817,219.013l38.564,110.753l63.282-28.399l-75.363-94.238L31.817,219.013z"
    />
    <polygon
      style={{
        fill: "#CEE8FA",
      }}
      points="130.057,85.297 69.036,112.681 244.182,198.814 370.133,142.291 "
    />
    <g>
      <path
        style={{
          fill: "#2D527C",
        }}
        d="M244.182,212.981c-2.142,0-4.282-0.486-6.251-1.453L62.784,125.393 c-4.927-2.422-8.009-7.473-7.912-12.962c0.098-5.489,3.356-10.426,8.364-12.674l61.022-27.384c2.847-1.278,6.038-1.58,9.072-0.858 l240.076,56.994c5.903,1.401,10.259,6.407,10.831,12.448c0.571,6.04-2.768,11.775-8.303,14.26l-125.953,56.522 C248.135,212.568,246.156,212.981,244.182,212.981z M102.329,113.268l142.128,69.895l81.829-36.721l-194.817-46.251 L102.329,113.268z"
      />
      <path
        style={{
          fill: "#2D527C",
        }}
        d="M483.23,455.678h-356.81c-7.824,0-14.166-6.342-14.166-14.166s6.342-14.166,14.166-14.166h356.81 c7.824,0,14.166,6.342,14.166,14.166S491.054,455.678,483.23,455.678z"
      />
      <path
        style={{
          fill: "#2D527C",
        }}
        d="M60.268,455.678H28.75c-7.824,0-14.166-6.342-14.166-14.166s6.342-14.166,14.166-14.166h31.518 c7.824,0,14.166,6.342,14.166,14.166S68.092,455.678,60.268,455.678z"
      />
    </g>
  </svg>
  )
}

export default DepartureIcon
