import {Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, CircularProgress,Box} from '@mui/material';

// 
export function MsgDialog (props) {

    const { msgContent = "",  msgTitle = "", msgDialogOpen , closeMsgDialog, trueButtonText = "", falseButtonText = "", icon = null, progress = false } = props

    const msgArray = msgContent.split("\n")

/***********************************************/

    const buttonTrueClicked = ()=>{ 
        closeMsgDialog(true)
    }

/***********************************************/

    const buttonFalseClicked = ()=>{ 
        closeMsgDialog(false)
    }

/***********************************************/
    
    return(
    
      <Dialog 
        className="fpl-submit" 
        open={msgDialogOpen} 
        onClose={buttonFalseClicked} 
        >

        {msgTitle.length ? <DialogTitle>{msgTitle}</DialogTitle> : null} 

        <DialogContent>
            {msgArray.map((msg,index) => {
                return(
                    <DialogContentText key={index} dangerouslySetInnerHTML = {{ __html: msg }}></DialogContentText>
                )
            })}

            {progress ? 
                <div>
                <p/><p/>
                <Box display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress/>
                </Box>
                </div> : null
            }

            {icon ?
                <div style={{textAlign: 'center'}}>
                    <p/><p/>
                    {icon}
                </div> :null
            }

        </DialogContent>

        <DialogActions>

          {falseButtonText.length ? <Button onClick={buttonFalseClicked}>{falseButtonText}</Button> : null}
          {trueButtonText.length ? <Button variant="contained" onClick={buttonTrueClicked}>{trueButtonText}</Button> : null}

        </DialogActions>
      </Dialog>
    )    
}

export default MsgDialog;
