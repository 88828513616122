export const rulesMod = [
  {
    ifClosed: ["ZMGID","NIRYA"],
    alsoClose: [
                ["ZMGID", "BHROD"],
                ["BARRA", "BHROD2"],
                ["ZMGID", "BARRA"],
                ["NIRYA", "BHROD"],
               ]
  }
]