import {LoadReportPoints , LoadAirstrips, LoadBubbles} from ".";
import gju from "geojson-utils";


export function FindNearestFeature (point,mapUsed,timeCategory,rules = null, openAirways = null)
{
    const distance = 500; // 500m

    const updatePoint = (airWay) => {
        var updatedPoint = point;
        updatedPoint.name = airWay.name;
        updatedPoint.icao = airWay.icao;
        if(airWay.latlng){
           updatedPoint.latlng.lat = airWay.latlng[0];
           updatedPoint.latlng.lng = airWay.latlng[1];
        }
        return updatedPoint
    }

    const reportPoints = LoadReportPoints(mapUsed,timeCategory, openAirways)
    const airstrips = LoadAirstrips(mapUsed,rules, openAirways);
    const bubbles = LoadBubbles(mapUsed, timeCategory,rules, openAirways);

    const pinPoint = {
        type: 'Point', 
        coordinates:[point.latlng.lat, point.latlng.lng]
    }
   
    // Check if in the airstrips 
    for (let i = 0; i < airstrips.length; i++) {

        const targetPoint = {
            type: 'Point', 
            coordinates: airstrips[i].latlng
        }

        if( gju.pointDistance(pinPoint,targetPoint) < distance)
            return updatePoint(airstrips[i])
    }
    
    // Check the report points
    for (let i = 0; i < reportPoints.length; i++) {

        const targetPoint = {
            type: 'Point', 
            coordinates: reportPoints[i].latlng
        }

        if( gju.pointDistance(pinPoint,targetPoint) < distance)
            return updatePoint(reportPoints[i])
    }


    // Check the bubbles
    for (let i = 0; i < bubbles.length; i++) {

        if(!bubbles[i].closed){

            const bubble = {
                "type":"Polygon", 
                "coordinates": [bubbles[i].coords]
            }

            if( gju.pointInPolygon(pinPoint,bubble))
                return updatePoint(bubbles[i])
        }
    }

    // return the original point if not found
    return point;

}

export default FindNearestFeature;
