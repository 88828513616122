import {useState, useEffect} from "react";
import {DialogContentText } from '@mui/material'

export function RouteNames(props){

    const {route,routeLength} = props

    const [routeNames, setRouteNames] = useState( "");

    // Create the text for the dialog header
    useEffect(() => {
        var routeN = "";
        for (let i = 0; i < route.length; i++) {
        routeN += route[i].name
            if (i !== route.length - 1) {
            routeN += " -> "
            }
        }

        setRouteNames(routeN)
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeLength]);


    return(

        <DialogContentText dir="rtl" textAlign="right">
          {routeNames}
        </DialogContentText>
    )

}
export default RouteNames