export const lsaWeekendReportPoints = [
  {
    "name": "אולגה",
    "icao": "OLGAH",
    "latlng": [
      32.430335,
      34.865029
    ],
    "key": "OLGAH",
    "route": [
      {
        "icao": "PELEG",
        "distance": 62,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "SDTYM",
        "distance": 41,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "GSHML",
        "distance": 42,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 30,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 65,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARN1",
        "distance": 41,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "אופקים",
    "icao": "OFKIM",
    "latlng": [
      31.322222,
      34.618889
    ],
    "key": "OFKIM",
    "route": [
      {
        "icao": "GILAT",
        "distance": 20,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "TZHOT",
        "distance": 47,
        "alt": 1500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "צלחות",
    "icao": "TZHOT",
    "latlng": [
      31.307778,
      34.529722
    ],
    "key": "TZHOT",
    "route": [
      {
        "icao": "OFKIM",
        "distance": 47,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "NBSOR",
        "distance": 27,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLUR",
        "distance": 27,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 104,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "אלון",
    "icao": "ALLON",
    "latlng": [
      32.03971171673081,
      35.36539865216844
    ],
    "key": "ALLON",
    "route": [
      {
        "icao": "FAZEL",
        "distance": 49,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "SHAHR",
        "distance": 52,
        "alt": 2700,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "אליקים",
    "icao": "ELYKM",
    "latlng": [
      32.63538178277776,
      35.06388867915449
    ],
    "key": "ELYKM",
    "route": [
      {
        "icao": "BTSLM",
        "distance": 40,
        "alt": 1000,
        "time": 6,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 67,
        "alt": 2200,
        "time": 6,
        "active": false
      },
      {
        "icao": "DALIA",
        "distance": 25,
        "alt": 1200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "דליה",
    "icao": "DALIA",
    "latlng": [
      32.59578189977683,
      35.08305368082976
    ],
    "key": "DALIA",
    "route": [
      {
        "icao": "ZMGID",
        "distance": 56,
        "alt": 1200,
        "time": 6,
        "active": false
      },
      {
        "icao": "ELYKM",
        "distance": 25,
        "alt": 1200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "כפר חסידים",
    "icao": "HASID",
    "latlng": [
      32.74569623609257,
      35.07698336903437
    ],
    "key": "HASID",
    "route": [
      {
        "icao": "MOVIL",
        "distance": 82,
        "alt": 1000,
        "time": 6,
        "active": false
      },
      {
        "icao": "BOREN",
        "distance": 57,
        "alt": 2200,
        "time": 6,
        "active": false
      },
      {
        "icao": "CNHRL",
        "distance": 81,
        "alt": 2200,
        "time": 6,
        "active": false
      },
      {
        "icao": "ELYKM",
        "distance": 67,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "אלמוג",
    "icao": "ALMOG",
    "latlng": [
      31.789722,
      35.456389
    ],
    "key": "ALMOG",
    "route": [
      {
        "icao": "ZUKIM",
        "distance": 46,
        "alt": 500,
        "time": 6,
        "active": true
      },
      {
        "icao": "DUMIM",
        "distance": 72,
        "alt": 1900,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNAMM",
        "distance": 59,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "NAAMA",
        "distance": 114,
        "alt": 800,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "נעמה",
    "icao": "NAAMA",
    "latlng": [
      31.90678237891994,
      35.46154320973841
    ],
    "key": "NAAMA",
    "route": [
      {
        "icao": "FAZEL",
        "distance": 83,
        "alt": 800,
        "time": 6,
        "active": false
      },
      {
        "icao": "ALMOG",
        "distance": 114,
        "alt": 500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "ארגמן",
    "icao": "ARGMN",
    "latlng": [
      32.1713845979909,
      35.53113670008179
    ],
    "key": "ARGMN",
    "route": [
      {
        "icao": "MHOLA",
        "distance": 122,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZADAM",
        "distance": 31,
        "alt": 500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בית גוברין",
    "icao": "GOVRN",
    "latlng": [
      31.60300535020492,
      34.89210362193516
    ],
    "key": "GOVRN",
    "route": [
      {
        "icao": "ZHRYA",
        "distance": 62,
        "alt": 2000,
        "time": 6,
        "active": true
      },
      {
        "icao": "MKGAT",
        "distance": 48,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "BGOVS",
        "distance": 112,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BLHAV",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בת שלמה",
    "icao": "BTSLM",
    "latlng": [
      32.5960938724842,
      35.00329389502072
    ],
    "key": "BTSLM",
    "route": [
      {
        "icao": "ELYKM",
        "distance": 40,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "FRDIS",
        "distance": 35,
        "alt": 1000,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "גילת",
    "icao": "GILAT",
    "latlng": [
      31.337222,
      34.653056
    ],
    "key": "GILAT",
    "route": [
      {
        "icao": "NASIH",
        "distance": 24,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "OFKIM",
        "distance": 20,
        "alt": 1500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "דור",
    "icao": "DOORS",
    "latlng": [
      32.607117,
      34.910314
    ],
    "key": "DOORS",
    "route": [
      {
        "icao": "SDTYM",
        "distance": 68,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "FRDIS",
        "distance": 20,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "דימונה",
    "icao": "DIMON",
    "latlng": [
      31.06684466874028,
      35.00586552080052
    ],
    "key": "DIMON",
    "route": [
      {
        "icao": "YRUHM",
        "distance": 71,
        "alt": 2700,
        "time": 6,
        "active": true
      },
      {
        "icao": "ETZEM",
        "distance": 15,
        "alt": 3000,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "הר חומה",
    "icao": "HOMMA",
    "latlng": [
      31.72919816683107,
      35.22034454686862
    ],
    "key": "HOMMA",
    "route": [
      {
        "icao": "DUMIM",
        "distance": 69,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "KENED",
        "distance": 45,
        "alt": 4000,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "וינגייט",
    "icao": "VINGT",
    "latlng": [
      32.254864,
      34.816997
    ],
    "key": "VINGT",
    "route": [
      {
        "icao": "SHFAM",
        "distance": 23,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "PELEG",
        "distance": 46,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "MEYAL",
        "distance": 40,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "טירת צבי",
    "icao": "TIRAT",
    "latlng": [
      32.42324210541948,
      35.52593246719919
    ],
    "key": "TIRAT",
    "route": [
      {
        "icao": "ENHRD",
        "distance": 121,
        "alt": 1300,
        "time": 6,
        "active": true
      },
      {
        "icao": "MHOLA",
        "distance": 35,
        "alt": 500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "יד קנדי",
    "icao": "KENED",
    "latlng": [
      31.74817926679777,
      35.13545595087274
    ],
    "key": "KENED",
    "route": [
      {
        "icao": "HOMMA",
        "distance": 45,
        "alt": 3500,
        "time": 6,
        "active": true
      },
      {
        "icao": "TZUBA",
        "distance": 19,
        "alt": 3500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "ירוחם",
    "icao": "YRUHM",
    "latlng": [
      30.989722,
      34.910833
    ],
    "key": "YRUHM",
    "route": [
      {
        "icao": "DIMON",
        "distance": 71,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "NEGEV",
        "distance": 59,
        "alt": 2500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 148,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "כוכב השחר",
    "icao": "SHAHR",
    "latlng": [
      31.95612239951118,
      35.33657420970167
    ],
    "key": "SHAHR",
    "route": [
      {
        "icao": "ALLON",
        "distance": 52,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "DUMIM",
        "distance": 92,
        "alt": 2700,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "כפר אדומים",
    "icao": "DUMIM",
    "latlng": [
      31.8018909466356,
      35.32425024583436
    ],
    "key": "DUMIM",
    "route": [
      {
        "icao": "ALMOG",
        "distance": 72,
        "alt": 1300,
        "time": 6,
        "active": true
      },
      {
        "icao": "HOMMA",
        "distance": 69,
        "alt": 4000,
        "time": 6,
        "active": true
      },
      {
        "icao": "SHAHR",
        "distance": 92,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNAMM",
        "distance": 44,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "כרם מהרל",
    "icao": "CNHRL",
    "latlng": [
      32.63893182746218,
      34.97695890898693
    ],
    "key": "CNHRL",
    "route": [
      {
        "icao": "FRDIS",
        "distance": 33,
        "alt": 1000,
        "time": 6,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 81,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "מחולה",
    "icao": "MHOLA",
    "latlng": [
      32.366667,
      35.516667
    ],
    "key": "MHOLA",
    "route": [
      {
        "icao": "TIRAT",
        "distance": 35,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "ARGMN",
        "distance": 122,
        "alt": 500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNAMM",
        "distance": 304,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "מעיינות צוקים",
    "icao": "ZUKIM",
    "latlng": [
      31.71422296716307,
      35.44937818299636
    ],
    "key": "ZUKIM",
    "route": [
      {
        "icao": "SHALM",
        "distance": 98,
        "alt": 500,
        "time": 6,
        "active": true
      },
      {
        "icao": "ALMOG",
        "distance": 46,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSHLM",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BYMLH",
        "distance": 108,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "מצפה שלם",
    "icao": "SHALM",
    "latlng": [
      31.55658395131587,
      35.40149460097428
    ],
    "key": "SHALM",
    "route": [
      {
        "icao": "ENGDI",
        "distance": 55,
        "alt": 500,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZUKIM",
        "distance": 98,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSHLM",
        "distance": 56,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 166,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "נחל בשור",
    "icao": "NBSOR",
    "latlng": [
      31.274444,
      34.493056
    ],
    "key": "NBSOR",
    "route": [
      {
        "icao": "TZHOT",
        "distance": 27,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZELIM",
        "distance": 45,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLGV",
        "distance": 30,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "AMIOZ",
        "distance": 48,
        "alt": 800,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "ניצן",
    "icao": "NITZA",
    "latlng": [
      31.722668,
      34.570832
    ],
    "key": "NITZA",
    "route": [
      {
        "icao": "NMADD",
        "distance": 63,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZBRCH",
        "distance": 40,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "ניצנים",
    "icao": "NIZNM",
    "latlng": [
      31.713889,
      34.627778
    ],
    "key": "NIZNM",
    "route": [
      {
        "icao": "ZBRCH",
        "distance": 32,
        "alt": 400,
        "time": 6,
        "active": false
      },
      {
        "icao": "ZASHD",
        "distance": 82,
        "alt": 400,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "נמל אשדוד",
    "icao": "NMADD",
    "latlng": [
      31.814863,
      34.630763
    ],
    "key": "NMADD",
    "route": [
      {
        "icao": "ZASHD",
        "distance": 40,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "NITZA",
        "distance": 63,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "עין גדי",
    "icao": "ENGDI",
    "latlng": [
      31.466389,
      35.395833
    ],
    "key": "ENGDI",
    "route": [
      {
        "icao": "SHALM",
        "distance": 55,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 82,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZOHAR",
        "distance": 189,
        "alt": 500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSHLM",
        "distance": 104,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BYMLH",
        "distance": 45,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "עין זיתים",
    "icao": "ZETIM",
    "latlng": [
      32.98710366634216,
      35.4823794046134
    ],
    "key": "ZETIM",
    "route": [
      {
        "icao": "BKRML",
        "distance": 68,
        "alt": 3500,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLIB",
        "distance": 50,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "עין חרוד",
    "icao": "ENHRD",
    "latlng": [
      32.55323286772257,
      35.38129875449562
    ],
    "key": "ENHRD",
    "route": [
      {
        "icao": "TIRAT",
        "distance": 121,
        "alt": 1000,
        "time": 6,
        "active": true
      },
      {
        "icao": "MRHVA",
        "distance": 47,
        "alt": 1700,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 22,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "נפח",
    "icao": "NAFCH",
    "latlng": [
      33.05599284274555,
      35.73405761753691
    ],
    "key": "NAFCH",
    "route": [
      {
        "icao": "ZBTMS",
        "distance": 52,
        "alt": 3300,
        "time": 6,
        "active": false
      },
      {
        "icao": "AVITL",
        "distance": 33,
        "alt": 3700,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "שניר",
    "icao": "SNNIR",
    "latlng": [
      33.23647846881158,
      35.67529393234854
    ],
    "key": "SNNIR",
    "route": [
      {
        "icao": "ZMSDA",
        "distance": 52,
        "alt": 5000,
        "time": 6,
        "active": false
      },
      {
        "icao": "HGSRM",
        "distance": 28,
        "alt": 4500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת מסעדה",
    "icao": "ZMSDA",
    "latlng": [
      33.2321870898955,
      35.75134345789734
    ],
    "key": "ZMSDA",
    "route": [
      {
        "icao": "BNTAL",
        "distance": 56,
        "alt": 5000,
        "time": 6,
        "active": false
      },
      {
        "icao": "SNNIR",
        "distance": 52,
        "alt": 4500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "בנטל",
    "icao": "BNTAL",
    "latlng": [
      33.14866582942906,
      35.78689583316234
    ],
    "key": "BNTAL",
    "route": [
      {
        "icao": "AVITL",
        "distance": 44,
        "alt": 5000,
        "time": 6,
        "active": false
      },
      {
        "icao": "ZMSDA",
        "distance": 56,
        "alt": 4500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת אביטל",
    "icao": "AVITL",
    "latlng": [
      33.08556928364023,
      35.78506243030261
    ],
    "key": "AVITL",
    "route": [
      {
        "icao": "NZFCH",
        "distance": 33,
        "alt": 4200,
        "time": 6,
        "active": false
      },
      {
        "icao": "BNTAL",
        "distance": 52,
        "alt": 4500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "עמיעוז",
    "icao": "AMIOZ",
    "latlng": [
      31.256667,
      34.402778
    ],
    "key": "AMIOZ",
    "route": [
      {
        "icao": "BSVTA",
        "distance": 140,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "NBSOR",
        "distance": 48,
        "alt": 1100,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "עצם",
    "icao": "ETZEM",
    "latlng": [
      31.09,
      34.993056
    ],
    "key": "ETZEM",
    "route": [
      {
        "icao": "DIMON",
        "distance": 15,
        "alt": 2700,
        "time": 6,
        "active": true
      },
      {
        "icao": "SECHR",
        "distance": 30,
        "alt": 3000,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "סכר",
    "icao": "SECHR",
    "latlng": [
      31.08936963884632,
      34.93518286045373
    ],
    "key": "SECHR",
    "route": [
      {
        "icao": "NEGEV",
        "distance": 52,
        "alt": 2500,
        "time": 6,
        "active": false
      },
      {
        "icao": "ETZEM",
        "distance": 30,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "פלג",
    "icao": "PELEG",
    "latlng": [
      32.32863758219636,
      34.83712263372249
    ],
    "key": "PELEG",
    "route": [
      {
        "icao": "VINGT",
        "distance": 46,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "OLGAH",
        "distance": 62,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "צאלים",
    "icao": "ZELIM",
    "latlng": [
      31.21308917016089,
      34.53096325685532
    ],
    "key": "ZELIM",
    "route": [
      {
        "icao": "NBSOR",
        "distance": 45,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSVTA",
        "distance": 98,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "צובה",
    "icao": "TZUBA",
    "latlng": [
      31.77757644600048,
      35.12088278460246
    ],
    "key": "TZUBA",
    "route": [
      {
        "icao": "KENED",
        "distance": 19,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "SORES",
        "distance": 26,
        "alt": 3500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "צומת אדם",
    "icao": "ZADAM",
    "latlng": [
      32.12449528294434,
      35.50887995644056
    ],
    "key": "ZADAM",
    "route": [
      {
        "icao": "ARGMN",
        "distance": 31,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "FAZEL",
        "distance": 53,
        "alt": 500,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "צומת אשדוד",
    "icao": "ZASHD",
    "latlng": [
      31.826111,
      34.708333
    ],
    "key": "ZASHD",
    "route": [
      {
        "icao": "SOREK",
        "distance": 74,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "NMADD",
        "distance": 40,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNZNI",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "NIZNM",
        "distance": 82,
        "alt": 400,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת ברכיה",
    "icao": "ZBRCH",
    "latlng": [
      31.665278,
      34.606111
    ],
    "key": "ZBRCH",
    "route": [
      {
        "icao": "NITZA",
        "distance": 40,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZPLGT",
        "distance": 84,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNZNI",
        "distance": 36,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BDRTN",
        "distance": 48,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "NIZNM",
        "distance": 32,
        "alt": 400,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת פלוגות",
    "icao": "ZPLGT",
    "latlng": [
      31.62359115890732,
      34.75524399016022
    ],
    "key": "ZPLGT",
    "route": [
      {
        "icao": "ZBRCH",
        "distance": 84,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "MKGAT",
        "distance": 33,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "MACHZ",
        "distance": 72,
        "alt": 1200,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "צומת פצאל",
    "icao": "FAZEL",
    "latlng": [
      32.04767413651691,
      35.46293277322031
    ],
    "key": "FAZEL",
    "route": [
      {
        "icao": "ZADAM",
        "distance": 53,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "ALLON",
        "distance": 49,
        "alt": 2700,
        "time": 6,
        "active": true
      },
      {
        "icao": "NAAMA",
        "distance": 83,
        "alt": 500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת קדרים",
    "icao": "ZKADR",
    "latlng": [
      32.909444,
      35.473889
    ],
    "key": "ZKADR",
    "route": [
      {
        "icao": "BKRML",
        "distance": 37,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZAMID",
        "distance": 37,
        "alt": 2000,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "שדות ים",
    "icao": "SDTYM",
    "latlng": [
      32.495631,
      34.884526
    ],
    "key": "SDTYM",
    "route": [
      {
        "icao": "OLGAH",
        "distance": 41,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "DOORS",
        "distance": 68,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "ORAKV",
        "distance": 25,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 25,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "שורש",
    "icao": "SORES",
    "latlng": [
      31.799722,
      35.076111
    ],
    "key": "SORES",
    "route": [
      {
        "icao": "TZUBA",
        "distance": 26,
        "alt": 3000,
        "time": 6,
        "active": true
      },
      {
        "icao": "SHARG",
        "distance": 31,
        "alt": 3300,
        "time": 6,
        "active": true
      },
      {
        "icao": "ESTOL",
        "distance": 35,
        "alt": 2500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "שזפון",
    "icao": "SIZFN",
    "latlng": [
      30.0475,
      35.014444
    ],
    "key": "SIZFN",
    "route": [
      {
        "icao": "SITIM",
        "distance": 77,
        "alt": 2500,
        "time": 6,
        "active": true
      },
      {
        "icao": "KTORA",
        "distance": 51,
        "alt": 2200,
        "time": 6,
        "active": true
      },
      {
        "icao": "BZNIF",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 81,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "שיטים",
    "icao": "SITIM",
    "latlng": [
      30.175833,
      35.014444
    ],
    "key": "SITIM",
    "route": [
      {
        "icao": "SIZFN",
        "distance": 77,
        "alt": 2200,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZZHOR",
        "distance": 60,
        "alt": 2500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 30,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "שפך",
    "icao": "SHEFC",
    "latlng": [
      31.93998921294407,
      34.70577086862941
    ],
    "key": "SHEFC",
    "route": [
      {
        "icao": "SOREK",
        "distance": 24,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  }
]