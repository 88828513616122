import {useState} from "react";
import {IconButton, ListItemIcon, ListItemText, MenuItem, Menu} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


 export function UndoButton (props) {

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const{undoDisabled, removeLastPointInRouteEvent} = props

  const handleOpenMenu = (e) => {
   e.stopPropagation()
   e.preventDefault();
   setAnchorElMenu(e.currentTarget);
 };

/***********************************************/
  const handleCloseMenu = () => {
   setAnchorElMenu(null);
 };

 /***********************************************/

 const resetLocalStorage = () => {
  handleCloseMenu()
  localStorage.clear();
  window.location.reload(true);
}

/***********************************************/
function MItem (props){
 const {text, icon, action} = props
 return(
   <MenuItem onClick={action} disabled = {props.disabled}>
       <ListItemIcon size="small"> 
         {icon} 
       </ListItemIcon>
       <ListItemText primary={text}  primaryTypographyProps={{fontSize: '14px', textAlign: "right"}}/>
   </MenuItem>
 )
}
/***********************************************/

return (
 <>
      <span>
        <IconButton
          onContextMenu={handleOpenMenu}
          size='small'
          sx={{mr: 1, bgcolor: '#1976d2', color: '#fff', '&:hover': { backgroundColor: '#1565c0', color: '#fff' },}}
          variant='contained'
          aria-label='menu'
          disabled={undoDisabled}
          onClick={removeLastPointInRouteEvent}
        >
          <UndoIcon />
        </IconButton>
      </span>

      <Menu 
          dir="RTL" 
          sx={{textAlign: "right",  mt: '45px'}}
          anchorEl={anchorElMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{ style: { width: 280,},}} 
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          >
          <MItem text = "מחק נקודה אחרונה" icon = {<UndoIcon/>} action = {removeLastPointInRouteEvent} />
          <MItem text = "אפס את נתוני האפליקציה" icon = {<RestartAltIcon/>} action = {resetLocalStorage} />
      </Menu>
   </>
  )
 }

 export default UndoButton;
