import * as React from "react"

export function ErrorIcon(props) {

  return (

<svg 
  xmlns="http://www.w3.org/2000/svg" 
  version="1.1" 
  width="64" 
  height="64" 
  viewBox="0 0 64 64" 
  >

<defs>
</defs>
<g 
  style={{stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1}}
  transform="translate(1.4065934065934016 1.4065934065934016) scale(0.7 0.7)" >
	<path 
    d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" 
    style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(236,0,0)", fillRule: "nonzero", opacity: 1}}
    transform=" matrix(1 0 0 1 0 0) " 
    strokeLinecap="round" 
  />
	<path 
    d="M 28.5 65.5 c -1.024 0 -2.047 -0.391 -2.829 -1.172 c -1.562 -1.562 -1.562 -4.095 0 -5.656 l 33 -33 c 1.561 -1.562 4.096 -1.562 5.656 0 c 1.563 1.563 1.563 4.095 0 5.657 l -33 33 C 30.547 65.109 29.524 65.5 28.5 65.5 z" 
    style={{stroke: "none",strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:" rgb(255,255,255)", fillRule: "nonzero", opacity: 1}}
    transform="matrix(1 0 0 1 0 0)" 
    strokeLinecap="round" 
  />
	<path 
    d="M 61.5 65.5 c -1.023 0 -2.048 -0.391 -2.828 -1.172 l -33 -33 c -1.562 -1.563 -1.562 -4.095 0 -5.657 c 1.563 -1.562 4.095 -1.562 5.657 0 l 33 33 c 1.563 1.562 1.563 4.095 0 5.656 C 63.548 65.109 62.523 65.5 61.5 65.5 z" 
    style={{stroke: "none",strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:" rgb(255,255,255)", fillRule: "nonzero", opacity: 1}}
    transform=" matrix(1 0 0 1 0 0)" 
    strokeLinecap="round" 
    />
</g>
</svg>
 )
}

export default ErrorIcon
